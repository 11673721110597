import { defineMessages, useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useVersionMonitor } from '@mevia/features/versionMonitor/useVersionMonitor'
import { useToast } from '@mevia/features/toast/toastContext'

const toastMessages = defineMessages({
  title: {
    defaultMessage: 'New release',
    description: 'Title for the toast message when a new release is available',
  },
  description: {
    defaultMessage:
      "A new release is available. Click 'Update' to reload the application.",
    description:
      'Description for the toast message when a new release is available',
  },
  action: {
    defaultMessage: 'Update',
    description:
      'Action button label for the toast message when a new release is available. Clicking the button will reload the application.',
  },
})

export function VersionToaster() {
  const intl = useIntl()
  const toastState = useToast()
  const { hasNewVersion } = useVersionMonitor()

  useEffect(() => {
    if (hasNewVersion) {
      toastState.add({
        type: 'info',
        title: intl.formatMessage(toastMessages.title),
        description: intl.formatMessage(toastMessages.description),
        action: {
          label: intl.formatMessage(toastMessages.action),
          onClick: () => window.location.reload(),
        },
      })
    }
  }, [hasNewVersion])

  return null
}
