import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'
import { ValidationErrors } from '../reducers/types'
import { Recipient, RecipientId } from '@mevia/types/mapiAPI'

export type RecipientResponseShape = {
  id: string
  attributes: Omit<Recipient, 'id'>
}
export type RecipientResponse = {
  data: RecipientResponseShape
}

export type RecipientsResponse = {
  data: RecipientResponseShape[]
}

export const fetchByPrescription = createAsyncThunk<
  Recipient[],
  { prescriptionId: string; query?: Record<string, string> }
>('recipients/fetchByPrescription', async ({ prescriptionId, query = {} }) => {
  const json = await request.get<RecipientsResponse>(
    `v2/prescriptions/${prescriptionId}/recipients`,
    { query }
  )

  const recipients = json.data.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }))

  return recipients
})

export const update = createAsyncThunk<
  Recipient,
  { recipientId: RecipientId; attributes: unknown },
  {
    rejectValue: ValidationErrors
  }
>(
  'recipients/update',
  async ({ recipientId, attributes }, { rejectWithValue }) => {
    try {
      const json = await request.put<RecipientResponse>(
        `v2/recipients/${recipientId}`,
        {
          body: { data: { type: 'recipients', id: recipientId, attributes } },
        }
      )

      return { id: json.data.id, ...json.data.attributes }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        validationErrors: error.json?.errors,
      })
    }
  }
)

export const destroy = createAsyncThunk<
  RecipientId,
  { recipientId: RecipientId }
>('recipients/destroy', async ({ recipientId }) => {
  await request.del<void>(`v2/recipients/${recipientId}`)
  return recipientId
})

export const createRecipientForPrescription = createAsyncThunk<
  Recipient,
  { prescriptionId: string; attributes: unknown },
  {
    rejectValue: ValidationErrors
  }
>(
  'recipients/createForPrescription',
  async ({ prescriptionId, attributes }, { rejectWithValue }) => {
    try {
      const json = await request.post<RecipientResponse>(`v2/recipients`, {
        body: {
          data: {
            type: 'recipients',
            attributes,
            relationships: {
              prescription: {
                data: { type: 'prescriptions', id: prescriptionId },
              },
            },
          },
        },
      })

      const recipient = {
        id: json.data.id,
        ...json.data.attributes,
      }

      return recipient
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        validationErrors: error.json?.errors,
      })
    }
  }
)
