import React from 'react'
import styled from 'styled-components'
import { MainWrapper } from '@mevia/components/atoms/MainWrapper'
import { AnchorLink } from '@mevia/components/atoms/AnchorLink'
import Button from '@mevia/components/atoms/Button'
import Banner from '@mevia/components/molecules/Banner'
import FullPageBox from '@mevia/components/molecules/FullPageBox'
import HeadingWrapper from '@mevia/components/molecules/HeadingWrapper'
import LogoLink from '@mevia/components/molecules/LogoLink'
import { BP_S } from '../../constants/breakpoints'
import { FormattedMessage } from 'react-intl'

const StyledLogoLink = styled(LogoLink)`
  display: block;
  margin-right: auto;
  margin-left: auto;
`

const Description = styled.p`
  color: var(--color-black-beta);
  padding: 0;
  margin-top: 0;
  margin-bottom: var(--spacing-m);
`

const LinkWrapper = styled.p`
  margin-top: var(--spacing-xs);
  font-size: var(--font-size-s);
  text-align: center;
  color: var(--color-black-beta);
`

const FixedBanner = styled(Banner)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`

const FormWrapper = styled.div`
  margin-top: var(--spacing-l);

  @media (min-width: ${BP_S}) {
    margin-top: var(--spacing-xxl);
    padding-right: var(--spacing-m);
    padding-left: var(--spacing-m);
  }
`

const StyledButton = styled(Button)`
  margin-top: var(--spacing-m);
`

const LoginAlternativeContainer = styled.div`
  margin-top: var(--spacing-m);
`

const LoginAlternativeHeader = styled.h2`
  text-align: center;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-s);
  color: var(--color-black-beta);
  font-weight: var(--bold);

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid var(--color-black-delta);
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`

const LoginAlternativeContent = styled.div`
  margin-top: var(--spacing-m);
`

const LoginAlternative = ({ children }) => {
  return (
    <LoginAlternativeContainer>
      <LoginAlternativeHeader>
        <FormattedMessage
          id="login-alternative-login-header"
          defaultMessage="or"
        />
      </LoginAlternativeHeader>
      <LoginAlternativeContent>{children}</LoginAlternativeContent>
    </LoginAlternativeContainer>
  )
}

const AuthContainer = ({
  onSubmit,
  children = undefined,
  title,
  subtitle = null,
  errorContent = null,
  disabled = false,
  buttonContent,
  linkContent = null,
  linkTo = '',
  bannerStatus = null,
  bannerTitle = null,
  loginAlternative = null,
}) => (
  <MainWrapper noSpacing>
    {bannerStatus && bannerTitle && (
      <FixedBanner type={bannerStatus} title={bannerTitle} />
    )}
    <FullPageBox>
      <h1>
        <StyledLogoLink logoWithText href="/login" />
      </h1>
      <FormWrapper>
        <HeadingWrapper title={title} headingLevel={'h2'} narrowSpacing />
        {subtitle && <Description>{subtitle}</Description>}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(e)
          }}
        >
          {children}
          {errorContent}
          <StyledButton
            type="submit"
            data-cy="submit-button"
            disabled={disabled}
            fullWidth
          >
            {buttonContent}
          </StyledButton>
        </form>
        {linkContent && linkTo && (
          <LinkWrapper>
            <AnchorLink to={linkTo} data-cy="link">
              {linkContent}
            </AnchorLink>
          </LinkWrapper>
        )}
        {loginAlternative && (
          <LoginAlternative>{loginAlternative}</LoginAlternative>
        )}
      </FormWrapper>
    </FullPageBox>
  </MainWrapper>
)

export default AuthContainer
