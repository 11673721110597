import React from 'react'
import { JobStatus } from '@mevia/mapiAPI'

type JobStateDesc = {
  title: string
  message: string
  on?: () => void
}

type MappedJobStatusToLabel = {
  [Status in JobStatus['status']]: JobStateDesc
}

export type Job = {
  jobId: string
  states: MappedJobStatusToLabel & {
    requestError: JobStateDesc
    pending: JobStateDesc
  }
}

interface JobStatusMonitor {
  pushJob: (job: Job) => void
}

export const JobStatusContext = React.createContext<JobStatusMonitor | null>(
  null
)

export function useJobMonitor() {
  const jobStatusContext = React.useContext(JobStatusContext)
  if (!jobStatusContext) {
    throw new Error('JobStatusContext not found')
  }
  return jobStatusContext
}
