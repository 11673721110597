import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch, useAppSelector } from '@mevia/storeHooks'
import styled from 'styled-components'
// @ts-expect-error Not a TS-file
import DropdownWrapper from '@mevia/components/atoms/DropdownWrapper'
import { Label, RadioButton } from '@mevia/components/atoms/CustomRadioButton'
import { ChevronUpIcon, ChevronDownIcon } from '@mevia/components/atoms/Icon'
import {
  setLocale,
  PreferredTimeZoneType,
  setPreferredTimeZoneType,
  getSelectedLocale,
  getSelectedProjectLocale,
  getLocalesForSelectedProject,
} from '../../../reducers/localization'
import { RootState } from '../../../reducers/types'
import ToggleButton from '@mevia/components/atoms/DropdownToggleButton'
import { saveProjectLocale } from '../../../utils/persistance'

const LocalizationButton = styled(ToggleButton)`
  & > *:not(:first-child) {
    margin-left: var(--spacing-xxs);
  }
`

const Fieldset = styled.fieldset`
  border: 0;
  padding: var(--spacing-m);
  margin-top: var(--spacing-m);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-black-epsilon);
  }
`

const Legend = styled.legend`
  line-height: 1;
  font-weight: var(--bold);
`

const DropdownItemLabel = styled.label`
  display: flex;
  align-items: center;
  padding: var(--spacing-xxs) 0;
  border-radius: 0;
  min-width: 200px;
  text-align: left;
  color: var(--color-black-alpha);
  transition: background-color 0.2s;
  cursor: pointer;

  :first-of-type {
    padding-top: 0;
  }
`

const LanguageLabel = styled.span`
  padding-right: var(--spacing-xxs);
  color: var(--color-black-beta);
`

const LanguageButtonLabel = () => {
  const { label: selectedLanguageLabel } = useAppSelector(
    getSelectedProjectLocale
  )

  return (
    <div>
      <LanguageLabel>
        <FormattedMessage
          id="admin-menu.language-button-label"
          defaultMessage="Language"
        />
        :
      </LanguageLabel>

      <span>{selectedLanguageLabel}</span>
    </div>
  )
}

const timeZoneLabels: Record<
  PreferredTimeZoneType,
  { timeZone: PreferredTimeZoneType; label: ReactElement }
> = {
  participant: {
    timeZone: 'participant',
    label: (
      <FormattedMessage
        id="localization.time-zone.participant"
        defaultMessage="Participant"
      />
    ),
  },
  browser: {
    timeZone: 'browser',
    label: (
      <FormattedMessage
        id="localization.time-zone.local"
        defaultMessage="Local"
      />
    ),
  },
}

const TimeZoneLabelWrapper = styled.div``

const TimeZoneLabel = styled.span`
  padding-right: var(--spacing-xxs);
  color: var(--color-black-beta);
`

const TimeZoneButtonLabel = () => {
  const { preferredTimeZoneType: selectedTimeZone } = useAppSelector(
    (state: RootState) => state.localization
  )
  const selectedTimeZoneLabel = timeZoneLabels[selectedTimeZone].label

  return (
    <TimeZoneLabelWrapper>
      <TimeZoneLabel>
        <FormattedMessage
          id="admin-menu.time-zone-button-label"
          defaultMessage="Time zone"
        />
        :
      </TimeZoneLabel>

      <span>{selectedTimeZoneLabel}</span>
    </TimeZoneLabelWrapper>
  )
}

const SelectLanguageFieldSet = () => {
  const dispatch = useAppDispatch()

  const selectedLocale = useAppSelector(getSelectedLocale)
  const locales = useAppSelector(getLocalesForSelectedProject)

  type ProjectLocaleCode = keyof typeof locales

  const handleLocale = (localeCode: ProjectLocaleCode) => {
    const projectLocale = {
      project: selectedLocale.project,
      locale: localeCode,
    }
    dispatch(setLocale(projectLocale))
    saveProjectLocale(projectLocale)
  }

  return (
    <Fieldset>
      <Legend>
        <FormattedMessage
          id="admin-menu.language-button-label"
          defaultMessage="Language"
        />
      </Legend>
      {Object.entries(locales).map(([code, { label }]) => (
        <DropdownItemLabel key={code}>
          <RadioButton
            onChange={() => handleLocale(code as ProjectLocaleCode)}
            checked={code === selectedLocale.locale}
            name="locale"
          />
          <Label>{label || code}</Label>
        </DropdownItemLabel>
      ))}
    </Fieldset>
  )
}

const SelectTimeZoneFieldSet = () => {
  const dispatch = useAppDispatch()
  const { preferredTimeZoneType: selectedTimeZoneType } = useAppSelector(
    (state: RootState) => state.localization
  )
  const handleTimeZone = (timeZone: PreferredTimeZoneType) => {
    dispatch(setPreferredTimeZoneType(timeZone))
  }
  return (
    <Fieldset>
      <Legend>
        <FormattedMessage
          id="admin-menu.time-zone-button-label"
          defaultMessage="Time zone"
        />
      </Legend>
      {Object.values(timeZoneLabels).map(({ timeZone, label }) => (
        <DropdownItemLabel key={timeZone}>
          <RadioButton
            onChange={() => handleTimeZone(timeZone)}
            checked={timeZone === selectedTimeZoneType}
            name="timeZone"
          />
          <Label>{label}</Label>
        </DropdownItemLabel>
      ))}
    </Fieldset>
  )
}

const LocalizationDropdown = (): ReactElement | null => {
  const isAllowedToChangeLanguage = useAppSelector(
    (state: RootState) =>
      state.user.user?.config?.canChangePortalLanguage || false
  )
  const isAllowedToChangeTimeZone = useAppSelector(
    (state: RootState) =>
      state.user.user?.config?.canChangePortalTimeZone || false
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      const button = event.currentTarget
      setAnchorEl(button)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!isAllowedToChangeLanguage && !isAllowedToChangeTimeZone) return null

  return (
    <>
      <LocalizationButton
        data-cy="localization-button"
        active={!!anchorEl}
        onClick={handleClick}
      >
        {isAllowedToChangeLanguage && <LanguageButtonLabel />}
        {isAllowedToChangeTimeZone && <TimeZoneButtonLabel />}

        {anchorEl ? (
          <ChevronUpIcon spacingLeft />
        ) : (
          <ChevronDownIcon spacingLeft />
        )}
      </LocalizationButton>

      <DropdownWrapper
        dataCy="localization-dropdown"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        {isAllowedToChangeLanguage && <SelectLanguageFieldSet />}
        {isAllowedToChangeTimeZone && <SelectTimeZoneFieldSet />}
      </DropdownWrapper>
    </>
  )
}

export default LocalizationDropdown
