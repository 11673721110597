import styled from 'styled-components'
import React from 'react'
import { AriaToastRegionProps, useToastRegion } from '@react-aria/toast'
import { Toast, Toastable } from '@mevia/features/toast/Toast'
import { ToastState } from '@react-stately/toast'
import { BP_M, BP_S } from '@mevia/constants/breakpoints'

const ToastRegionWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: var(--spacing-l);
  z-index: 1000;
  padding: 0 var(--spacing-m);
  display: flex;
  flex-direction: column-reverse;
  align-items: end;

  @media (max-width: ${BP_M}) {
    align-items: center;
  }
`

const ToastConstrainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: ${BP_S};
  gap: 1rem;
`

export interface ToastRegionProps extends AriaToastRegionProps {
  state: ToastState<Toastable>
}

export function ToastRegion({ state, ...props }: ToastRegionProps) {
  const ref = React.useRef(null)
  const { regionProps } = useToastRegion(props, state, ref)

  return (
    <ToastRegionWrapper {...regionProps} ref={ref}>
      <ToastConstrainer>
        {state.visibleToasts.map((toast) => (
          <Toast key={toast.key} toast={toast} state={state} />
        ))}
      </ToastConstrainer>
    </ToastRegionWrapper>
  )
}
