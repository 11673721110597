import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'
import {
  RecipientResponse,
  RecipientResponseShape,
  RecipientsResponse,
} from './recipients'
import { FlagPolicy, FlagPolicyId } from '../reducers/flagPolicies'
import { ValidationErrors } from '../reducers/types'
import { Recipient, RecipientId } from '@mevia/types/mapiAPI'

type RelationshipLink = {
  type: string
  id: string
}

type FlagPolicyResponseShape = {
  id: string
  attributes: Omit<FlagPolicy, 'id'>
  relationships: {
    recipientReceivers: {
      data: RelationshipLink[]
    }
  }
}
type FlagPolicyResponse = {
  data: FlagPolicyResponseShape
}

type FlagPoliciesResponse = {
  data: FlagPolicyResponseShape[]
  included?: RecipientResponseShape[]
}

export const fetchByPrescription = createAsyncThunk<
  {
    flagPolicies: FlagPolicy[]
    recipients: Recipient[]
    relationships: Record<FlagPolicyId, RecipientId[]>
  },
  { prescriptionId: string; query?: Record<string, string> }
>(
  'flagPolicies/fetchByPrescription',
  async ({ prescriptionId, query = {} }) => {
    const json = await request.get<FlagPoliciesResponse>(
      `v2/prescriptions/${prescriptionId}/flag_policies?include=recipient_receivers`,
      { query }
    )

    const buildEntity = <T>({
      id,
      attributes,
    }: {
      id: string
      attributes: T
    }) => ({
      id,
      ...attributes,
    })

    const flagPolicies = json.data.map(buildEntity)
    const recipients = json.included?.map(buildEntity) || []

    const relationships: Record<FlagPolicyId, RecipientId[]> = json.data.reduce(
      (acc, { id: flagPolicyId, relationships }) => {
        acc[flagPolicyId] = relationships.recipientReceivers.data.map(
          ({ id: recipientId }) => recipientId
        )
        return acc
      },
      {} as Record<FlagPolicyId, RecipientId[]>
    )

    return { flagPolicies, recipients, relationships }
  }
)

export const fetch = createAsyncThunk<
  FlagPolicy,
  { flagPolicyId: FlagPolicyId; query?: Record<string, string> }
>('flagPolicies/fetch', async ({ flagPolicyId, query = {} }) => {
  const json = await request.get<FlagPolicyResponse>(
    `v2/flag_policies/${flagPolicyId}`,
    {
      query,
    }
  )

  return { id: json.data.id, ...json.data.attributes }
})

export const create = createAsyncThunk<
  FlagPolicy,
  { prescriptionId: string; attributes: unknown; recipients?: string[] }
>(
  'flagPolicies/create',
  async ({ prescriptionId, attributes, recipients = [] }) => {
    const json = await request.post<FlagPolicyResponse>(`v2/flag_policies`, {
      body: {
        data: {
          type: 'flag_policies',
          attributes,
          relationships: {
            prescription: {
              data: {
                type: 'prescriptions',
                id: prescriptionId,
              },
            },
            recipient_receivers: {
              data: recipients.map((id) => ({
                type: 'recipients',
                id,
              })),
            },
          },
        },
      },
    })

    return { id: json.data.id, ...json.data.attributes }
  }
)

export const update = createAsyncThunk<
  FlagPolicy,
  { flagPolicyId: FlagPolicyId; attributes: unknown; recipients: string[] }
>('flagPolicies/update', async ({ flagPolicyId, attributes, recipients }) => {
  const json = await request.put<FlagPolicyResponse>(
    `v2/flag_policies/${flagPolicyId}`,
    {
      body: {
        data: {
          type: 'flag_policies',
          id: flagPolicyId,
          attributes,
          relationships: {
            recipient_receivers: {
              data: recipients.map((id) => ({
                type: 'recipients',
                id,
              })),
            },
          },
        },
      },
    }
  )

  return { id: json.data.id, ...json.data.attributes }
})

export const destroy = createAsyncThunk<
  FlagPolicyId,
  { flagPolicyId: FlagPolicyId }
>('flagPolicies/destroy', async ({ flagPolicyId }) => {
  await request.del<void>(`v2/flag_policies/${flagPolicyId}`)
  return flagPolicyId
})

export const fetchRecipients = createAsyncThunk<
  Recipient[],
  { flagPolicyId: FlagPolicyId }
>('flagPolicies/fetchRecipients', async ({ flagPolicyId }) => {
  const json = await request.get<RecipientsResponse>(
    `v2/flag_policies/${flagPolicyId}/recipient_receivers`
  )
  const data = json.data.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }))

  return data
})

export const createRecipient = createAsyncThunk<
  Recipient,
  { flagPolicyId: FlagPolicyId; prescriptionId: string; attributes: unknown },
  {
    rejectValue: ValidationErrors
  }
>(
  'flagPolicies/createRecipient',
  async ({ flagPolicyId, prescriptionId, attributes }, { rejectWithValue }) => {
    try {
      const json = await request.post<RecipientResponse>(`v2/recipients`, {
        body: {
          data: {
            type: 'recipients',
            attributes,
            relationships: {
              flagPolicies: {
                data: [{ type: 'flag_policies', id: flagPolicyId }],
              },
              prescription: {
                data: { type: 'prescriptions', id: prescriptionId },
              },
            },
          },
        },
      })

      return { id: json.data.id, ...json.data.attributes }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        validationErrors: error.json?.errors,
      })
    }
  }
)

export const linkRecipient = createAsyncThunk<
  { flagPolicyId: FlagPolicyId; recipientId: RecipientId },
  { flagPolicyId: FlagPolicyId; recipientId: RecipientId }
>('flagPolicies/linkRecipient', async ({ flagPolicyId, recipientId }) => {
  await request.post<void>(
    `v2/flag_policies/${flagPolicyId}/relationships/recipient_receivers`,
    {
      body: {
        data: [{ type: 'recipients', id: recipientId }],
      },
    }
  )
  return { flagPolicyId, recipientId }
})

export const unlinkRecipient = createAsyncThunk<
  { flagPolicyId: FlagPolicyId; recipientId: RecipientId },
  { flagPolicyId: FlagPolicyId; recipientId: RecipientId }
>('flagPolicies/unlinkRecipient', async ({ flagPolicyId, recipientId }) => {
  await request.del<void>(
    `v2/flag_policies/${flagPolicyId}/relationships/recipient_receivers`,
    {
      body: {
        data: [{ type: 'recipients', id: recipientId }],
      },
    }
  )

  return { flagPolicyId, recipientId }
})
