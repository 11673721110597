import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import logo from '../../../assets/mevia-symbol@2x.png'
import logoWithTextImg from '../../../assets/mevia-logo@2x.png'

const StyledLink = styled(Link)<{ $width: string }>`
  display: inline-block;
  width: ${(props) => props.$width};
`

const Logo = styled.img`
  vertical-align: middle;
`

interface Props {
  href?: string
  alt?: string
  src?: typeof logo
  logoWithText?: boolean
}

const LogoLink = ({
  href = '/',
  src = logo,
  alt = 'Mevia logo',
  logoWithText = false,
  ...props
}: Props): ReactElement => {
  const width = logoWithText ? '156px' : '50px'
  const height = logoWithText ? '42px' : '34px'
  return (
    <StyledLink to={href} $width={width} {...props}>
      <Logo
        width={width}
        height={height}
        src={logoWithText ? logoWithTextImg : src}
        alt={alt}
      />
    </StyledLink>
  )
}

export default LogoLink
