import React from 'react'
import styled, { css } from 'styled-components'
import { BP_S } from '../../constants/breakpoints'
import { BoxShadow } from '@mevia/components/atoms/BoxShadow'

const PageWrapper = styled.div`
  display: flex;
  min-height: inherit;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 550px;
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-s);
`

export const contentPadding = css`
  padding: var(--spacing-l);
  @media (min-width: ${BP_S}) {
    padding: var(--spacing-xxl);
  }
`

const Content = styled.div`
  justify-content: center;

  ${(props) => props.padded && contentPadding}
`

const FullPageBox = ({ children, padded = true }) => (
  <PageWrapper>
    <ContentWrapper>
      <BoxShadow>
        <Content padded={padded}>{children}</Content>
      </BoxShadow>
    </ContentWrapper>
  </PageWrapper>
)

export default FullPageBox
