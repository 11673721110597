import React from 'react'
import { UserConfig } from '@mevia/reducers/user'
import { useAppSelector } from '@mevia/storeHooks'

interface Props {
  children: React.ReactNode
  permission: (userConfig: UserConfig) => boolean
}

const Protected = ({ children, permission }: Props) => {
  const config = useAppSelector((state) => state.user.user?.config)
  const isAllowed = config ? permission(config) : false

  return isAllowed ? children : null
}

export default Protected
