import React from 'react'
import styled from 'styled-components'
import Heading from '@mevia/components/atoms/Heading'
import { BP_XL } from '../../constants/breakpoints'

const HeadingWrapperElement = styled.div<{
  narrowSpacing?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.narrowSpacing ? 'var(--spacing-xs)' : 'var(--spacing-m)'};

  @media (min-width: ${BP_XL}) {
    margin-bottom: ${(props) =>
      props.narrowSpacing ? 'var(--spacing-xs)' : 'var(--spacing-l)'};
  }
`

type HeadingProps = React.ComponentPropsWithoutRef<typeof Heading>
type HeadingWrapperElementProps = React.ComponentPropsWithoutRef<
  typeof HeadingWrapperElement
>

interface HeadingWrapperProps
  extends Omit<HeadingProps, 'headingLevel'>,
    HeadingWrapperElementProps {
  children?: React.ReactNode
  headingLevel?: HeadingProps['headingLevel']
}

const HeadingWrapper = ({
  title,
  headingLevel = 'h1',
  children = undefined,
  ...props
}: HeadingWrapperProps) => (
  <HeadingWrapperElement {...props}>
    <Heading title={title} headingLevel={headingLevel} />
    {children}
  </HeadingWrapperElement>
)

export default HeadingWrapper
