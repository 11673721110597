import styled from 'styled-components'

const InputDescription = styled.p`
  margin-top: var(--spacing-xxs);
  margin-bottom: 0;
  font-size: var(--font-size-xs);
  color: var(--color-black-beta);
`

export default InputDescription
