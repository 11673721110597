import { Prescription, MiaModule } from '@mevia/types/mapiAPI'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'

// From backend config
export const MAX_PRESCRIPTION_PAGE_COUNT = 1825

interface MapiResponse {
  meta: {
    recordCount: number
  }
}

export interface ResponseShape<T> {
  id: string
  type: string
  attributes: Omit<T, 'id'>
}

export interface PrescriptionResponse extends MapiResponse {
  data: ResponseShape<Prescription>
}

export interface PrescriptionsResponse extends MapiResponse {
  data: ResponseShape<Prescription>[]
  included: ResponseShape<MiaModule>[]
}

export const fetchAll = createAsyncThunk<
  { prescriptions: Prescription[]; recordCount: number },
  { query?: Record<string, string> }
>('prescriptions/fetchAll', async ({ query }) => {
  const json = await request.get<PrescriptionsResponse>('v2/prescriptions', {
    query,
  })
  const prescriptions = json.data.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }))

  return {
    prescriptions,
    recordCount: json.meta.recordCount,
  }
})

export const connectDevice = createAsyncThunk<
  void,
  { prescriptionId: string; deviceName: string }
>(
  'prescriptions/connectDevice',
  async ({ prescriptionId, deviceName }, { rejectWithValue }) => {
    try {
      await request.put(
        `v2/prescriptions/${prescriptionId}/connect_device/${deviceName}`,
        {}
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
