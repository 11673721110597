import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const frontendAPI = createApi({
  reducerPath: 'frontendAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getLatestVersion: build.query<string, void>({
      query: () => ({
        url: `RELEASE`,
        responseHandler: 'text',
      }),
    }),
  }),
})
export const { useGetLatestVersionQuery } = frontendAPI
