import React from 'react'
import styled from 'styled-components'
import Popover from '@mui/material/Popover'

const PopoverWrapper = styled(Popover)`
  & .MuiPaper-root {
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    white-space: nowrap;
  }
`

const TOP_OFFSET_PX = 16

const DropdownWrapper = (props) => {
  const { dataCy = '', className, anchorEl, children, handleClose } = props

  let heightOffset
  if (anchorEl) {
    const anchorRect = anchorEl.getBoundingClientRect()
    heightOffset = anchorRect.height + TOP_OFFSET_PX
  }

  const anchorOriginVertical =
    props.anchorOrigin?.vertical || heightOffset || 'bottom'
  const anchorOriginHorizontal = props.anchorOrigin?.horizontal || 'right'

  const anchorOrigin = {
    vertical: anchorOriginVertical,
    horizontal: anchorOriginHorizontal,
  }

  const transformOrigin = props.transformOrigin || {
    vertical: 'top',
    horizontal: 'right',
  }

  const expanded = Boolean(anchorEl)

  return (
    <PopoverWrapper
      className={className}
      open={expanded}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        'data-cy': dataCy,
      }}
    >
      {children}
    </PopoverWrapper>
  )
}

export default DropdownWrapper
