import React from 'react'
import { FormattedMessage } from 'react-intl'
import type { Locale as DateFnsLocale } from 'date-fns'
import type { LocaleInput as FullCalendarLocale } from '@fullcalendar/react'

type LocalizationLabel = { label: React.ReactElement }
type Translations = {
  intlLocale?: string
  messages: () => Promise<{ default: Record<string, string> }>
  dateFnsLocale?: () => Promise<{ default: DateFnsLocale }>
  fullCalendarLocale?: () => Promise<{ default: FullCalendarLocale }>
}

const supportedLocales = ['en', 'sv', 'es'] as const

export type Locales = typeof supportedLocales[number]

const locales: Record<Locales, LocalizationLabel & Translations> = {
  en: {
    messages: () =>
      import(
        /* webpackPreload: true */
        './en.json'
      ),
    intlLocale: 'en-GB',
    dateFnsLocale: () => import('date-fns/locale/en-GB'),
    fullCalendarLocale: () => import('@fullcalendar/core/locales/en-gb'),
    label: (
      <FormattedMessage id="localization.english" defaultMessage="English" />
    ),
  },
  sv: {
    messages: () => import('./sv.json'),
    dateFnsLocale: () => import('date-fns/locale/sv'),
    fullCalendarLocale: () => import('@fullcalendar/core/locales/sv'),
    label: (
      <FormattedMessage
        id="localization.swedish"
        defaultMessage="Svenska (Swedish)"
      />
    ),
  },
  es: {
    messages: () => import('./es.json'),
    dateFnsLocale: () => import('date-fns/locale/es'),
    fullCalendarLocale: () => import('@fullcalendar/core/locales/es'),
    label: (
      <FormattedMessage
        id="localization.spanish"
        defaultMessage="Español (Spanish)"
      />
    ),
  },
}

export default locales
