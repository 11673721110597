import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { createRoot } from 'react-dom/client'
import ReactModal from 'react-modal'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import Root from './Root'

if (process.env.SENTRY_DSN && process.env.SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,

    // Increase the context depth from 3, the idea is to get more Redux state
    normalizeDepth: 10,

    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    beforeBreadcrumb(breadcrumb) {
      // Don't include console logs as breadcrumbs. The event payload becomes
      // so large that Sentry rejects it.
      return breadcrumb.category === 'console' ? null : breadcrumb
    },
  })
}

const rootElement = document.getElementById('root')
const reactRoot = createRoot(rootElement)

ReactModal.setAppElement('#root')
reactRoot.render(<Root />)
