import * as Sentry from '@sentry/react'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAction as createHydrateAction } from '../reducers/utils/hydratable'
import {
  clear as clearPersistedData,
  saveAuthToken,
} from '../utils/persistance'
import * as request from '../utils/request'
import { User } from '../reducers/user'
import { z } from 'zod'

const ROOT = 'v2/users'

type UserResponseShape = {
  id: string
  attributes: Omit<User, 'id'>
}
type UserResponse = {
  data: UserResponseShape
}

type EmailLogin = {
  email: string
  password: string
}

type UserNameLogin = {
  userName: string
  password: string
}

type LoginParams = EmailLogin | UserNameLogin

const invalidCredentialsSchema = z.object({
  response: z.object({
    status: z.literal(401),
  }),
})

export type LoginError =
  | { type: 'invalid-credentials' }
  | { type: 'storage-unavailable' }
  | { type: 'unknown' }

type AuthedUserResponse = {
  data: {
    attributes: {
      jwt: string
    }
  }
}

export const logIn = createAsyncThunk<
  User,
  {
    remember: boolean
  } & LoginParams,
  { rejectValue: LoginError }
>('authentication/login', async (loginParams, thunkApi) => {
  const { remember, ...user } = loginParams

  let json

  try {
    json = await request.post<UserResponse & AuthedUserResponse>(
      `${ROOT}/login?config=portal`,
      { body: { user } }
    )
  } catch (error) {
    if (invalidCredentialsSchema.safeParse(error).success) {
      return thunkApi.rejectWithValue({ type: 'invalid-credentials' })
    } else if (
      error instanceof DOMException &&
      error.name === 'SecurityError'
    ) {
      return thunkApi.rejectWithValue({ type: 'storage-unavailable' })
    } else {
      Sentry.captureException(error)
      return thunkApi.rejectWithValue({ type: 'unknown' })
    }
  }

  const token = json.data.attributes.jwt

  try {
    saveAuthToken(token, remember)
  } catch (e) {
    // When Local/session storage is not available
    return thunkApi.rejectWithValue({ type: 'storage-unavailable' })
  }

  thunkApi.dispatch(createHydrateAction())

  const userId = json.data.id
  Sentry.setUser({ id: userId })

  return { id: json.data.id, ...json.data.attributes }
})

export const postPasswordReset = createAsyncThunk<
  void,
  {
    email: string
  }
>('authentication/postPasswordReset', async ({ email }) => {
  const body = {
    user: {
      email,
    },
  }
  await request.post(`${ROOT}/password/reset`, { body })
  return
})

export const putPasswordReset = createAsyncThunk<
  void,
  {
    token: string
    password: string
    passwordConfirmation: string
  }
>(
  'authentication/putPasswordReset',
  async ({ token, password, passwordConfirmation }) => {
    const body = {
      user: {
        resetPasswordToken: token,
        password,
        passwordConfirmation,
      },
    }

    await request.put(`${ROOT}/password/reset`, { body })
    return
  }
)

export const postAcceptInvitation = createAsyncThunk<
  void,
  {
    token: string
    password: string
    passwordConfirmation: string
    name?: string
  }
>(
  'authentication/postAcceptInvitation',
  async ({ token, password, passwordConfirmation, name }) => {
    const body = {
      user: {
        invitationToken: token,
        password,
        passwordConfirmation,
        name,
      },
    }
    await request.post(`${ROOT}/invitations/accept`, { body })
  }
)

export const logOut = (): ReturnType<typeof createHydrateAction> => {
  clearPersistedData()
  return createHydrateAction()
}

export const changePassword = createAsyncThunk<
  void,
  {
    currentPassword: string
    password: string
  }
>('authentication/changePassword', async ({ currentPassword, password }) => {
  const body = {
    user: { currentPassword, password },
  }

  await request.post(`${ROOT}/update_password`, { body })
})

export const validateJwt = createAsyncThunk<
  boolean,
  void,
  { rejectValue: Error }
>('authentication/validateJwt', async () => {
  try {
    await request.get(`v2/me`)
    return true
  } catch (error) {
    return false
  }
})
