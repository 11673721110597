import {
  createSlice,
  SerializedError,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import * as actions from '../actions/prescriptions'
import keyBy from 'lodash/keyBy'
import { RootState } from './types'

import * as qs from 'query-string'
import { Prescription } from '@mevia/types/mapiAPI'

type PrescriptionId = string

export interface PrescriptionState {
  readonly byId: Record<PrescriptionId, Prescription>
  readonly idsByQuery: Record<PrescriptionId, PrescriptionId[]>
  readonly totalCount: number
  readonly loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  readonly error: SerializedError | null
}

const initialState: PrescriptionState = {
  byId: {},
  idsByQuery: {},
  totalCount: 0,
  loading: 'idle',
  error: null,
}

const prescriptionsSlice = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.fetchAll.fulfilled, (state, action) => {
      const query = action.meta.arg.query
      const key =
        query && Object.keys(query).length !== 0 && qs.stringify(query)

      state.byId = keyBy(action.payload.prescriptions, 'id')
      state.totalCount =
        typeof key === 'string' && key.includes('filter')
          ? state.totalCount
          : action.payload.recordCount
      state.loading = 'succeeded'
      state.error = null
      if (typeof key === 'string') {
        state.idsByQuery[key] = action.payload.prescriptions.map(({ id }) => id)
      }
    })

    builder.addMatcher(isPending(actions.fetchAll), (state) => {
      state.loading = 'pending'
    })

    builder.addMatcher(isRejected(actions.fetchAll), (state, action) => {
      state.loading = 'failed'
      state.error = action.error
    })
  },
})

export default prescriptionsSlice.reducer

export const isFetching = (state: RootState): boolean => {
  return state.prescriptions.loading === 'pending'
}

export const fetchHasSucceeded = (state: RootState): boolean => {
  return state.prescriptions.loading === 'succeeded'
}
