import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { doseTimes as getDoseTimes } from '../../reducers/utils/participantRegistration'

import { RootState } from '../../reducers/types'
import * as request from '../../utils/request'
import { saveAuthToken } from '../../utils/persistance'

const networkError = {
  name: 'NetworkError',
}

export const postPhoneVerification = createAsyncThunk<
  {
    studyCode: string
    requiredSteps: string[]
    phoneVerificationId: number
    id: number
    rememberJwt: boolean
  },
  {
    personalCode: string
    phoneNr: string | void
    remember: boolean
  }
>(
  'participant/registration/postPhoneVerification',
  async ({ personalCode, phoneNr, remember }, { rejectWithValue }) => {
    const body = {
      data: {
        type: 'study_code_user_phone_verifications',
        attributes: {
          study_code: personalCode,
          phone: phoneNr,
        },
      },
    }
    try {
      const { data } = await request.post<{
        data: {
          id: string
          attributes: {
            requiredSteps: string[]
            phoneVerificationId: number
          }
        }
      }>(`v2/study_code_user_phone_verifications`, { body })

      const { phoneVerificationId, requiredSteps } = data.attributes

      return {
        id: parseInt(data.id),
        studyCode: personalCode,
        phoneVerificationId,
        requiredSteps,
        rememberJwt: remember,
      }
    } catch (error) {
      if (error instanceof request.MapiError) {
        return rejectWithValue(error.serialize())
      } else {
        // Should be a TypeError if we end up here (which Fetch throws)
        return rejectWithValue(networkError)
      }
    }
  }
)

type PortalParticipantAttributes = {
  jwt: string
  userRegistered: boolean
  userId: string | number
}

export const registerPortalParticipant = createAsyncThunk<
  PortalParticipantAttributes,
  {
    verificationCode: string | number
    studyCode: string | number
    studyCodeUserPhoneVerificationId: string | number
  }
>(
  'participant/registration/registerPortalParticipant',
  async (
    { verificationCode, studyCode, studyCodeUserPhoneVerificationId },
    { rejectWithValue, getState }
  ) => {
    const body = {
      data: {
        type: 'portal_registrations',
        attributes: {
          studyCode,
          phoneVerificationId: studyCodeUserPhoneVerificationId,
          phoneVerificationCode: verificationCode,
        },
      },
    }

    try {
      const json = await request.post<{
        data: {
          attributes: PortalParticipantAttributes
        }
      }>('v2/portal_registrations', { body })
      const attributes = json.data.attributes

      const state = getState() as RootState
      const remember = state.participantRegistration.rememberJwt
      saveAuthToken(attributes.jwt, remember)
      return json.data.attributes
    } catch (error) {
      if (error instanceof request.MapiError) {
        return rejectWithValue(error.serialize())
      } else {
        // Should be a TypeError if we end up here (which Fetch throws)
        return rejectWithValue(networkError)
      }
    }
  }
)

export const postGoRegistration = createAsyncThunk<void, void>(
  'participant/registration/postGoRegistration',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const { morningDoseTime, eveningDoseTime } = getDoseTimes(state)
    const body = {
      data: {
        type: 'go_registrations',
        attributes: {
          doseTimes: [morningDoseTime, eveningDoseTime],
        },
      },
    }

    try {
      await request.post('v2/go_registrations', { body })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const setMorningDoseTime = createAction<string>(
  'participant/registration/setMorningDoseTime'
)

export const postResendVerification = createAsyncThunk<
  void,
  { phoneVerificationId: string | number }
>(
  'participant/registration/resendVerificationCode',
  async ({ phoneVerificationId }) => {
    const body = {
      phoneVerificationId,
    }

    await request.post(`v2/phone_verifications/resend`, {
      body,
    })
  }
)
