import { RootState } from '@mevia/reducers/types'
import { useSelector } from 'react-redux'
import { UserConfig } from '../reducers/user'

export const userIsAuthorized = (): boolean => true
export const canEditUsers = (userConfig: UserConfig): boolean =>
  userConfig.canEditUsers
export const canCreatePatients = (userConfig: UserConfig): boolean =>
  userConfig.canCreatePatients
export const canPlanDoses = (userConfig: UserConfig): boolean =>
  userConfig.canPlanDoses
export const hasDevice = (userConfig: UserConfig): boolean =>
  userConfig.hasDevice
export const canManageProject = (userConfig: UserConfig): boolean =>
  userConfig.permissions.manageProject
export const canCreateAdherenceReport = (userConfig: UserConfig): boolean =>
  userConfig.permissions.adherenceReport
export const canCreateExposureReport = (userConfig: UserConfig): boolean =>
  userConfig.permissions.exposureReport
export const canCreateCsvReport = (userConfig: UserConfig): boolean =>
  userConfig.permissions.csvReport
export const canCreateReports = (userConfig: UserConfig): boolean =>
  canCreateAdherenceReport(userConfig) || canCreateExposureReport(userConfig)

type PermissionCallback = (userConfig: UserConfig) => boolean

export const useHasPermission = (cb: PermissionCallback) => {
  const config = useSelector((state: RootState) => state.user.user?.config)
  return config ? cb(config) : false
}
