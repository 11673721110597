import {
  AnyAction,
  combineReducers,
  DeepPartial,
  ReducersMapObject,
} from 'redux'
import {
  configureStore,
  isAnyOf,
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { ThunkMiddleware } from 'redux-thunk'
import logger from 'redux-logger'
import { enhance as makeHydratable } from './reducers/utils/hydratable'
import * as reducers from './reducers'
import * as Sentry from '@sentry/react'
import { RootState } from './reducers/types'
import { mapiApi } from './mapiAPI'
import {
  MutationThunkArg,
  QueryThunkArg,
} from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { logIn } from './actions/authentication'
import { frontendAPI } from './frontendAPI'

const isQueryOrMutationAction = (action: AnyAction) =>
  (action.type.startsWith('mapiApi/executeQuery') ||
    action.type.startsWith('mapiApi/executeMutation')) &&
  isAnyOf(isRejected, isFulfilled, isPending)(action)

const enhanceActionType = (action: AnyAction) => {
  if (isQueryOrMutationAction(action)) {
    const arg = action?.meta?.arg as QueryThunkArg | MutationThunkArg
    const endpointName = arg.endpointName
    return `${action.type} - ${endpointName}`
  } else {
    return action.type
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (!action) return action

    return {
      type: enhanceActionType(action),
    }
  },
  stateTransformer: (state) => {
    if (!state) return state

    const stateKeys = Object.keys(state)
    return stateKeys.reduce((newState, key) => {
      newState[key] = '[Filtered]'
      return newState
    }, {} as Record<string, string>)
  },
})

const isLoginAction = isAsyncThunkAction(logIn)

const sentryMiddleware: ThunkMiddleware<RootState, AnyAction> =
  () => (next) => (action) => {
    const isSkipped = action?.meta?.condition
    // Include login action here as mistyped credentials aren't exceptional
    if (!isLoginAction(action) && isRejected(action) && !isSkipped) {
      Sentry.captureMessage(enhanceActionType(action))
    }

    return next(action)
  }

const reducersWithApi = {
  ...reducers,
  [mapiApi.reducerPath]: mapiApi.reducer,
  [frontendAPI.reducerPath]: frontendAPI.reducer,
} as ReducersMapObject

const rootReducer = makeHydratable(combineReducers(reducersWithApi))

const isDevEnvironment =
  process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'

const createStore = (initialState?: DeepPartial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => {
      const optionalMiddleware = []
      if (isDevEnvironment) {
        optionalMiddleware.push(logger)
      }
      return getDefaultMiddleware()
        .concat(mapiApi.middleware)
        .concat(frontendAPI.middleware)
        .concat(sentryMiddleware)
        .concat(optionalMiddleware)
    },
    enhancers: [sentryReduxEnhancer],
  })

export type AppDispatch = ReturnType<typeof createStore>['dispatch']

export default createStore
