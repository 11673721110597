import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html {
    // Typography
    --font-heading: 'PT sans', var(--font-body);
    --font-body: 'Roboto', Helvetica, Arial, sans-serif;
    --font-button: 'Inconsolata', monospace;

    // Font sizes
    --font-size-l: 1.5rem; // 24px
    --font-size-m: 1.125rem; // 18px
    --font-size-default: 1rem;
    --font-size-s: .875rem; // 14px
    --font-size-xs: .75rem; // 12px

    // Font weights
    --normal: 400;
    --medium: 500;
    --bold: 700;

    // Spacing sizes
    --spacing-xxxl: 6rem; // 96px
    --spacing-xxl: 4rem; // 64px
    --spacing-xl: 3rem; // 48px
    --spacing-l: 2rem; //32px
    --spacing-m: 1.5rem; //24px
    --spacing-s: 1rem;
    --spacing-xs: .75rem; // 12px
    --spacing-xxs: .375rem; // 6px
    --spacing-xxxs: .188rem; // 3px

    // Border radius
    --border-radius: .625rem; //10px
    --border-radius-s: .313rem; //5px
    --border-radius-xs: .125rem; //2px

    // Box shadow
    --box-shadow: 0 2px 15px rgba(74, 89, 109, .1);

    // Brand colors
    --color-blue-alpha: #0d213c;
    --color-blue-beta: #4a596d;
    --color-blue-gamma: #86909D;
    --color-blue-delta: #C2C7CE;

    // Accent colors
    --color-orange-alpha: #f69155;
    --color-orange-beta: #f8aa81;
    --color-orange-gamma: #FAC6AB;
    --color-orange-delta: #FDE3D5;

    --color-light-blue-alpha: #467ba9;
    --color-light-blue-beta: #749cbf;
    --color-light-blue-gamma: #A2BDD4;
    --color-light-blue-delta: #D1DEE9;

    // UI colors
    --color-red-alpha: #C23434;
    --color-red-beta: #D16767;
    --color-red-gamma: #E09999;
    --color-red-delta: #F6E5E5;

    --color-yellow-alpha: #FFD364;
    --color-yellow-beta: #FFDE8B;
    --color-yellow-gamma: #FFE9B1;
    --color-yellow-delta: #FFF4D8;

    --color-green-alpha: #357655;
    --color-green-beta: #56AA7C;
    --color-green-gamma: #8EC6A7;
    --color-green-delta: #DDEEE5;

    // Black and greys
    --color-black-alpha: #231F20;
    --color-black-alpha-transparent: rgba(35, 31, 32, .5);
    --color-black-beta: #5A5758;
    --color-black-gamma: #918F8F;
    --color-black-delta: #C8C7C7;
    --color-black-epsilon: #E9E8E8;

    --color-grey-alpha: #F3F2F1;
    --color-grey-beta: #F6F5F5;
    --color-grey-gamma: #F9F8F8;
    --color-grey-delta: #FCFCFB;
    --color-white: #FFFFFF;
  }
`
