import React from 'react'
import AdminHeader from '@mevia/components/organisms/Header/AdminHeader'
// @ts-expect-error Not a TS file
import Block from '@mevia/components/atoms/Block'
import { MainWrapper } from '@mevia/components/atoms/MainWrapper'

export const Layout = ({
  children,
  isLoadingApp = false,
}: {
  children: React.ReactNode
  isLoadingApp?: boolean
}) => (
  <Block data-user-config-fetched="true" data-is-loading-app={isLoadingApp}>
    <AdminHeader />
    <MainWrapper>{children}</MainWrapper>
  </Block>
)
