import { schema } from 'normalizr'

export const event = new schema.Entity('events')
export const flag = new schema.Entity('flags')
export const flagPolicy = new schema.Entity('flagPolicies')
export const miaModuleSchedule = new schema.Entity('miaModuleSchedules')
export const notificationPolicy = new schema.Entity('notificationPolicies')
export const prescription = new schema.Entity('prescriptions')
export const recipient = new schema.Entity('recipients')
export const scheduledDose = new schema.Entity('scheduledDoses')
export const scheduledDoseSchema = new schema.Entity('scheduledDoseSchemas')
export const takenPod = new schema.Entity('takenPods')
export const packages = new schema.Entity('packages')
export const user = new schema.Entity('users')
export const prescriptionSummary = new schema.Entity('prescriptionSummaries')
