import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { CheckIcon, MinusIcon } from '../Icon'

const CheckboxContainer = styled.label`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
`

// Hidden but accessible
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
`

const checkboxStateStyles = {
  checked: css`
    background: var(--color-blue-alpha);
    border-color: var(--color-blue-alpha);
  `,
  indeterminate: css`
    background: var(--color-blue-gamma);
    border-color: var(--color-blue-gamma);
  `,
}

const StyledCheckbox = styled.div`
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  width: var(--spacing-s);
  height: var(--spacing-s);
  background: var(--color-white);
  border: 1px solid var(--color-black-delta);
  border-radius: var(--border-radius-xs);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  ${(props) => checkboxStateStyles[props.state]}

  ${HiddenCheckbox}:focus + & {
    border-color: var(--color-blue-alpha);
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-white);
  transform: translate(-50%, -50%);
`

const StyledMinusIcon = styled(MinusIcon)`
  visibility: ${(props) => (props.indeterminate ? 'visible' : 'hidden')};
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-white);
  transform: translate(-50%, -50%);
`

const labelSizeStyles = {
  small: css`
    margin-left: var(--spacing-xxs);
    font-size: var(--font-size-s);
  `,
  large: css`
    margin-left: var(--spacing-xs);
  `,
}

const Label = styled.span`
  margin-left: var(--spacing-xs);
  color: var(--color-black-alpha);
  line-height: 1.1;
  display: flex;
  flex: 1;

  ${(props) => labelSizeStyles[props.size]}
`

const getState = (checked, indeterminate) => {
  if (checked) return 'checked'
  if (indeterminate) return 'indeterminate'

  return 'default'
}

// Exported to support checkbox styling within our TreeSelect component. The actual
// <input type=checkbox .../> steals focus from react-select resulting in a
// closed menu.
export const CheckboxWrapper = ({
  checked,
  indeterminate,
  label,
  size = 'large',
  className,
  children,
}) => {
  const state = getState(checked, indeterminate)

  return (
    <CheckboxContainer data-cy="checkbox" className={className}>
      {children}
      <StyledCheckbox
        checked={checked}
        indeterminate={indeterminate}
        state={state}
      >
        {indeterminate && (
          <StyledMinusIcon
            checked={checked}
            indeterminate={indeterminate}
            noSpacing
            size="tiny"
          />
        )}
        {checked && (
          <StyledCheckIcon
            checked={checked}
            indeterminate={indeterminate}
            noSpacing
            size="tiny"
          />
        )}
      </StyledCheckbox>
      <Label size={size}>{label}</Label>
    </CheckboxContainer>
  )
}

const CustomCheckbox = (props) => {
  const { checked, indeterminate, ...restProps } = props
  const checkboxRef = useRef()

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate
    }
  }, [checkboxRef, indeterminate])

  return (
    <CheckboxWrapper {...props}>
      <HiddenCheckbox ref={checkboxRef} checked={checked} {...restProps} />
    </CheckboxWrapper>
  )
}

export default CustomCheckbox
