export const BP_XXL_PIXELS = 1432
export const BP_XXL = `${BP_XXL_PIXELS}px`
export const BP_XL_PIXELS = 1160
export const BP_XL = `${BP_XL_PIXELS}px`
export const BP_L_PIXELS = 1025
export const BP_L = `${BP_L_PIXELS}px`
export const BP_M_PIXELS = 900
export const BP_M = `${BP_M_PIXELS}px`
export const BP_S_PIXELS = 600
export const BP_S = `${BP_S_PIXELS}px`
export const BP_S_DOWN_PIXLES = 599
export const BP_S_DOWN = `${BP_S_DOWN_PIXLES}px`
