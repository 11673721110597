import { getProjectLocale } from '../utils/persistance'
import { RootState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  defaultProjectLocale,
  getProjectLocales,
  getSelectedLocale as getSelectedLocaleData,
  ProjectLocale,
} from '../locales/'
export type { ProjectLocale }

export type PreferredTimeZoneType = 'browser' | 'participant'
export type ParticipantTimeZone = string | null

export interface LocalizationState {
  locale: ProjectLocale
  preferredTimeZoneType: PreferredTimeZoneType
  participantTimeZone: ParticipantTimeZone
}

const initialLocale: ProjectLocale = getProjectLocale() || defaultProjectLocale

const initialState: LocalizationState = {
  locale: initialLocale,
  preferredTimeZoneType: 'participant',
  participantTimeZone: null,
}

const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<ProjectLocale>) {
      state.locale = action.payload
    },
    setPreferredTimeZoneType(
      state,
      action: PayloadAction<PreferredTimeZoneType>
    ) {
      state.preferredTimeZoneType = action.payload
    },
    setParticipantTimeZone(state, action: PayloadAction<ParticipantTimeZone>) {
      state.participantTimeZone = action.payload
    },
  },
})

export default localizationSlice.reducer

// Selectors
export const { setLocale, setParticipantTimeZone, setPreferredTimeZoneType } =
  localizationSlice.actions

export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const getSelectedLocale = (state: RootState): ProjectLocale =>
  state.localization.locale

export const getLocalesForSelectedProject = (
  state: RootState
): ReturnType<typeof getProjectLocales> => {
  const project = state.localization.locale.project
  return getProjectLocales(project)
}

export const getSelectedProjectLocale = (state: RootState) => {
  const selectedProjectLocale = getSelectedLocale(state)

  return getSelectedLocaleData(selectedProjectLocale)
}

export const getPreferredTimeZoneType = (
  state: RootState
): PreferredTimeZoneType => state.localization.preferredTimeZoneType
