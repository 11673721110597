import React from 'react'
import Block from '@mevia/components/atoms/Block'

const defaultStyle = {
  width: '100%',
  height: '100%',
  WebkitAppearance: 'menulist-button',
}

const UnstylableElementOverlay = ({ overlayElement, unstylableElement }) => (
  <Block position="relative">
    {overlayElement}
    <Block
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      opacity={0}
    >
      {React.cloneElement(unstylableElement, { style: defaultStyle })}
    </Block>
  </Block>
)

export default UnstylableElementOverlay
