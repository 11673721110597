import React from 'react'
import styled from 'styled-components'
import ButtonTheme, { theme } from './ButtonTheme'

const ButtonElement = styled.button`
  display: ${(props) => props.display};
  align-items: ${(props) => (props.display === 'flex' ? 'center' : '')};
  justify-content: ${(props) => (props.display === 'flex' ? 'center' : '')};
  width: ${(props) => props.width};
  padding: ${(props) => props.spacing};
  border: ${({ theme }) =>
    theme.border ? theme.border : '1px solid transparent'};
  border-radius: 2em;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
  text-decoration: none;
  line-height: 1;
  font-family: var(--font-button);
  font-size: ${(props) => props.fontSize};
  transition: background-color 0.15s ease-in-out;

  :active,
  :visited {
    color: ${({ theme }) => theme.color};
  }

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.hoverBg};
    color: ${({ theme }) => theme.hoverColor};
  }

  :disabled {
    background-color: ${(props) =>
      props.variant === 'lowEmphasis' ? theme.bg : 'var(--color-grey-beta)'};
    border-color: var(--color-grey-beta);
    color: var(--color-black-beta);
  }
`

const getSpacing = (variant, size) => {
  if (variant === 'lowEmphasis' || variant == 'lowEmphasisWarning') {
    return ''
  }

  if (size === 'tiny') return theme.size.tiny
  if (size === 'small') return theme.size.small

  return theme.size.default
}

const Button = ({
  icon,
  iconRight,
  fullWidth,
  size = 'default',
  children,
  component = 'button',
  ...props
}) => {
  const themeVariant = props.variant ? theme[props.variant] : theme.default
  const fontSize =
    size === 'tiny' || size === 'small'
      ? theme.fontSize.small
      : theme.fontSize.default
  const spacing = getSpacing(props.variant, size)
  const display = icon || iconRight ? 'flex' : 'inline-block'
  const width = fullWidth ? '100%' : ''

  return (
    <ButtonTheme>
      <ButtonElement
        as={component}
        theme={themeVariant}
        fontSize={fontSize}
        spacing={spacing}
        display={display}
        width={width}
        {...props}
      >
        {icon}
        {children}
        {iconRight}
      </ButtonElement>
    </ButtonTheme>
  )
}

export default Button
