import { useContext } from 'react'
import { browserTimeZone } from '../reducers/localization'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { isToday, isYesterday } from 'date-fns'
import DateFnsLocales from '../utils/dateFnsLocales'
import useTimeZone from '@mevia/features/timeZoneSwitcher/hooks/useTimeZone'
import type { Locale } from 'date-fns'

type DateFnsDate = Date | string | number

const formatInTimeZone =
  (timeZone: string, locale: Locale | null) =>
  (date: DateFnsDate, pattern: string): ReturnType<typeof format> => {
    const zonedTime = utcToZonedTime(date, timeZone)
    return format(zonedTime, pattern, { timeZone, locale: locale || undefined })
  }

const isTodayInTimeZone =
  (timeZone: string) =>
  (date: DateFnsDate): ReturnType<typeof isToday> => {
    const zonedTime = utcToZonedTime(date, timeZone)
    return isToday(zonedTime)
  }

const isYesterdayInTimeZone =
  (timeZone: string) =>
  (date: DateFnsDate): ReturnType<typeof isYesterday> => {
    const zonedTime = utcToZonedTime(date, timeZone)
    return isYesterday(zonedTime)
  }

const utcDateFromTimeZone =
  (timeZone: string) =>
  (date: DateFnsDate): ReturnType<typeof zonedTimeToUtc> => {
    return zonedTimeToUtc(date, timeZone)
  }

export interface DateFnsInTimeZone {
  format: ReturnType<typeof formatInTimeZone>
  isToday: ReturnType<typeof isTodayInTimeZone>
  isYesterday: ReturnType<typeof isYesterdayInTimeZone>
  toUtcDate: ReturnType<typeof utcDateFromTimeZone>
  timeZone: string
  locale: Locale | null
}

export const dateFnsInTimeZone = (
  timeZone: string,
  locale: Locale | null
): DateFnsInTimeZone => {
  return {
    format: formatInTimeZone(timeZone, locale),
    isToday: isTodayInTimeZone(timeZone),
    isYesterday: isYesterdayInTimeZone(timeZone),
    toUtcDate: utcDateFromTimeZone(timeZone),
    timeZone,
    locale,
  }
}

export const useDateFnsInSubjectTimeZone = (): ReturnType<
  typeof dateFnsInTimeZone
> | null => {
  const { subjectTimeZone } = useTimeZone()
  const dateFnLocale = useContext(DateFnsLocales)

  if (!subjectTimeZone) return null

  return dateFnsInTimeZone(subjectTimeZone, dateFnLocale)
}

export const useDateFnsInBrowserTimeZone = (): ReturnType<
  typeof dateFnsInTimeZone
> => {
  const timeZone = browserTimeZone
  const dateFnLocale = useContext(DateFnsLocales)

  return dateFnsInTimeZone(timeZone, dateFnLocale)
}

export const useDateFnsInTimeZone = (
  timeZone: string
): ReturnType<typeof dateFnsInTimeZone> => {
  const dateFnLocale = useContext(DateFnsLocales)

  return dateFnsInTimeZone(timeZone, dateFnLocale)
}

export const useDateFnsInUTC = (): ReturnType<typeof useDateFnsInTimeZone> => {
  return useDateFnsInTimeZone('UTC')
}

export const useDateFnsInPreferredTimeZone = (): ReturnType<
  typeof dateFnsInTimeZone
> => {
  const { preferredTimeZone } = useTimeZone()
  const dateFnLocale = useContext(DateFnsLocales)

  return dateFnsInTimeZone(preferredTimeZone, dateFnLocale)
}
