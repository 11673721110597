import React from 'react'
import { keyframes } from 'glamor'
import Block from './Block'

const spin = keyframes({
  '0%': { transform: 'rotate(0deg) scale(1)' },
  '50%': { transform: 'rotate(180deg) scale(1.1)' },
  '100%': { transform: 'rotate(360deg) scale(1)' },
})

const Spinner = ({
  size = '2em',
  borderWidth = '2px',
  color = 'white',
  background = 'rgba(255,255,255,0.25)',
  ...props
}) => (
  <Block
    width={size}
    height={size}
    borderTop={`${borderWidth} solid ${background}`}
    borderRight={`${borderWidth} solid ${background}`}
    borderBottom={`${borderWidth} solid ${background}`}
    borderLeft={`${borderWidth} solid ${color}`}
    transform="translateZ(0)"
    borderRadius="100%"
    animation={`${spin} 1s infinite linear`}
    {...props}
  />
)

export default Spinner
