import { useGetLatestVersionQuery } from '@mevia/frontendAPI'

const currentVersion = process.env.APP_VERSION

export function useVersionMonitor() {
  const { data: latestVersion, isSuccess } = useGetLatestVersionQuery(
    undefined,
    {
      pollingInterval: 1000 * 60,
    }
  )

  const hasNewVersion =
    isSuccess &&
    typeof latestVersion === 'string' &&
    typeof currentVersion === 'string' &&
    latestVersion.trim() !== currentVersion.trim()

  return { hasNewVersion }
}
