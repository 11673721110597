import React, { Suspense } from 'react'
import Loader from '@mevia/components/molecules/Loader'
import { Layout } from '@mevia/Layout'

export const SuspenseWithLayout = ({
  children,
  fallback,
}: {
  children: React.ReactNode
  fallback: React.ComponentProps<typeof Suspense>['fallback']
}) => (
  <Suspense
    fallback={
      <Layout isLoadingApp={true}>
        <Loader label={fallback} />
      </Layout>
    }
  >
    {children}
  </Suspense>
)
