import defaultLocales, { Locales } from './default'
import type { Locale as DateFnsLocale } from 'date-fns'
import type { LocaleInput as FullCalendarLocale } from '@fullcalendar/react'

type LocalizationLabel = { label?: React.ReactElement }
type Translations = {
  messages: () => Promise<{ default: Record<string, string> }>
  dateFnsLocale?: () => Promise<{ default: DateFnsLocale }>
  fullCalendarLocale?: () => Promise<{ default: FullCalendarLocale }>
}

const defaultProjectName = 'default'
export type DefaultProject = {
  project: typeof defaultProjectName
  locale: Locales
}

export type ProjectLocale = DefaultProject

export const defaultProjectLocale: ProjectLocale = {
  project: 'default',
  locale: 'en',
}

type LocaleKeys = Locales

export const getProjectLocales = (
  project: ProjectLocale['project']
): typeof defaultLocales => {
  if (project === defaultProjectName) return defaultLocales
  return defaultLocales
}

function getLocaleOrUseFallback(
  localeKey: LocaleKeys,
  localeData?: Translations & LocalizationLabel
) {
  if (!localeData) {
    return {
      locale: 'en',
      ...defaultLocales.en,
    }
  } else {
    return {
      locale: localeKey,
      ...localeData,
    }
  }
}

export const getSelectedLocale = (projectLocale: ProjectLocale) => {
  if (projectLocale.project === defaultProjectName) {
    return getLocaleOrUseFallback(
      projectLocale.locale,
      defaultLocales[projectLocale.locale]
    )
  } else {
    return getLocaleOrUseFallback('en', defaultLocales.en)
  }
}

export function isProjectLocale(projectLocale: {
  project: string
  locale: string
}): projectLocale is ProjectLocale {
  if (projectLocale.project === defaultProjectName) {
    return projectLocale.locale in defaultLocales
  } else {
    return false
  }
}
