import { createSlice, isPending } from '@reduxjs/toolkit'
import * as actions from '@mevia/actions/notes'
import { Note } from './types'
import { RootState } from '../types'

export interface NotesState {
  readonly byPrescriptionId: Partial<Record<string | number, Note[]>>
  readonly fetchingError: boolean
  readonly isFetching: boolean
}

const initialState: NotesState = {
  isFetching: false,
  fetchingError: false,
  byPrescriptionId: {},
}

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      actions.fetchByPrescriptionId.fulfilled,
      (state, action) => {
        state.isFetching = false
        state.fetchingError = false
        state.byPrescriptionId = {
          ...state.byPrescriptionId,
          [action.meta.arg.prescriptionId]: action.payload,
        }
      }
    )

    builder.addCase(actions.postNote.fulfilled, (state, action) => {
      const existingPrescriptionNotes =
        state.byPrescriptionId[action.meta.arg.prescriptionId] || []

      state.isFetching = false
      state.fetchingError = false
      state.byPrescriptionId = {
        ...state.byPrescriptionId,
        [action.meta.arg.prescriptionId]: [
          action.payload,
          ...existingPrescriptionNotes,
        ],
      }
    })

    builder.addCase(actions.fetchByPrescriptionId.rejected, (state) => {
      state.fetchingError = true
    })

    builder.addMatcher(isPending(actions.fetchByPrescriptionId), (state) => {
      state.isFetching = true
    })
  },
})

export default notesSlice.reducer

export const selectByPrescriptionId = (
  prescriptionId: string | number,
  state: RootState
): Note[] => {
  return state.notes.byPrescriptionId[prescriptionId] || []
}
