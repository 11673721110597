import React, { ReactElement, ReactNode } from 'react'
// @ts-expect-error Not a TS-file
import Block from '@mevia/components/atoms/Block'
// @ts-expect-error Not a TS-file
import Spinner from '@mevia/components/atoms/Spinner'
import styled from 'styled-components'

const Label = styled.div`
  margin-top: var(--spacing-m);
  color: var(--color-black-alpha);
`

interface Props {
  height?: string
  spinnerSize?: string
  label?: ReactNode
}

const Loader = ({
  height,
  spinnerSize = '10rem',
  label,
}: Props): ReactElement => (
  <Block
    data-testid="loader"
    column
    alignItems="center"
    justifyContent="center"
    minHeight={height}
  >
    <Spinner
      size={spinnerSize}
      borderWidth="0.2rem"
      color={'var(--color-black-alpha)'}
      background="transparent"
    />
    {label && <Label>{label}</Label>}
  </Block>
)

export default Loader
