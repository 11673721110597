import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// @ts-expect-error Not a TS file
import DropdownWrapper from '@mevia/components/atoms/DropdownWrapper'
import {
  SettingsIcon,
  LeaveIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@mevia/components/atoms/Icon'
import ToggleButton from '@mevia/components/atoms/DropdownToggleButton'
import { useHandleLogout } from '@mevia/features/auth/hooks/useHandleLogOut'

const Wrapper = styled.div`
  position: relative;
`

const dropdownItemStyles = `
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
  border-radius: 0;
  min-width: 300px;
  text-align: left;
  color: var(--color-black-beta);
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    color: inherit;
    background-color: var(--color-grey-gamma);
  }

  &:last-child {
    border-top: 1px solid var(--color-black-epsilon);
  }
`

const DropdownButton = styled.button`
  ${dropdownItemStyles}
`

const DropdownLink = styled(Link)`
  ${dropdownItemStyles}
  text-decoration: none;

  &:active,
  &:visited {
    color: var(--color-black-beta);
  }
`

const DropdownText = styled.span`
  margin-left: var(--spacing-xxs);
`

const DropdownMenu = styled(DropdownWrapper)``

const LogOutButton = () => {
  const logout = useHandleLogout()

  return (
    <DropdownButton onClick={logout}>
      <LeaveIcon />
      <DropdownText>
        <FormattedMessage
          id="admin-menu.log-out-button-label"
          defaultMessage="Log out"
        />
      </DropdownText>
    </DropdownButton>
  )
}

const AccountDropdown = (): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null)

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      const button = event.currentTarget
      setAnchorEl(button)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Wrapper>
      <ToggleButton
        data-cy="account-dropdown"
        active={!!anchorEl}
        onClick={handleClick}
      >
        <FormattedMessage
          id="admin-menu.account-button-label"
          defaultMessage="Account"
        />
        {anchorEl ? (
          <ChevronUpIcon spacingLeft />
        ) : (
          <ChevronDownIcon spacingLeft />
        )}
      </ToggleButton>
      <DropdownMenu
        dataCy="account-dropdown-menu"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <DropdownLink
          data-cy="settings-link"
          to="/settings"
          onClick={handleClose}
        >
          <SettingsIcon />
          <DropdownText>
            <FormattedMessage
              id="admin-menu.settings-button-label"
              defaultMessage="Settings"
            />
          </DropdownText>
        </DropdownLink>
        <LogOutButton />
      </DropdownMenu>
    </Wrapper>
  )
}

export default AccountDropdown
