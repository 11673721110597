import { RootState } from '../types'

const formatNumberAsTime: (num: number) => string = (num) =>
  num < 10 ? `0${num}` : `${num}`

export const doseTimeByOffset = ({
  doseTime,
  offset = 12,
}: {
  doseTime: string
  offset?: number
}): string => {
  const [startHour, startMinute] = doseTime
    .split(':')
    .map((num) => parseInt(num))
  const endHour = (startHour + offset) % 24
  const endMinute = startMinute

  return `${formatNumberAsTime(endHour)}:${formatNumberAsTime(endMinute)}`
}

export const doseTimes: (state: RootState) => {
  morningDoseTime: string
  eveningDoseTime: string
} = (state: RootState) => {
  const morningDoseTime = state.participantRegistration
    .morningDoseTime as string

  return {
    morningDoseTime,
    eveningDoseTime: doseTimeByOffset({ doseTime: morningDoseTime }),
  }
}
