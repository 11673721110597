import React from 'react'
import styled from 'styled-components'

const StyledBox = styled.div`
  height: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-white);
`

type Props = React.ComponentPropsWithoutRef<'div'>

export const BoxShadow = ({ children, ...props }: Props) => (
  <StyledBox {...props}>{children}</StyledBox>
)
