import { createSlice } from '@reduxjs/toolkit'
import * as actions from '../../actions/participant/questions'

export interface Question {
  question: string
  answer: string
}

export interface QuestionsState {
  readonly loading: boolean
  readonly questions: Question[]
}

const initialState: QuestionsState = {
  loading: false,
  questions: [],
}

const questionsSlice = createSlice({
  name: 'participant/questions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.fetchAll.fulfilled, (state, action) => {
      state.questions = action.payload
      state.loading = false
    })
    builder.addCase(actions.fetchAll.pending, (state) => {
      state.loading = true
    })
    builder.addCase(actions.fetchAll.rejected, (state) => {
      state.loading = false
    })
  },
})

export default questionsSlice.reducer
