import React, { useState, useEffect } from 'react'
import { parse as parseQueryString } from 'query-string'
import {
  FormattedMessage,
  defineMessages,
  useIntl,
  MessageDescriptor,
} from 'react-intl'
import { logIn, LoginError, validateJwt } from '../actions/authentication'
// @ts-expect-error Not a TS file
import CustomCheckbox from '@mevia/components/atoms/CustomCheckbox'
// @ts-expect-error Not a TS file
import Input from '@mevia/components/atoms/Input'
import InputWrapper from '@mevia/components/molecules/InputWrapper'
// @ts-expect-error Not a TS-file
import AuthContainer from '@mevia/components/organisms/AuthContainer'
import isValidEmail from '../utils/validateEmail'
import { unwrapResult } from '@reduxjs/toolkit'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleErrorMessage from '@mevia/components/atoms/SimpleErrorMessage'
import { useAppDispatch, useAppSelector } from '@mevia/storeHooks'

const loginInputMessages = defineMessages({
  'login-username-input-placeholder': {
    id: 'login-username-placeholder',
    defaultMessage: 'Enter username or email',
  },
  'login-password-input-placeholder': {
    id: 'login-password-placeholder',
    defaultMessage: 'Enter password',
  },
})

type LoginErrorType = LoginError['type']

const errorMessages = defineMessages<LoginErrorType, MessageDescriptor>({
  'invalid-credentials': {
    id: 'login-wrong-username-or-password',
    defaultMessage: 'Username or password is incorrect.',
  },
  'storage-unavailable': {
    id: 'login-error-storage-unavailable',
    defaultMessage:
      "Storage unavailable. Please make sure that the site is allowed to access the browser's storage.",
  },
  unknown: {
    id: 'login-unknown-error',
    defaultMessage:
      'An error occurred. Please try again later or contact support.',
  },
})

function usePageQueryParamOrFallback(): string {
  const location = useLocation()
  const query = parseQueryString(location.search)
  if ('p' in query && typeof query.p === 'string') return query.p
  else return '/'
}

const LogIn = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const pageOnSuccessfulLogin = usePageQueryParamOrFallback()

  const { isLoading, loginError } = useAppSelector((state) => state.user)
  const isInvalidCredentialsError = loginError?.type === 'invalid-credentials'

  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)

  useEffect(() => {
    dispatch(validateJwt())
      .then(unwrapResult)
      .then((valid) => {
        if (valid) {
          navigate(pageOnSuccessfulLogin, { replace: true })
        }
      })
  }, [])

  const onSubmit = () => {
    if (!userName || !password) return
    const isEmail = isValidEmail(userName)
    return dispatch(
      logIn(
        isEmail
          ? { email: userName, password, remember }
          : { userName, password, remember }
      )
    )
      .then(unwrapResult)
      .then(() => {
        navigate(pageOnSuccessfulLogin)
      })
      .catch(() => {
        // Error is handled by the reducer, see `loginError`
      })
  }

  return (
    <>
      <AuthContainer
        onSubmit={onSubmit}
        title={
          <FormattedMessage
            id="login-header"
            defaultMessage="Login to your account"
          />
        }
        errorContent={
          loginError && (
            <SimpleErrorMessage
              message={intl.formatMessage(errorMessages[loginError?.type])}
            />
          )
        }
        buttonContent={
          isLoading ? (
            <FormattedMessage
              id="login-submit-button-label-pending-request"
              defaultMessage="Authenticating..."
            />
          ) : (
            <FormattedMessage
              id="login-submit-button-label"
              defaultMessage="Login"
            />
          )
        }
        linkContent={
          <FormattedMessage
            id="login-forgot-password"
            defaultMessage="Forgot password?"
          />
        }
        linkTo="/forgot_password"
      >
        <InputWrapper>
          <Input
            type="text"
            placeholder={intl.formatMessage(
              loginInputMessages['login-username-input-placeholder']
            )}
            value={userName}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setUserName(e.currentTarget.value)
            }
            autoFocus
            required
            invalidInput={isInvalidCredentialsError}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="password"
            placeholder={intl.formatMessage(
              loginInputMessages['login-password-input-placeholder']
            )}
            value={password}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPassword(e.currentTarget.value)
            }
            required
            invalidInput={isInvalidCredentialsError}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomCheckbox
            label={
              <FormattedMessage
                id="login-remember"
                defaultMessage="Remember me"
              />
            }
            checked={remember}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setRemember(e.currentTarget.checked)
            }
            id="remember"
          />
        </InputWrapper>
      </AuthContainer>
    </>
  )
}

export default LogIn
