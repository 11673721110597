import * as request from '@mevia/utils/request'
import { Guides } from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const completeGuide = createAsyncThunk<Guides[], { guide: Guides }>(
  'completedGuides/completeGuide',
  async ({ guide }, { rejectWithValue }) => {
    const body = {
      name: guide,
    }
    try {
      const json = await request.post<{ completedGuides: Guides[] }>(
        'v2/users/completed_guides',
        { body }
      )

      return json.completedGuides
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
