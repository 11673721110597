import React from 'react'
import styled from 'styled-components'
import { WarningIcon } from './Icon'

const InputWrapper = styled.div`
  position: relative;
`

const InputIcon = styled(WarningIcon)`
  position: absolute;
  top: 50%;
  right: 0.375rem;
  transform: translateY(-50%);
`

export const InputElement = styled.input.attrs((props) => ({
  type: props.type ? props.type : 'text',
}))`
  height: var(--spacing-xl);
  width: ${(props) => (props.inline ? '' : '100%')};
  padding: var(--spacing-xs) var(--spacing-s);
  border: 1px solid
    ${(props) =>
      props.invalidInput
        ? 'var(--color-red-alpha)'
        : 'var(--color-black-delta)'};
  border-radius: var(--border-radius-s);
  background: var(--color-white);
  color: var(--color-black-alpha);
  line-height: 1;
  outline: none;
  opacity: 1; // Override iOS opacity change affecting text & background color

  &::placeholder {
    color: var(--color-black-beta);
  }

  // Make sure Firefox doesn't display lighter placeholder text
  &::-moz-placeholder {
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) =>
      props.invalidInput
        ? 'var(--color-red-alpha)'
        : 'var(--color-black-gamma)'};
  }

  &:disabled {
    border-color: var(--color-grey-beta);
    background-color: var(--color-grey-beta);
    color: var(--color-black-beta);
    -webkit-text-fill-color: var(
      --color-black-beta
    ); // Override iOS/Android font color change
    cursor: not-allowed;
  }

  // Don't want to interfere with the Browser's built in datepicker icon
  &[type='date'] ~ ${InputIcon} {
    display: none;
  }
`

const Input = React.forwardRef(
  ({ id, placeholder, invalidInput, ...props }, ref) => (
    <InputWrapper>
      <InputElement
        id={id}
        ref={ref}
        invalidInput={invalidInput}
        placeholder={placeholder}
        {...props}
      />
      {invalidInput && <InputIcon color={'var(--color-red-alpha)'} />}
    </InputWrapper>
  )
)
Input.displayName = 'Input'

export default Input
