import React from 'react'
import { FormattedMessage } from 'react-intl'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import Button from '@mevia/components/atoms/Button'
import Heading from '@mevia/components/atoms/Heading'
import { CloseIcon } from '@mevia/components/atoms/Icon'

const CloseButtonWrapper = styled.button`
  position: absolute;
  top: var(--spacing-xs);
  right: var(--spacing-xs);
  z-index: 1;
  color: var(--color-black-alpha);
`

export const CloseButton = ({ onClick }) => (
  <CloseButtonWrapper data-cy="modal-close-button" onClick={onClick}>
    <CloseIcon size="tiny" noSpacing />
  </CloseButtonWrapper>
)

const ModalHeaderElement = styled.div`
  padding: var(--spacing-m);
  border-bottom: 1px solid var(--color-black-epsilon);
`

export const ModalContent = styled.div`
  padding: var(--spacing-m);
  color: var(--color-black-alpha);
`

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--spacing-m);
  background-color: var(--color-grey-beta);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  color: var(--color-black-beta);

  > :first-child {
    margin-right: auto;
  }

  > :only-child {
    margin-right: 0;
  }

  > :not(:first-child) {
    margin-left: var(--spacing-s);
  }
`

const style = {
  overlay: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 'var(--spacing-s)',
    background: 'var(--color-black-alpha-transparent)',
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '600px',
    maxHeight: '100%',
    maxWidth: '100%',
    border: 0,
    borderRadius: 'var(--border-radius)',
    padding: 0,
  },
}

export const BaseModal = ({
  children,
  onRequestClose,
  isOpen,
  style: contentStyleOverrides = {},
  overlayStyle: overlayStyleOverrides = {},
  ...props
}) => {
  if (!isOpen) return null

  return (
    <ReactModal
      isOpen
      onRequestClose={onRequestClose}
      {...props}
      style={{
        overlay: {
          ...style.overlay,
          ...overlayStyleOverrides,
        },
        content: {
          ...style.content,
          ...contentStyleOverrides,
        },
      }}
    >
      <CloseButton onClick={onRequestClose} />
      {children}
    </ReactModal>
  )
}

export const ModalHeader = ({ title }) => (
  <ModalHeaderElement>
    <Heading title={title} headingLevel={'h2'} />
  </ModalHeaderElement>
)

const Modal = ({
  title,
  children,
  footer,
  isOpen,
  onRequestClose,
  style: contentStyleOverrides,
  ...props
}) => {
  if (!isOpen) return null

  return (
    <BaseModal
      isOpen
      onRequestClose={onRequestClose}
      {...props}
      style={contentStyleOverrides}
    >
      {title && <ModalHeader title={title} />}
      <ModalContent>{children}</ModalContent>
      {footer && (
        <ModalFooter>
          <Button variant="lowEmphasis" onClick={onRequestClose}>
            <FormattedMessage
              id="site-wide-words.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        </ModalFooter>
      )}
    </BaseModal>
  )
}

export const ModalContainer = ({ header, children, footer }) => {
  return (
    <>
      {header && <ModalHeaderElement>{header}</ModalHeaderElement>}
      <ModalContent>{children}</ModalContent>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </>
  )
}

export default Modal
