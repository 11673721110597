import { Template } from '@mevia/reducers/templates'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'

interface ResponseShape<T> {
  id: string
  type: string
  attributes: Omit<T, 'id'>
}

type TemplateResponseShape = {
  data: Array<ResponseShape<Template>>
}

export const fetch = createAsyncThunk<Array<Template>>(
  'templates/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const json = await request.get<TemplateResponseShape>(
        'v2/patient_communication_templates'
      )
      return json.data.map(({ id, attributes }) => ({
        id,
        ...attributes,
      }))
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
