import React from 'react'
import { useToastState } from '@react-stately/toast'
import { Toastable } from '@mevia/features/toast/Toast'
import {
  ToastRegion,
  ToastRegionProps,
} from '@mevia/features/toast/ToastRegion'
import { ToastContext } from '@mevia/features/toast/toastContext'

interface ToastProviderProps extends Omit<ToastRegionProps, 'state'> {
  children: React.ReactNode
}

export function ToastProvider({ children, ...props }: ToastProviderProps) {
  const state = useToastState<Toastable>({
    maxVisibleToasts: 5,
  })

  return (
    <ToastContext.Provider value={state}>
      {children}
      {state.visibleToasts.length > 0 && (
        <ToastRegion {...props} state={state} />
      )}
    </ToastContext.Provider>
  )
}
