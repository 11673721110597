import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

type Props = React.ComponentPropsWithoutRef<typeof Link>

export const AnchorLink = ({ children, ...props }: Props) => (
  <StyledLink {...props}>{children}</StyledLink>
)
