import React from 'react'
import styled from 'styled-components'

const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`

const IconWrapper = styled.span`
  color: ${(props) => (props.color ? props.color : '')};
`

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: var(--spacing-xxs);
  font-weight: ${(props) => (props.bold ? 'var(--bold)' : '')};
`

const IconAndTitle = ({ icon, color, title, bold = false, className }) => (
  <IconAndTitleWrapper className={className}>
    {icon && <IconWrapper color={color}>{icon}</IconWrapper>}
    <Title bold={bold}>{title}</Title>
  </IconAndTitleWrapper>
)

export default IconAndTitle
