import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const PrimaryNavigationList = styled.ul`
  display: flex;
  align-items: center;
`

const PrimaryNavigationItem = styled.li`
  margin-left: var(--spacing-l);

  &.right-aligned {
    margin-left: auto;
  }

  &:first-child {
    margin-left: var(--spacing-xxs);
  }
`

const PrimaryNavigationLink = styled(NavLink)`
  display: block;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &,
  &:visited,
  &:active,
  &.active {
    color: var(--color-black-alpha);
  }

  &:hover,
  &:focus {
    color: var(--color-black-beta);
  }

  &.active {
    border-bottom-color: var(--color-black-alpha);

    &:hover,
    &:focus {
      border-bottom-color: var(--color-black-beta);
    }
  }
`

export interface MenuItem {
  path: string
  label: ReactElement
  testId?: string
  className?: string
}

interface Props {
  menuItems: MenuItem[]
}

const PrimaryNavigation = ({ menuItems }: Props): ReactElement => (
  <nav
    role="navigation"
    aria-label="Primary navigation"
    data-cy="primary-navigation"
  >
    <PrimaryNavigationList>
      {menuItems.map((item) => (
        <PrimaryNavigationItem key={item.path} className={item.className}>
          <PrimaryNavigationLink data-cy={item.testId} to={item.path}>
            {item.label}
          </PrimaryNavigationLink>
        </PrimaryNavigationItem>
      ))}
    </PrimaryNavigationList>
  </nav>
)

export default PrimaryNavigation
