import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'
import { Note } from '@mevia/reducers/notes/types'
import { Resource } from '@mevia/utils/JSONAPI/types'

export const fetchByPrescriptionId = createAsyncThunk<
  Note[],
  { prescriptionId: string | number }
>('notes/fetchByPrescriptionId', async ({ prescriptionId }) => {
  const response = await request.get<{ data: Resource<Note>[] }>(
    `v2/prescriptions/${prescriptionId}/notes`
  )

  const notes = response.data.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }))

  return notes
})

export const postNote = createAsyncThunk<
  Note,
  { prescriptionId: string | number; text: string }
>('notes/postNote', async ({ prescriptionId, text }) => {
  const relationships = {
    prescription: {
      data: {
        type: 'prescriptions',
        id: prescriptionId,
      },
    },
  }

  const body = {
    data: {
      type: 'notes',
      attributes: {
        text,
      },
      relationships,
    },
  }
  const response = await request.post<{ data: Resource<Note> }>(`v2/notes`, {
    body,
  })
  const { id, attributes } = response.data

  const note = {
    id,
    ...attributes,
  }

  return note
})
