import { ReportOptions } from '@mevia/reducers/reportOptions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'

export const fetchAdherenceOptions = createAsyncThunk<ReportOptions[]>(
  'reports/fetchAdherenceOptions',
  async () => {
    const json = await request.get<ReportOptions[]>(
      `v2/reports/adherence/options`
    )

    return json
  }
)

export const fetchCsvOptions = createAsyncThunk<ReportOptions[]>(
  'reports/fetchCsvOptions',
  async () => {
    const json = await request.get<ReportOptions[]>(`v2/reports/csv/options`)

    return json
  }
)
