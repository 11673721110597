import { createSlice } from '@reduxjs/toolkit'

import * as actions from '../actions/flagPolicies'

import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import { RootState } from './types'
import { Recipient } from '@mevia/types/mapiAPI'

export type FlagPolicyId = string

export interface FlagPolicy {
  createdAt: string
  updatedAt: string
  flagType: string
  message: string
  notificationType: string
  dispatchable: boolean
  id: FlagPolicyId
  prescriptionId: string
}

export interface FlagPoliciesState {
  readonly byId: Record<string, FlagPolicy>
}

const initialState: FlagPoliciesState = {
  byId: {},
}

const flagPoliciesSlice = createSlice({
  name: 'flagPolicies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.fetchByPrescription.fulfilled, (state, action) => {
      state.byId = {
        ...state.byId,
        ...keyBy(action.payload.flagPolicies, 'id'),
      }
    })
    builder.addCase(actions.fetch.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(actions.destroy.fulfilled, (state, action) => {
      const flagPolicyId = action.payload
      state.byId = omit(state.byId, flagPolicyId)
    })
    builder.addCase(actions.update.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(actions.create.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
  },
})

export default flagPoliciesSlice.reducer

export const selectByPrescriptionId = (
  prescriptionId: string,
  state: RootState
): FlagPolicy[] =>
  Object.values(state.flagPolicies.byId).filter(
    (policy) => String(policy.prescriptionId) === String(prescriptionId)
  )

export const selectRecipients = (
  flagPolicyId: FlagPolicyId,
  state: RootState
): Recipient[] => {
  if (!state.recipients.idsByFlagPolicyId[flagPolicyId]) return []
  return state.recipients.idsByFlagPolicyId[flagPolicyId]
    .map((id) => state.recipients.byId[id])
    .filter(Boolean)
}
