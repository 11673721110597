import styled from 'styled-components'

const ToggleButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  min-height: 38px;
  background: var(--color-white);
  padding: var(--spacing-xs) var(--spacing-s);
  border: 1px solid
    ${(props) =>
      props.active ? 'var(--color-black-gamma)' : 'var(--color-black-delta)'};
  border-radius: var(--border-radius-s);
  font-size: var(--font-size-s);
  color: var(--color-black-alpha);
  line-height: 1;

  &:hover,
  &:focus {
    border-color: var(--color-black-gamma);
  }
`

export default ToggleButton
