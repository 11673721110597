import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface HeadingElementProps {
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const HeadingElement = styled.h1.attrs<HeadingElementProps>((props) => ({
  as: props.headingLevel,
}))<HeadingElementProps>`
  font-family: var(--font-heading);
  font-weight: var(--bold);
  color: var(--color-black-alpha);

  &:not(:last-child) {
    margin-right: ${(props) =>
      props.headingLevel === 'h1' ? 'var(--spacing-s)' : ''};
  }
`

interface Props {
  title?: React.ReactNode
  children?: React.ReactNode
}

const Heading = ({
  title,
  children,
  headingLevel,
  ...props
}: Props & HeadingElementProps): ReactElement => (
  <HeadingElement headingLevel={headingLevel} {...props}>
    {title}
    {children}
  </HeadingElement>
)

export default Heading
