import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'

export const fetchByPrescriptionId = createAsyncThunk<string[], string>(
  'featureFlags/fetchByPrescriptionId',
  async (prescriptionId) => {
    const response = await request.get<string[]>(
      `v2/prescriptions/${prescriptionId}/feature_flags`
    )
    return response
  }
)

export const fetchGlobal = createAsyncThunk<string[], void>(
  'featureFlags/fetchGlobal',
  async () => {
    const response = await request.get<string[]>('v2/feature_flags')
    return response
  }
)
