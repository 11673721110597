import { createSlice, isPending, isRejected } from '@reduxjs/toolkit'
import { RootState } from '../types'
import * as actions from '../../actions/participant/registration'

export enum OnboardingSteps {
  Code = 'code',
  PhoneVerification = 'phoneVerification',
  Language = 'language',
  DoseTimes = 'doseTimes',
  Device = 'device',
}

export const logInSteps: string[] = [
  OnboardingSteps.Code,
  OnboardingSteps.PhoneVerification,
]

export const initialRequiredSteps: string[] = logInSteps

const implementedSteps = new Set([...logInSteps, OnboardingSteps.DoseTimes])

export interface RegistrationState {
  readonly loading: boolean
  readonly steps: string[]
  readonly completedSteps: string[]
  readonly studyCodeUserPhoneVerificationId: number | null
  readonly phoneVerificationId: number | null
  readonly studyCode: string | null
  readonly userRegistered: boolean
  readonly jwt: string | null
  readonly rememberJwt: boolean
  readonly morningDoseTime: string | null
  readonly goRegistrationCreated: boolean
}

const initialState: RegistrationState = {
  loading: false,
  steps: initialRequiredSteps,
  completedSteps: [],
  studyCodeUserPhoneVerificationId: null,
  phoneVerificationId: null,
  studyCode: null,
  userRegistered: false,
  jwt: null,
  rememberJwt: false,
  morningDoseTime: null,
  goRegistrationCreated: false,
}

const registrationSlice = createSlice({
  name: 'participant/registration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      actions.postPhoneVerification.fulfilled,
      (state, action) => {
        state.steps = [...initialRequiredSteps, ...action.payload.requiredSteps]
        state.phoneVerificationId = action.payload.phoneVerificationId
        state.studyCodeUserPhoneVerificationId = action.payload.id
        state.studyCode = action.payload.studyCode
        state.completedSteps = [...state.completedSteps, OnboardingSteps.Code]
        state.rememberJwt = action.payload.rememberJwt
        state.loading = false
      }
    )
    builder.addCase(
      actions.registerPortalParticipant.fulfilled,
      (state, action) => {
        state.completedSteps = [
          ...state.completedSteps,
          OnboardingSteps.PhoneVerification,
        ]
        state.userRegistered = action.payload.userRegistered
        state.jwt = action.payload.jwt
        state.loading = false
      }
    )
    builder.addCase(actions.postGoRegistration.fulfilled, (state) => {
      state.goRegistrationCreated = true
    })
    builder.addCase(actions.setMorningDoseTime, (state, action) => {
      state.morningDoseTime = action.payload
    })
    builder.addMatcher(
      isPending(
        actions.postPhoneVerification,
        actions.registerPortalParticipant
      ),
      (state) => {
        state.loading = true
      }
    )
    builder.addMatcher(
      isRejected(
        actions.postPhoneVerification,
        actions.registerPortalParticipant
      ),
      (state) => {
        state.loading = false
      }
    )
  },
})

export default registrationSlice.reducer

export const registrationSteps = (/*state: RootState*/): string[] =>
  // state.participantRegistration.steps.filter(step => implementedSteps.has(step))
  Array.from(implementedSteps)

export const participantRegistrationParams = (
  state: RootState
): {
  studyCode: string
  studyCodeUserPhoneVerificationId: number | string
  phoneVerificationId: string | number
} | null => {
  const { studyCodeUserPhoneVerificationId, studyCode, phoneVerificationId } =
    state.participantRegistration

  if (!studyCodeUserPhoneVerificationId || !studyCode || !phoneVerificationId)
    return null

  return {
    studyCode,
    studyCodeUserPhoneVerificationId,
    phoneVerificationId,
  }
}
