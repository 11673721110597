import styled from 'styled-components'
import { BP_XL, BP_XXL } from '../../constants/breakpoints'

const Container = styled.section`
  padding-right: var(--spacing-m);
  padding-left: var(--spacing-m);

  @media (min-width: ${BP_XL}) {
    padding-right: var(--spacing-l);
    padding-left: var(--spacing-l);
  }

  @media (min-width: ${BP_XXL}) {
    max-width: ${BP_XXL};
    margin-right: auto;
    margin-left: auto;
  }
`

export default Container
