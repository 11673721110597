import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from '../reducers/user'
import * as request from '../utils/request'
import * as Sentry from '@sentry/react'

type UserResponseShape = {
  id: string
  attributes: Omit<User, 'id'>
}
type UserResponse = {
  data: UserResponseShape
}

export const fetch = createAsyncThunk<User>(
  'user/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const json = await request.get<UserResponse>('v2/me?config=portal')
      const userId = json.data.id
      Sentry.setUser({ id: userId })
      return { id: userId, ...json.data.attributes }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
