import React from 'react'
import styled from 'styled-components'
import { BP_XL } from '../../constants/breakpoints'

interface Props extends React.ComponentPropsWithoutRef<'main'> {
  noSpacing?: boolean
}

const MainWrapperElement = styled.main<Pick<Props, 'noSpacing'>>`
  min-height: 100vh;
  padding-top: ${({ noSpacing }) => (noSpacing ? '' : 'var(--spacing-m)')};
  padding-bottom: ${({ noSpacing }) => (noSpacing ? '' : 'var(--spacing-m)')};
  background-color: var(--color-grey-delta);
  color: var(--color-black-alpha);

  @media (min-width: ${BP_XL}) {
    padding-top: ${({ noSpacing }) => (noSpacing ? '' : 'var(--spacing-l)')};
    padding-bottom: ${({ noSpacing }) => (noSpacing ? '' : 'var(--spacing-l)')};
  }
`

export const MainWrapper = ({ children, noSpacing, ...rest }: Props) => {
  return (
    <MainWrapperElement noSpacing={noSpacing} {...rest}>
      {children}
    </MainWrapperElement>
  )
}
