import {
  createSlice,
  isPending,
  isRejected,
  isFulfilled,
} from '@reduxjs/toolkit'
import * as actions from '../actions/featureFlags'
import { RootState } from './types'

export interface FeatureFlagState {
  readonly flags: string[]
  readonly error: boolean
  readonly isLoading: boolean
}

const initialState: FeatureFlagState = {
  isLoading: false,
  error: false,
  flags: [],
}

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isPending(actions.fetchGlobal, actions.fetchByPrescriptionId),
      (state) => {
        state.isLoading = true
      }
    )

    builder.addMatcher(
      isFulfilled(actions.fetchGlobal, actions.fetchByPrescriptionId),
      (state, action) => {
        state.isLoading = false
        state.error = false
        state.flags = action.payload
      }
    )

    builder.addMatcher(
      isRejected(actions.fetchGlobal, actions.fetchByPrescriptionId),
      (state) => {
        state.isLoading = false
        state.error = true
      }
    )
  },
})

export default featureFlagsSlice.reducer

export const isFeatureEnabled = (state: RootState, flag: string): boolean => {
  return state.featureFlags.flags.includes(flag)
}

export const isLoading = (state: RootState): boolean =>
  state.featureFlags.isLoading
