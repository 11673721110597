import React from 'react'
import { ToastState } from '@react-stately/toast'
import { Toastable } from '@mevia/features/toast/Toast'

export const ToastContext = React.createContext<ToastState<Toastable> | null>(
  null
)

export function useToast() {
  const toast = React.useContext(ToastContext)
  if (!toast) {
    throw new Error('ToastContext not found')
  }
  return toast
}
