import type { ProjectLocale } from '../locales'

const TOKEN_KEY = 'AUTHENTICATION_TOKEN'
const LOCALE_KEY = 'PROJECT_LOCALE_CODE'

export const saveAuthToken = (token: string, remember = false): void => {
  const store = remember ? localStorage : sessionStorage
  return store.setItem(TOKEN_KEY, token)
}

const getStore = () =>
  typeof localStorage.getItem(TOKEN_KEY) === 'string'
    ? localStorage
    : sessionStorage

export const getAuthToken = (): string | null => getStore().getItem(TOKEN_KEY)

export const saveProjectLocale = (locale: ProjectLocale): void =>
  getStore().setItem(LOCALE_KEY, JSON.stringify(locale))

export const getProjectLocale = (): ProjectLocale | null => {
  const maybeLocale = getStore().getItem(LOCALE_KEY)
  if (!maybeLocale) return null

  try {
    const parsedProjectLocale = JSON.parse(maybeLocale) as ProjectLocale
    return parsedProjectLocale
  } catch (error) {
    return null
  }
}

export const clear = (): void => {
  getStore().removeItem(TOKEN_KEY)
  getStore().removeItem(LOCALE_KEY)
}

export const isEmpty = (): boolean => !getStore().getItem(TOKEN_KEY)
