import { ScheduledDoseSchema } from '@mevia/reducers/scheduledDoseSchemas'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../utils/request'
import { NotificationPolicyResponseShape } from './notificationPolicies'
import { NotificationPolicy, NotificationPolicyId } from '@mevia/types/mapiAPI'

export interface ResponseShape<T> {
  id: ScheduledDoseSchemaId
  type: string
  attributes: Omit<T, 'id'>
  relationships?: {
    notificationPolicies?: {
      data: RelationshipLink[]
    }
  }
}

type RelationshipLink = {
  type: string
  id: string
}

export type ScheduledDoseSchemaId = string

export interface ScheduledDoseSchemasResponse {
  data: ResponseShape<ScheduledDoseSchema>[]
  included?: Omit<NotificationPolicyResponseShape, 'relationships'>[]
}

export interface ScheduledDoseSchemaResponse {
  data: ResponseShape<ScheduledDoseSchema>
}

export const fetchAll = createAsyncThunk<
  {
    scheduledDoseSchemas: ScheduledDoseSchema[]
    notificationPolicies: NotificationPolicy[]
    relationships: Record<ScheduledDoseSchemaId, NotificationPolicyId[]>
  },
  { query?: Record<string, string> }
>('scheduledDoseSchemas/fetchAll', async ({ query = {} }) => {
  const json = await request.get<ScheduledDoseSchemasResponse>(
    `v2/scheduled_dose_schemas`,
    { query }
  )

  const buildEntity = <T>({
    id,
    attributes,
  }: {
    id: string
    attributes: T
  }) => ({
    id,
    ...attributes,
  })

  const scheduledDoseSchemas = json.data.map(buildEntity)
  const notificationPolicies = json.included?.map(buildEntity) || []

  const relationships: Record<ScheduledDoseSchemaId, NotificationPolicyId[]> =
    json.data.reduce(
      (
        acc,
        { id: scheduledDoseSchemaId, relationships: schemaRelationships }
      ) => {
        if (!schemaRelationships || !schemaRelationships.notificationPolicies) {
          return acc
        }
        acc[scheduledDoseSchemaId] =
          schemaRelationships.notificationPolicies.data.map(
            ({ id: notificationPolicyId }) => notificationPolicyId
          )
        return acc
      },
      {} as Record<ScheduledDoseSchemaId, NotificationPolicyId[]>
    )

  return { scheduledDoseSchemas, notificationPolicies, relationships }
})
