import React from 'react'
import styled from 'styled-components'

const Message = styled.p`
  margin-top: var(--spacing-xs);
  margin-bottom: 0;
  color: var(--color-red-alpha);
`

interface Props extends React.ComponentPropsWithoutRef<'p'> {
  message: React.ReactNode
}

const SimpleErrorMessage = ({ message, ...props }: Props) => (
  <Message data-cy="error-message" {...props}>
    {message}
  </Message>
)

export default SimpleErrorMessage
