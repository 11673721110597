import { parse as parseQueryString } from 'query-string'
import React, { useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import {
  getAuthToken,
  saveAuthToken,
  clear as clearPersistedData,
} from './utils/persistance'
import { logOut } from './actions/authentication'
import { fetch as fetchUser } from './actions/user'
import Block from '@mevia/components/atoms/Block'
import Button from '@mevia/components/atoms/Button'
import { RedoIcon } from '@mevia/components/atoms/Icon'
import ForgotPassword from './pages/ForgotPassword'
import LogIn from './pages/LogIn'
import ResetPassword from './pages/ResetPassword'
import createStore from './createStore'
import { ReloadAppErrorBoundary } from './components/util'
import { GlobalStyles } from './components/util/GlobalStyles'
import { unwrapResult } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import 'normalize.css/normalize.css'
import '../css/main.css'
import '../css/style.scss'
import { fetchGlobal as fetchGlobalFeatureFlags } from './actions/featureFlags'
import LocalizationProvider from './LocalizationProvider'
import withRouter from '@mevia/utils/withRouter'
import { buildQueryString } from '@mevia/utils/request'
import { SuspenseWithLayout } from './SuspenseWithLayout'
import { ToastProvider } from './features/toast/ToastProvider'
import { VersionToaster } from './features/versionMonitor/VersionToaster'
import { JobStatusMonitor } from './features/jobStatusMonitor/JobStatusToaster'
const AdminApp = React.lazy(() => import('./AdminApp'))

const UNAUTHORIZED_STATUS_CODE = 401

const store = createStore(global.__INITIAL_STATE__)

const PAGES_WITHOUT_AUTH = [
  '/forgot_password',
  '/login',
  '/login/participant',
  '/login/participant/verification',
  '/reset_password',
  '/create_account',
]

const headData = {
  htmlAttributes: { lang: 'en' },
  titleTemplate: '%s | Mevia',
  defaultTitle: 'Mevia',
  base: { target: '_blank' },
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
  ],
  link: [
    ...(process.env.NODE_ENV === 'production'
      ? [
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/apple-touch-icon.png',
          },
          {
            rel: 'icon',
            sizes: '32x32',
            type: 'image/png',
            href: '/favicon-32x32.png',
          },
          {
            rel: 'icon',
            sizes: '16x16',
            type: 'image/png',
            href: '/favicon-16x16.png',
          },
        ]
      : []),
  ].filter(Boolean),
}

const CouldNotFetchUser = ({ onRetry }) => (
  <Block
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Block column alignItems="center">
      <Block margin="0 0 2rem">
        <FormattedMessage
          id="could-not-get-user-error"
          defaultMessage="Could not get the user. Please try again contact support if the problem persists."
        />
      </Block>
      <Button onClick={onRetry} icon={<RedoIcon />}>
        <FormattedMessage
          id="general-settings.retry-button-label"
          defaultMessage="Try again"
        />
      </Button>
    </Block>
  </Block>
)

const UserFetch = ({
  isUnauthorized,
  fetchUser,
  fetchingError,
  isFetching,
  user,
}) => {
  const { pathname } = useLocation()

  if (fetchingError) {
    return <CouldNotFetchUser onRetry={fetchUser} />
  }
  if (!user && isFetching) {
    return <div /> // TODO: Spinner?
  }

  if (!isFetching && !user && isUnauthorized) {
    // Redirect and save current path in query string as `p`.
    // The user can then be redirected back to this page after login.
    return (
      <Navigate
        to={{
          pathname: 'login',
          search: buildQueryString(
            location.pathname.length > 1 ? { p: pathname } : {}
          ),
        }}
        replace
      />
    )
  }

  return (
    <SuspenseWithLayout
      fallback={<FormattedMessage defaultMessage="Loading application" />}
    >
      <AdminApp />
    </SuspenseWithLayout>
  )
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      fetchingError: null,
      isUnauthorized: false,
    }
    this.fetchUser = this.fetchUser.bind(this)
    this.handleUnauthorized = this.handleUnauthorized.bind(this)
  }

  componentDidMount() {
    // Check if we got a query string auth token
    const qsAuthToken = parseQueryString(location.hash).auth
    if (qsAuthToken) {
      saveAuthToken(qsAuthToken)
    }

    // Pass on authenticated users and re-fetch user data
    if (getAuthToken()) {
      this.fetchUser()
      return
    }

    this.handleUnauthorized()
  }

  fetchUser() {
    this.setState({
      isFetching: true,
      fetchingError: null,
    })

    this.props
      .fetchUser()
      .then(unwrapResult)
      .then(
        () => this.setState({ isFetching: false, isUnauthorized: false }),
        (error) => {
          if (error.response?.status === UNAUTHORIZED_STATUS_CODE) {
            this.handleUnauthorized()
            return
          }

          this.setState({
            isFetching: false,
            isUnauthorized: false,
            fetchingError: error.message,
          })
        }
      )
  }

  handleUnauthorized() {
    const {
      router: {
        location: { pathname },
      },
    } = this.props

    if (PAGES_WITHOUT_AUTH.includes(pathname)) return

    clearPersistedData()

    this.setState({
      isFetching: false,
      isUnauthorized: true,
    })
  }

  render() {
    const { user } = this.props
    const { isFetching, fetchingError, isUnauthorized } = this.state

    return (
      <Block data-user-fetched={!!user} minHeight="100vh">
        <Helmet {...headData} />
        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/create_account" element={<ResetPassword />} />
          <Route
            path="*"
            element={
              <UserFetch
                isFetching={isFetching}
                isUnauthorized={isUnauthorized}
                fetchingError={fetchingError}
                fetchUser={this.fetchUser}
                user={user}
              />
            }
          />
        </Routes>
      </Block>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})
const mapDispatchToProps = { fetchUser, logOut }

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

const EnhancedApp = enhance(App)

const WithFeatureFlags = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchGlobalFeatureFlags())
  }, [])

  return children
}

const Root = () => {
  return (
    <Provider store={store}>
      <LocalizationProvider>
        <BrowserRouter>
          <GlobalStyles />
          <ReloadAppErrorBoundary>
            <ToastProvider>
              <VersionToaster />
              <JobStatusMonitor>
                <WithFeatureFlags>
                  <EnhancedApp />
                </WithFeatureFlags>
              </JobStatusMonitor>
            </ToastProvider>
          </ReloadAppErrorBoundary>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  )
}

export default Sentry.withProfiler(Root)
