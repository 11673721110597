import * as React from 'react'
import * as Sentry from '@sentry/react'
// @ts-expect-error Implicit any type (need to define declaration files or convert to TS files)
import Block from '@mevia/components/atoms/Block'
// @ts-expect-error Implicit any type (need to define declaration files or convert to TS files)
import Button from '@mevia/components/atoms/Button'
// @ts-expect-error Implicit any type (need to define declaration files or convert to TS files)
import Modal from '@mevia/components/organisms/Modal'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import styled from 'styled-components'

const ButtonRow = styled.div`
  display: flex;
  margin-top: var(--spacing-s);
  justify-content: flex-end;
  align-items: center;
`

export const ReloadAppErrorModal = ({
  error,
}: {
  error: unknown
}): React.ReactElement => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV !== 'production') console.error(error)

  const [isOpen, setIsOpen] = useState(true)

  const reloadApp = (): void => location.replace('/')

  return (
    <Modal
      isOpen={isOpen}
      testId="error-boundary"
      onRequestClose={(): void => setIsOpen(false)}
      contentLabel="Error dialog"
      style={{ padding: 0 }}
      title={
        <FormattedMessage
          id="error-boundaries.refresh-modal-header"
          defaultMessage="An error has occured"
        />
      }
    >
      <Block>
        <FormattedMessage
          id="error-boundaries.refresh-modal-message"
          defaultMessage="Please try to reload the application."
        />
      </Block>
      <ButtonRow>
        <Button onClick={reloadApp}>
          <FormattedMessage
            id="error-boundaries.refresh-modal-button"
            defaultMessage="Reload application"
          />
        </Button>
      </ButtonRow>
    </Modal>
  )
}

interface Props {
  children: React.ReactNode
}

const ReloadAppErrorBoundary = ({ children }: Props): React.ReactElement => (
  <Sentry.ErrorBoundary
    fallback={({ error }) => <ReloadAppErrorModal error={error} />}
  >
    {children}
  </Sentry.ErrorBoundary>
)

export default ReloadAppErrorBoundary
