import styled from 'styled-components'

const InputLabel = styled.label`
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: var(--bold);
  color: var(--color-black-alpha);
`

export default InputLabel
