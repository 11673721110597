import { browserTimeZone } from '@mevia/reducers/localization'
import { useSelector } from 'react-redux'
import { RootState } from '@mevia/reducers/types'

interface TimeZone {
  browserTimeZone: string
  subjectTimeZone: string | null
  preferredTimeZone: string
}

function useTimeZone(): TimeZone {
  const preferredTimeZoneType = useSelector(
    (state: RootState) => state.localization.preferredTimeZoneType
  )
  const participantTimeZone = useSelector(
    (state: RootState) => state.localization.participantTimeZone
  )

  return {
    browserTimeZone,
    subjectTimeZone: participantTimeZone,
    preferredTimeZone:
      preferredTimeZoneType === 'participant' && participantTimeZone
        ? participantTimeZone
        : browserTimeZone,
  }
}

export default useTimeZone
