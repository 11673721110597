import React from 'react'
// @ts-expect-error Not a TS file
import Spinner from '@mevia/components/atoms/Spinner'
import type { AriaToastProps } from '@react-aria/toast'
import type { ToastState } from '@react-stately/toast'
import { useToast } from '@react-aria/toast'
import styled, { css } from 'styled-components'
import {
  CheckIcon as BaseCheckIcon,
  CloseIcon as BaseCloseIcon,
  ExlamationMarkIcon,
  InfoIcon as BaseInfoIcon,
} from '@mevia/components/atoms/Icon'
// @ts-expect-error Not a TS fle
import Button from '@mevia/components/atoms/Button'
import { BoxShadow } from '@mevia/components/atoms/BoxShadow'
import { useButton } from '@react-aria/button'

const SpinnerWrapper = styled.div`
  margin-top: calc(var(--spacing-xxs) - 1px);
  margin-right: var(--spacing-xxs);
  align-self: flex-start;
  display: inline-block;
  vertical-align: middle;
`

const InfoIcon = styled(BaseInfoIcon)`
  margin-top: calc(var(--spacing-xxs) - 1px);
  margin-right: var(--spacing-xxs);
  align-self: flex-start;
`

const CheckIcon = styled(BaseCheckIcon)`
  margin-top: calc(var(--spacing-xxs) - 1px);
  margin-right: var(--spacing-xxs);
  align-self: flex-start;
`

const CloseIcon = styled(BaseCloseIcon)`
  margin: auto;
`

const CloseButtonWrapper = styled.button`
  width: var(--spacing-s);
  height: var(--spacing-s);
  color: var(--color-black-alpha);
  margin-left: auto;

  &:focus {
    outline: 1px solid var(--color-black-alpha);
  }
`

type CloseButtonProps = Parameters<typeof useButton>[0]

export const CloseButton = (props: CloseButtonProps) => {
  const ref = React.useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <CloseButtonWrapper {...buttonProps} ref={ref}>
      <CloseIcon size="tiny" noSpacing />
    </CloseButtonWrapper>
  )
}

export interface Toastable {
  type: 'info' | 'error' | 'success' | 'pending'
  title: string
  description: string
  action?: {
    label: string
    onClick: () => void
  }
}

const ToastWrapper = styled.div<{ $type: Toastable['type'] }>`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: var(--spacing-xs);
  background-color: #fff;
  gap: var(--spacing-s);
  align-items: center;

  ${({ $type }) => {
    switch ($type) {
      case 'info':
      case 'pending':
        return css`
          border-left: var(--spacing-xxs) solid var(--color-light-blue-alpha);
        `
      case 'success':
        return css`
          border-left: var(--spacing-xxs) solid var(--color-green-alpha);
        `
      case 'error':
        return css`
          border-left: var(--spacing-xxs) solid var(--color-red-alpha);
        `
    }
  }}
`

const ToastTitle = styled.div`
  font-family: var(--font-heading);
  font-weight: var(--bold);
  color: var(--color-black-alpha);
`

const ToastBody = styled.div`
  font-family: var(--font-body);
  color: var(--color-black-alpha);
`

const ToastContent = styled.div``

interface ToastProps extends AriaToastProps<Toastable> {
  state: ToastState<Toastable>
}

export function Toast(props: ToastProps) {
  const ref = React.useRef(null)
  const { toastProps, titleProps, closeButtonProps } = useToast(
    props,
    props.state,
    ref
  )
  const toastType = props.toast.content.type

  const isInfo = toastType === 'info'
  const isPending = toastType === 'pending'
  const isSuccess = toastType === 'success'
  const isError = toastType === 'error'

  const action = props.toast.content.action

  return (
    <BoxShadow>
      <ToastWrapper
        data-testid="toast"
        $type={toastType}
        {...toastProps}
        ref={ref}
      >
        {isInfo && <InfoIcon noSpacing color="var(--color-blue-alpha)" />}
        {isPending && (
          <SpinnerWrapper>
            <Spinner
              size="var(--spacing-s)"
              color="var(--color-black-alpha)"
              background="rgba(35, 31, 32, 0.25)"
            />
          </SpinnerWrapper>
        )}
        {isSuccess && <CheckIcon noSpacing color="var(--color-green-alpha)" />}
        {isError && (
          <ExlamationMarkIcon noSpacing color="var(--color-red-alpha)" />
        )}
        <ToastContent>
          <ToastTitle {...titleProps}>{props.toast.content.title}</ToastTitle>
          <ToastBody>{props.toast.content.description}</ToastBody>
        </ToastContent>
        {action && (
          <Button variant="defaultVariant" onClick={action.onClick}>
            {action.label}
          </Button>
        )}
        <CloseButton {...closeButtonProps} />
      </ToastWrapper>
    </BoxShadow>
  )
}
