import React, { useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import Input from '@mevia/components/atoms/Input'
import SimpleErrorMessage from '@mevia/components/atoms/SimpleErrorMessage'
import AuthContainer from '@mevia/components/organisms/AuthContainer'
import { postPasswordReset } from '../actions/authentication'
import { compose } from 'redux'
import { connect } from 'react-redux'
import validate from '../utils/validateEmail'

const placeholderMessages = defineMessages({
  email: {
    id: 'forgot-password-email-input-placeholder',
    defaultMessage: 'Enter email',
  },
})

const errorMessages = defineMessages({
  invalidEmail: {
    id: 'forgot-password-error-invalid-email',
    defaultMessage: 'Invalid email, please try again',
  },
  generic: {
    id: 'forgot-password-error-generic',
    defaultMessage:
      'Something went wrong when trying to send email. Please try again later or contact support.',
  },
})

const Bold = styled.span`
  font-weight: var(--bold);
`

const ForgotPassword = ({
  intl,
  isLoading,
  sent,
  fetchError,
  postPasswordReset,
}) => {
  const [email, setEmail] = useState('')
  const [sentEmail, setSentEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [error, setError] = useState(null)

  const onSubmit = (e) => {
    e.preventDefault()
    const validEmail = validate(email)
    setValidEmail(validEmail)
    if (validEmail) {
      postPasswordReset({ email })
    }
  }

  useEffect(() => {
    if (!validEmail) {
      setError(intl.formatMessage(errorMessages.invalidEmail))
    } else {
      setError(null)
    }
  }, [validEmail])

  useEffect(() => {
    if (!validEmail) {
      setValidEmail(validate(email))
    }
  }, [email])

  useEffect(() => {
    if (sent) {
      setSentEmail(email)
      setEmail('')
    }
  }, [sent])

  const { bannerStatus, bannerTitle } = (() => {
    if (fetchError)
      return {
        bannerStatus: 'error',
        bannerTitle: <span>{intl.formatMessage(errorMessages.generic)}</span>,
      }

    if (sent)
      return {
        bannerStatus: 'success',
        bannerTitle: (
          <FormattedMessage
            id="forgot-password-sent"
            defaultMessage="Email with instructions on how to reset you password sent to {email}"
            values={{ email: sentEmail }}
          />
        ),
      }

    return {}
  })()

  return (
    <AuthContainer
      bannerStatus={bannerStatus}
      bannerTitle={bannerTitle}
      title={
        <FormattedMessage
          id="forgot-password-header"
          defaultMessage="Forgot your password?"
        />
      }
      subtitle={
        <FormattedMessage
          id="forgot-password-instructions"
          defaultMessage="Enter the email associated with your account to receive reset instructions."
        />
      }
      errorContent={
        error && <SimpleErrorMessage message={error} status="error" />
      }
      disabled={isLoading || email.length === 0}
      onSubmit={onSubmit}
      buttonContent={
        isLoading ? (
          <FormattedMessage
            id="forgot-password-pending-request"
            defaultMessage="Sending..."
          />
        ) : (
          <FormattedMessage
            id="forgot-password-submit-button"
            defaultMessage="Send instructions"
          />
        )
      }
      linkContent={
        <>
          <FormattedMessage
            id="forgot-password-remember"
            defaultMessage="Did you remember? "
          />
          <Bold>
            <FormattedMessage
              id="forgot-password-login"
              defaultMessage="Login"
            />
          </Bold>
        </>
      }
      linkTo="/login"
    >
      <Input
        type="text"
        placeholder={intl.formatMessage(placeholderMessages.email)}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        invalidInput={!validEmail}
        data-cy="enter-email"
      />
    </AuthContainer>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
  fetchError: state.user.resetPasswordError,
  sent: state.user.resetPasswordSent,
})

const mapDispatchToProps = {
  postPasswordReset,
}

const enhance = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ForgotPassword)
