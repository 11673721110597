import React from 'react'
import styled from 'styled-components'

const VisuallyHiddenText = styled.span.attrs({
  'aria-hidden': true,
})`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
`

interface Props {
  children: React.ReactNode
}

const VisuallyHidden = ({ children }: Props) => (
  <VisuallyHiddenText>{children}</VisuallyHiddenText>
)

export default VisuallyHidden
