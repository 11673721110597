import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyFillIntlLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillDateTimeFormatLocale } from '@formatjs/intl-datetimeformat/should-polyfill'

export type PolyfillState = 'idle' | 'loading' | 'loaded' | 'error'

export async function polyfillIntlLocale() {
  if (shouldPolyFillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill')
  }
}

async function polyfillPluralRules(locale: string) {
  const unsupportedLocale = shouldPolyfillPluralRules(locale)
  // This locale is supported
  if (!unsupportedLocale) {
    return
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-pluralrules/polyfill-force')
  await import(`@formatjs/intl-pluralrules/locale-data/${unsupportedLocale}`)
}

async function loadIntlAPIPolyfills(locale: string) {
  // Make sure that Intl.Locale polyfill is loaded prior to loading polyfill for plural rules
  await polyfillIntlLocale()
  await polyfillPluralRules(locale)
}

export default loadIntlAPIPolyfills

export async function polyfillDateTimeFormatAPI(locale: string) {
  const unsupportedLocale = shouldPolyfillDateTimeFormatLocale(locale)
  // This locale is supported
  if (!unsupportedLocale) {
    return
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-datetimeformat/polyfill-force')

  // Parallelize CLDR data loading
  const dataPolyfills = [
    import('@formatjs/intl-datetimeformat/add-all-tz'),
    import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`),
  ]
  await Promise.all(dataPolyfills)
}
