import { original } from '@reduxjs/toolkit'
import qs from 'query-string'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ScheduledDoseSchema } from './reducers/scheduledDoseSchemas'
import { API_ROOT, buildHeader, buildQueryString } from './utils/request'
import { camelizeKeys, decamelizeKeys } from 'humps'
import {
  CamelCasedPropertiesDeep,
  Simplify,
  SnakeCasedPropertiesDeep,
} from 'type-fest'
import { ScheduledDose } from './reducers/scheduledDoses'
import { MAX_PRESCRIPTION_PAGE_COUNT } from './actions/prescriptions'
import {
  Adherence,
  DoseEvent,
  DoseEvents,
  Flag,
  FlagType,
  Invitation,
  InvitationStatuses,
  ManagedUser,
  MiaModule,
  NotificationPolicy,
  Package,
  PatientConfig,
  Permission,
  Prescription,
  PrescriptionSummary,
  Project,
  ReminderDispatch,
  Report,
  TakenPod,
  UpdateManagedUserRolesParams,
  UserRole,
} from './types/mapiAPI'
import { MAPIProjectWithDescendantsResponse } from './features/organizations/OrganizationTree'

type Recipient = { name?: string } & ({ email: string } | { phone: string })

export type JobStatus = {
  status: 'queued' | 'working' | 'completed' | 'failed'
}

export type RegistrationNotificationPolicy = Pick<
  NotificationPolicy,
  'message' | 'offset' | 'trigger' | 'notificationType'
>

const baseQuery = fetchBaseQuery({
  baseUrl: API_ROOT,
  prepareHeaders: buildHeader,
})

type JSONAPIResource<ResourceType extends { id: string | number }> = {
  id: ResourceType['id']
  attributes: SnakeCasedPropertiesDeep<Omit<ResourceType, 'id'>>
}
type CamelizedJSONAPIResource<ResourceType extends { id: string | number }> = {
  id: ResourceType['id']
  attributes: CamelCasedPropertiesDeep<Omit<ResourceType, 'id'>>
}

function transformJSONAPIResources<
  ResourceType extends { id: string | number }
>(data: JSONAPIResource<ResourceType>[]): ResourceType[] {
  const camelizedData = camelizeKeys(
    data
  ) as CamelizedJSONAPIResource<ResourceType>[]

  return camelizedData.map(
    ({ id, attributes }) =>
      ({
        id,
        ...attributes,
      } as unknown as ResourceType)
  )
}

function transformJSONAPIResourcesData<
  ResourceType extends { id: string | number }
>(response: { data: JSONAPIResource<ResourceType>[] }): ResourceType[] {
  return transformJSONAPIResources(response.data)
}

function transformJSONAPIResource<
  ResourceType extends { id: string | number }
>(response: { data: JSONAPIResource<ResourceType> }): ResourceType {
  const camelizedData = camelizeKeys(
    response.data
  ) as unknown as CamelizedJSONAPIResource<ResourceType>

  const { id, attributes } = camelizedData

  const resource = { id, ...attributes } as unknown as ResourceType

  return resource
}

type Filter = Record<string, string | string[] | number>

function filter(filterParams: Partial<Filter>): Filter {
  return Object.entries(filterParams).reduce((acc, [key, value]) => {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      Array.isArray(value)
    ) {
      acc[key] = value
    }
    return acc
  }, {} as Filter)
}

interface JSONAPIQuery {
  pageLimit?: number
  sort?: string
  filter?: Filter
}
// Some weird thing going on where optional keys aren't assignable to
// Partial<Record<string, string>
type SimpleJSONAPIQuery = Simplify<Pick<JSONAPIQuery, 'sort'>>

export function buildJSONAPIQuery<Query extends JSONAPIQuery>(
  query: Query
): string {
  const { filter, pageLimit, ...baseParams } = query
  const queryParams: Partial<Record<string, string | number>> =
    baseParams as SimpleJSONAPIQuery

  if (pageLimit) {
    queryParams['page[limit]'] = String(pageLimit)
  }

  if (filter) {
    Object.entries(filter).forEach(([filterKey, filterVal]) => {
      queryParams[`filter[${filterKey}]`] = Array.isArray(filterVal)
        ? filterVal.join(',')
        : filterVal
    })
  }

  return buildQueryString(queryParams)
}

const prepareBody = (body: object): string =>
  JSON.stringify(decamelizeKeys(body))

export const mapiApi = createApi({
  reducerPath: 'mapiApi',
  baseQuery,
  tagTypes: [
    'AdherenceScore',
    'ScheduledDoses',
    'ScheduledDoseSchemas',
    'TakenPodsByScheduledDose',
    'Packages',
    'Prescriptions',
    'UserRoles',
    'ManagedUsers',
    'MiaModules',
    'Reports',
  ],
  endpoints: (builder) => ({
    postDoseEventsBatch: builder.mutation<
      void,
      { scheduledDoseId: string | number; doseEvents: DoseEvents[] }
    >({
      query: ({ scheduledDoseId, doseEvents }) => {
        const body = prepareBody({ doseEvents })
        return {
          url: `v2/scheduled_doses/${scheduledDoseId}/dose_events/batch`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (_result, error, { scheduledDoseId }) =>
        !error
          ? [
              { type: 'ScheduledDoses', id: scheduledDoseId },
              { type: 'TakenPodsByScheduledDose', id: scheduledDoseId },
              { type: 'Packages', id: 'LIST' },
              { type: 'AdherenceScore', id: 'LIST' },
            ]
          : [],
    }),
    createScheduledDoseSchemaByPrescription: builder.mutation<
      ScheduledDoseSchema,
      {
        prescriptionId: string | number
        attributes: Omit<ScheduledDoseSchema, 'id'>
      }
    >({
      query: ({ prescriptionId, attributes }) => {
        const body = prepareBody({
          data: {
            type: 'scheduled_dose_schemas',
            attributes,
            relationships: {
              prescription: {
                data: { type: 'prescriptions', id: prescriptionId },
              },
            },
          },
        })

        return {
          url: `v2/scheduled_dose_schemas/`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (_result, error) =>
        !error
          ? [
              { type: 'ScheduledDoses', id: 'LIST' },
              { type: 'AdherenceScore', id: 'LIST' },
            ]
          : [],
      transformResponse: (response: {
        data: JSONAPIResource<ScheduledDoseSchema>
      }) => {
        return transformJSONAPIResource(
          response
        ) as unknown as ScheduledDoseSchema
      },
    }),
    updateScheduledDose: builder.mutation<
      void,
      { scheduledDoseId: string | number; attributes: unknown }
    >({
      query: ({ scheduledDoseId, attributes }) => {
        const body = prepareBody({
          data: {
            type: 'scheduled_doses',
            id: scheduledDoseId,
            attributes,
          },
        })
        return {
          url: `v2/scheduled_doses/${scheduledDoseId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (_result, error, { scheduledDoseId }) =>
        !error ? [{ type: 'ScheduledDoses', id: scheduledDoseId }] : [],
    }),
    updateScheduledDoseSchema: builder.mutation<
      void,
      { scheduledDoseSchemaId: string | number; attributes: unknown }
    >({
      query: ({ scheduledDoseSchemaId, attributes }) => {
        const body = prepareBody({
          data: {
            type: 'scheduled_dose_schemas',
            id: scheduledDoseSchemaId,
            attributes,
          },
        })
        return {
          url: `v2/scheduled_dose_schemas/${scheduledDoseSchemaId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (_result, error, { scheduledDoseSchemaId }) =>
        !error
          ? [
              { type: 'ScheduledDoseSchemas', id: scheduledDoseSchemaId },
              { type: 'ScheduledDoses', id: 'LIST' },
            ]
          : [],
    }),
    destroyScheduledDose: builder.mutation<
      void,
      { scheduledDoseId: string | number }
    >({
      query: ({ scheduledDoseId }) => {
        return {
          url: `v2/scheduled_doses/${scheduledDoseId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (_result, error, { scheduledDoseId }) =>
        !error
          ? [
              { type: 'ScheduledDoses', id: scheduledDoseId },
              { type: 'AdherenceScore', id: 'LIST' },
            ]
          : [],
    }),
    destroyScheduledDoseSchema: builder.mutation<
      void,
      { scheduledDoseSchemaId: string | number }
    >({
      query: ({ scheduledDoseSchemaId }) => {
        return {
          url: `v2/scheduled_dose_schemas/${scheduledDoseSchemaId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (_result, error, { scheduledDoseSchemaId }) =>
        !error
          ? [
              { type: 'ScheduledDoseSchemas', id: scheduledDoseSchemaId },
              { type: 'ScheduledDoses', id: 'LIST' },
              { type: 'AdherenceScore', id: 'LIST' },
            ]
          : [],
    }),
    getReports: builder.query<
      {
        data: Report[]
        meta: { record_count: number }
        links: { prev?: string; next?: string }
      },
      { search?: string; pageNumber: number; pageSize: number; sort?: string }
    >({
      query: ({ search, pageNumber, pageSize, sort }) => {
        const queryString = qs.stringify(
          {
            'filter[search]': search || '',
            'page[number]': pageNumber,
            'page[size]': pageSize,
            sort: sort || '', // Add sort to the query string
          },
          { skipEmptyString: true }
        )

        return `/v2/reports?${queryString}`
      },
      providesTags: (_result, error) =>
        !error ? [{ type: 'Reports', id: 'LIST' }] : [],
    }),
    getReportLink: builder.query<
      {
        url: string
      },
      { reportId: string | number }
    >({
      query: ({ reportId }) => {
        return `/v2/reports/${reportId}/link`
      },
    }),
    getScheduledDose: builder.query<
      {
        scheduledDose: ScheduledDose
        takenPods: TakenPod[]
      },
      {
        scheduledDoseId: string | number
      }
    >({
      query: ({ scheduledDoseId }) =>
        `v2/scheduled_doses/${scheduledDoseId}?include=taken_pods`,
      transformResponse: (response: {
        data: JSONAPIResource<TakenPod>
        included?: JSONAPIResource<TakenPod>[]
      }) => {
        return {
          scheduledDose: transformJSONAPIResource(
            response
          ) as unknown as ScheduledDose,
          takenPods: response.included
            ? transformJSONAPIResources(response.included)
            : [],
        }
      },
      providesTags: (_result, _error, { scheduledDoseId }) => [
        { type: 'ScheduledDoses', id: scheduledDoseId },
      ],
    }),
    getScheduledDoseSchema: builder.query<
      ScheduledDoseSchema,
      {
        scheduledDoseSchemaId: string | number
      }
    >({
      query: ({ scheduledDoseSchemaId }) =>
        `v2/scheduled_dose_schemas/${scheduledDoseSchemaId}`,
      transformResponse: (response: {
        data: JSONAPIResource<ScheduledDoseSchema>
      }) => {
        return transformJSONAPIResource(
          response
        ) as unknown as ScheduledDoseSchema
      },
      providesTags: (_result, _error, { scheduledDoseSchemaId }) => [
        { type: 'ScheduledDoseSchemas', id: scheduledDoseSchemaId },
      ],
    }),
    getPrescriptionSummaries: builder.query<
      {
        prescriptions: PrescriptionSummary[]
        totalCount: number
      },
      {
        from?: string
        to?: string
      }
    >({
      query: (params) => {
        const queryString = buildJSONAPIQuery({
          pageLimit: MAX_PRESCRIPTION_PAGE_COUNT,
          from: params?.from,
          to: params?.to,
        })
        return `v2/prescription_summaries${queryString}`
      },
      keepUnusedDataFor: 180, // Three minutes
      transformResponse: (response: {
        data: JSONAPIResource<PrescriptionSummary>[]
        meta: { record_count: number }
      }) => {
        return {
          prescriptions: transformJSONAPIResourcesData(response),
          totalCount: response.meta.record_count,
        }
      },
      providesTags: (result) => {
        const prescriptions = result?.prescriptions || []
        const prescriptionTags = prescriptions.map(({ id }) => ({
          type: 'Prescriptions',
          id,
        }))
        return [{ type: 'Prescriptions', id: 'LIST' }, ...prescriptionTags] as {
          type: 'Prescriptions'
          id: number | string
        }[]
      },
    }),
    getPrescriptionConfig: builder.query<
      PatientConfig,
      { prescriptionId: string | number }
    >({
      query: ({ prescriptionId }) =>
        `v2/prescriptions/${prescriptionId}/config`,
      transformResponse: (response: { data: PatientConfig }) =>
        camelizeKeys(response.data) as PatientConfig,
    }),
    getInvitations: builder.query<
      {
        invitations: Invitation[]
      },
      {
        limit?: number
        userId?: number | string
        status?: InvitationStatuses
      }
    >({
      query: (params) => {
        const { limit, userId, status } = params
        const query = { filter: filter({ userId, status }), pageLimit: limit }

        return `v2/invitations${buildJSONAPIQuery(query)}`
      },
      transformResponse: (response: {
        data: JSONAPIResource<Invitation>[]
        meta: { record_count: number }
      }) => {
        return {
          invitations: transformJSONAPIResourcesData(response),
        }
      },
    }),
    postAcceptInvitation: builder.mutation<
      void,
      { invitationId: Invitation['id'] }
    >({
      query: ({ invitationId }) => {
        return { url: `v2/invitations/${invitationId}/accept`, method: 'POST' }
      },
    }),
    postCreatePrescription: builder.mutation<
      Prescription,
      {
        attributes: Partial<Prescription> &
          Partial<{
            miaModules: { name: string }[]
            recipients: Recipient[]
            notificationPolicies: RegistrationNotificationPolicy[]
            patientCommunicationTemplates: (string | number)[]
          }>
      }
    >({
      query: ({ attributes }) => {
        const body = prepareBody({
          data: {
            type: 'registrations',
            attributes,
          },
        })
        return {
          url: `v2/registrations`,
          method: 'POST',
          body,
        }
      },
      transformResponse: (response: {
        data: JSONAPIResource<Prescription>
      }) => {
        return transformJSONAPIResource(response) as unknown as Prescription
      },
      invalidatesTags: (_result, error) =>
        !error
          ? [
              { type: 'Prescriptions', id: 'LIST' },
              { type: 'MiaModules', id: 'LIST' },
            ]
          : [],
    }),
    updatePrescription: builder.mutation<
      Prescription,
      { prescriptionId: string | number; attributes: Partial<Prescription> }
    >({
      query: ({ prescriptionId, attributes }) => {
        const body = prepareBody({
          data: {
            type: 'prescriptions',
            id: prescriptionId,
            attributes,
          },
        })
        return {
          url: `v2/prescriptions/${prescriptionId}`,
          method: 'PUT',
          body,
        }
      },
      async onQueryStarted({ prescriptionId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPrescription } = await queryFulfilled
          dispatch(
            mapiApi.util.updateQueryData(
              'getPrescription',
              { prescriptionId },
              (draft) => {
                Object.assign(draft, { prescription: updatedPrescription })
              }
            )
          )
        } catch {
          // Continue regardless of error
        }
      },
      transformResponse: (response: {
        data: JSONAPIResource<Prescription>
      }) => {
        return transformJSONAPIResource(response) as unknown as Prescription
      },
      invalidatesTags: (_result, error, { prescriptionId }) =>
        !error
          ? [
              { type: 'Prescriptions', id: 'LIST' },
              { type: 'Prescriptions', id: prescriptionId },
            ]
          : [],
    }),
    createExposureReport: builder.mutation<
      void,
      {
        prescriptionId: string | number
      }
    >({
      query: ({ prescriptionId }) => {
        return {
          url: `v2/reports/exposure/generate?prescription=${prescriptionId}`,
          method: 'GET',
        }
      },
      invalidatesTags: (_result, error) =>
        !error ? [{ type: 'Reports', id: 'LIST' }] : [],
    }),
    createAdherenceReport: builder.mutation<
      void,
      {
        start: string
        end: string
        projectId: string | number
      }
    >({
      query: ({ start, end, projectId }) => {
        return {
          url: `v2/reports/adherence/generate?from=${start}&to=${end}&project_id=${projectId}`,
          method: 'GET',
        }
      },
      invalidatesTags: (_result, error) =>
        !error ? [{ type: 'Reports', id: 'LIST' }] : [],
    }),
    createCsvReport: builder.mutation<
      void,
      {
        start: string
        end: string
        projectId: string | number
      }
    >({
      query: ({ start, end, projectId }) => {
        return {
          url: `v2/reports/csv/generate?from=${start}&to=${end}&project_id=${projectId}`,
          method: 'GET',
        }
      },
      invalidatesTags: (_result, error) =>
        !error ? [{ type: 'Reports', id: 'LIST' }] : [],
    }),
    getPrescription: builder.query<
      {
        prescription: Prescription
        miaModules: MiaModule[]
      },
      {
        prescriptionId: string | number
      }
    >({
      query: ({ prescriptionId }) =>
        `v2/prescriptions/${prescriptionId}?include=mia_modules`,
      transformResponse: (response: {
        data: JSONAPIResource<Prescription>
        included?: JSONAPIResource<MiaModule>[]
      }) => {
        return {
          prescription: transformJSONAPIResource(
            response
          ) as unknown as Prescription,
          miaModules: response.included
            ? transformJSONAPIResources(response.included)
            : [],
        }
      },
      providesTags: (_result, error, { prescriptionId }) =>
        !error ? [{ type: 'Prescriptions', id: prescriptionId }] : [],
    }),
    getAvailableMiaModules: builder.query<
      {
        miaModules: MiaModule[]
      },
      void
    >({
      query: () => {
        const query = { pageLimit: 500, filter: { available: 'true' } }
        const queryString = buildJSONAPIQuery(query)
        return `v2/mia_modules${queryString}`
      },
      transformResponse: (response: { data: JSONAPIResource<MiaModule>[] }) => {
        return {
          miaModules: transformJSONAPIResourcesData(response),
        }
      },
      providesTags: (_result, error) =>
        !error ? [{ type: 'MiaModules', id: 'LIST' }] : [],
    }),
    disconnectMiaModuleFromPrescription: builder.mutation<
      void,
      {
        prescriptionId: string | number
        miaModuleId: string | number
      }
    >({
      query: ({ prescriptionId, miaModuleId }) => {
        const body = prepareBody({
          data: [
            {
              type: 'mia_modules',
              id: miaModuleId,
            },
          ],
        })

        return {
          url: `v2/prescriptions/${prescriptionId}/relationships/mia_modules`,
          method: 'DELETE',
          body,
        }
      },
      invalidatesTags: (_result, error, { prescriptionId }) =>
        !error
          ? [
              { type: 'Prescriptions', id: prescriptionId },
              { type: 'MiaModules', id: 'LIST' },
            ]
          : [],
    }),
    getAdherenceByPrescription: builder.query<
      Adherence,
      {
        prescriptionId: string | number
        interval: { from?: string; to?: string }
      }
    >({
      query: ({ prescriptionId, interval }) =>
        `v2/prescriptions/${prescriptionId}/adherence_score${buildQueryString(
          interval
        )}`,
      providesTags: () => [{ type: 'AdherenceScore', id: 'LIST' }],
    }),
    destroyPrescription: builder.mutation<
      { jobId: string | number },
      { prescriptionId: number | string }
    >({
      query: ({ prescriptionId }) => {
        return {
          url: `v2/prescriptions/${prescriptionId}/enqueue`,
          method: 'DELETE',
        }
      },
      transformResponse: (response: { job_id: string | number }) => {
        return {
          jobId: response.job_id,
        }
      },
    }),
    getJobStatus: builder.query<JobStatus, string | number>({
      query: (jobId) => {
        return `v2/job_status/${jobId}`
      },
    }),
    getUnmatchedTakenPodsByPrescription: builder.query<
      { takenPods: TakenPod[]; eTag?: string | null },
      {
        prescriptionId: string | number
        from: string
        to: string
      }
    >({
      query: ({ prescriptionId, from, to }) => {
        const queryString = buildQueryString({
          'filter[with_matching_dose]': 'false',
          'filter[taken_at_after]': from,
          'filter[taken_at_before]': to,
          'page[limit]': String(365 * 3),
        })
        return `v2/prescriptions/${prescriptionId}/taken_pods${queryString}`
      },
      transformResponse: (
        response: {
          data: JSONAPIResource<TakenPod>[]
        },
        meta?: { response?: Response }
      ) => {
        const eTag = meta?.response?.headers.get('ETag')
        return {
          takenPods: transformJSONAPIResourcesData(response),
          eTag,
        }
      },
    }),
    getTakenPod: builder.query<
      { takenPod: TakenPod },
      { takenPodId: string | number }
    >({
      query: ({ takenPodId }) => `v2/taken_pods/${takenPodId}`,
      transformResponse: (
        response: {
          data: JSONAPIResource<TakenPod>
        },
        meta?: { response?: Response }
      ) => {
        const eTag = meta?.response?.headers.get('ETag')
        return {
          takenPod: transformJSONAPIResource(response) as TakenPod,
          eTag,
        }
      },
    }),
    getTakenPodsByScheduledDose: builder.query<
      { takenPods: TakenPod[]; eTag?: string | null },
      {
        scheduledDoseId: string | number
      }
    >({
      query: ({ scheduledDoseId }) => {
        const queryString = buildQueryString({
          'page[limit]': String(365 * 3),
        })
        return `v2/scheduled_doses/${scheduledDoseId}/taken_pods${queryString}`
      },
      transformResponse: (
        response: {
          data: JSONAPIResource<TakenPod>[]
        },
        meta?: { response?: Response }
      ) => {
        const eTag = meta?.response?.headers.get('ETag')
        return {
          takenPods: transformJSONAPIResourcesData(response),
          eTag,
        }
      },
      providesTags: (_result, _error, { scheduledDoseId }) => [
        { type: 'TakenPodsByScheduledDose', id: scheduledDoseId },
      ],
    }),
    getScheduledDosesByPrescription: builder.query<
      {
        scheduledDoses: ScheduledDose[]
        takenPods: TakenPod[]
        eTag?: string | null
      },
      {
        prescriptionId: string | number
        from: string
        to: string
      }
    >({
      query: ({ prescriptionId, from, to }) => {
        const queryString = buildQueryString({
          'filter[date_from]': from,
          'filter[date_to]': to,
          'page[limit]': String(365 * 3),
          include: 'taken_pods',
        })
        return `v2/prescriptions/${prescriptionId}/scheduled_doses${queryString}`
      },
      transformResponse: (
        response: {
          data: JSONAPIResource<ScheduledDose>[]
          included?: JSONAPIResource<TakenPod>[]
        },
        meta?: { response?: Response }
      ) => {
        const eTag = meta?.response?.headers.get('ETag')
        return {
          scheduledDoses: transformJSONAPIResourcesData(response),
          takenPods: response.included
            ? transformJSONAPIResources(response.included)
            : [],
          eTag,
        }
      },
      providesTags: (result) => {
        const scheduledDoses = result?.scheduledDoses || []
        const scheduledDoseTags = scheduledDoses.map(({ id }) => ({
          type: 'ScheduledDoses',
          id,
        }))
        return [
          { type: 'ScheduledDoses', id: 'LIST' },
          ...scheduledDoseTags,
        ] as { type: 'ScheduledDoses'; id: number | string }[]
      },
    }),
    getPackagesByPrescription: builder.query<
      Package[],
      {
        prescriptionId: string | number
      }
    >({
      query: ({ prescriptionId }) => {
        const queryString = buildQueryString({
          'page[limit]': String(365 * 3),
        })
        return `v2/prescriptions/${prescriptionId}/packages${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
      providesTags: () => [{ type: 'Packages', id: 'LIST' }],
    }),
    getPackages: builder.query<Package[], void>({
      query: () => {
        const queryString = buildQueryString({
          'page[limit]': String(365 * 3),
        })
        return `v2/packages${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
      providesTags: () => [{ type: 'Packages', id: 'LIST' }],
    }),
    getDoseEventsByPrescription: builder.query<
      DoseEvent[],
      {
        prescriptionId: string | number
        query?: JSONAPIQuery
      }
    >({
      query: ({ prescriptionId, query = {} }) => {
        const queryString = buildJSONAPIQuery(query)
        return `v2/prescriptions/${prescriptionId}/dose_events${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
    }),
    getReminderDispatchesByPrescription: builder.query<
      ReminderDispatch[],
      {
        prescriptionId: string | number
        query?: JSONAPIQuery
      }
    >({
      query: ({ prescriptionId, query = {} }) => {
        const queryString = buildJSONAPIQuery(query)
        return `v2/prescriptions/${prescriptionId}/reminder_dispatches${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
    }),
    getFlagsByPrescription: builder.query<
      Flag[],
      {
        prescriptionId: string | number
        query?: { flagTypes?: FlagType[] } & Omit<JSONAPIQuery, 'filter'>
      }
    >({
      query: ({ prescriptionId, query = {} }) => {
        const { flagTypes, ...jsonAPIQuery } = query
        const queryParams: JSONAPIQuery = jsonAPIQuery

        if (flagTypes) {
          queryParams.filter = {
            flag_type: flagTypes.join(','),
          }
        }

        const queryString = buildJSONAPIQuery(queryParams)
        return `v2/prescriptions/${prescriptionId}/flags${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
    }),
    getDoseEventsByDose: builder.query<
      DoseEvent[],
      {
        doseId: string | number
        query?: JSONAPIQuery
      }
    >({
      query: ({ doseId, query = {} }) => {
        const queryString = buildJSONAPIQuery(query)
        return `v2/scheduled_doses/${doseId}/dose_events${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
    }),
    getProjects: builder.query<Project[], void>({
      query: () => {
        return `v2/projects`
      },
      transformResponse: transformJSONAPIResourcesData,
    }),
    getManagedUsers: builder.query<
      { users: ManagedUser[]; eTag?: string | null },
      { projectId: string | number }
    >({
      query: ({ projectId }) => {
        return `v2/projects/${projectId}/managed_users`
      },
      transformResponse: (
        response: {
          data: JSONAPIResource<ManagedUser>[]
        },
        meta?: { response?: Response }
      ) => {
        const eTag = meta?.response?.headers.get('ETag')
        return {
          users: transformJSONAPIResourcesData(response),
          eTag,
        }
      },
      providesTags: () => [{ type: 'ManagedUsers', id: 'LIST' }],
    }),
    getManagedUser: builder.query<
      { user: ManagedUser; eTag?: string | null },
      { id: string | number }
    >({
      query: ({ id }) => {
        return `v2/managed_users/${id}`
      },
      transformResponse: (
        response: {
          data: JSONAPIResource<ManagedUser>
        },
        meta?: { response?: Response }
      ) => {
        const eTag = meta?.response?.headers.get('ETag')
        return {
          user: transformJSONAPIResource(response),
          eTag,
        }
      },
      providesTags: (_result, _error, { id }) => [{ type: 'ManagedUsers', id }],
    }),
    updateManagedUserRoles: builder.mutation<
      void,
      UpdateManagedUserRolesParams
    >({
      query: ({ id, roles }) => {
        const body = prepareBody({
          roles,
        })
        return {
          url: `v2/managed_users/${id}/roles`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (_result, error, { id }) =>
        !error
          ? [
              { type: 'ManagedUsers', id },
              { type: 'ManagedUsers', id: 'LIST' },
            ]
          : [],
    }),
    postRenewManagedUserInvitation: builder.mutation<
      void,
      { id: string | number }
    >({
      query: ({ id }) => {
        return {
          url: `v2/managed_users/${id}/renew_invitation`,
          method: 'POST',
        }
      },
      invalidatesTags: (_result, error, { id }) =>
        !error
          ? [
              { type: 'ManagedUsers', id },
              { type: 'ManagedUsers', id: 'LIST' },
            ]
          : [],
    }),
    postRevokeManagedUserInvitation: builder.mutation<
      void,
      { id: string | number }
    >({
      query: ({ id }) => {
        return {
          url: `v2/managed_users/${id}/revoke_invitation`,
          method: 'POST',
        }
      },
      invalidatesTags: (_result, error, { id }) =>
        !error
          ? [
              { type: 'ManagedUsers', id },
              { type: 'ManagedUsers', id: 'LIST' },
            ]
          : [],
    }),
    getProjectOrganizationTree: builder.query<
      { response: MAPIProjectWithDescendantsResponse; eTag?: string | null },
      string | number
    >({
      query: (projectId) => {
        return `/v2/projects/${projectId}?include=descendants`
      },
      transformResponse: (
        response: MAPIProjectWithDescendantsResponse,
        meta
      ) => {
        return {
          response,
          eTag: meta?.response?.headers.get('ETag'),
        }
      },
    }),
    postAddWatchedPrescription: builder.mutation<
      void,
      {
        userId: string | number
        prescriptionId: string | number
        intervalQuery: {
          from: string
          to: string
        }
      }
    >({
      query: ({ userId, prescriptionId }) => {
        const body = prepareBody({
          data: [
            {
              id: prescriptionId,
              type: 'prescriptions',
            },
          ],
        })

        return {
          url: `v2/users/${userId}/relationships/watched_prescriptions`,
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(
        { prescriptionId, intervalQuery },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          mapiApi.util.updateQueryData(
            'getPrescriptionSummaries',
            intervalQuery,
            (draft) => {
              const prescriptionIndex = original(
                draft
              )?.prescriptions.findIndex(({ id }) => id === prescriptionId)

              if (typeof prescriptionIndex === 'number') {
                draft.prescriptions[prescriptionIndex].watched = true
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    destroyWatchedPrescription: builder.mutation<
      void,
      {
        userId: string | number
        prescriptionId: string | number
        intervalQuery: {
          from: string
          to: string
        }
      }
    >({
      query: ({ userId, prescriptionId }) => {
        const body = prepareBody({
          data: [
            {
              id: prescriptionId,
              type: 'prescriptions',
            },
          ],
        })

        return {
          url: `v2/users/${userId}/relationships/watched_prescriptions`,
          method: 'DELETE',
          body,
        }
      },
      async onQueryStarted(
        { prescriptionId, intervalQuery },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          mapiApi.util.updateQueryData(
            'getPrescriptionSummaries',
            intervalQuery,
            (draft) => {
              const prescriptionIndex = original(
                draft
              )?.prescriptions.findIndex(({ id }) => id === prescriptionId)

              if (typeof prescriptionIndex === 'number') {
                draft.prescriptions[prescriptionIndex].watched = false
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    getUserRolesByProject: builder.query<
      UserRole[],
      {
        projectId: string | number
      }
    >({
      query: ({ projectId }) => {
        const queryString = buildQueryString({
          'page[limit]': String(365 * 3),
        })
        return `v2/projects/${projectId}/user_roles${queryString}`
      },
      transformResponse: transformJSONAPIResourcesData,
      providesTags: (_result, _error, { projectId }) => [
        { type: 'UserRoles', id: projectId },
      ],
    }),
    getPermissionsByProject: builder.query<
      Permission[],
      {
        projectId: string | number
      }
    >({
      query: ({ projectId }) => `v2/projects/${projectId}/permissions`,
      transformResponse: (response: {
        data: Record<string, { type: string }>
      }) => {
        return Object.keys(response.data).map((key: string) => {
          return {
            title: key,
            permissionType: response.data[key].type,
          } as Permission
        })
      },
    }),
    postInvitation: builder.mutation<
      void,
      {
        roles: {
          roleId: string | number
          organizationId: string | number
        }[]
        email: string
        projectId: string | number
      }
    >({
      query: ({ roles, email, projectId }) => {
        const body = prepareBody({
          data: {
            type: 'invitations',
            attributes: {
              roles,
              email,
            },
            relationships: {
              organization: {
                data: { type: 'organizations', id: projectId },
              },
            },
          },
        })

        return {
          url: `v2/invitations`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (_result, error) =>
        !error ? [{ type: 'ManagedUsers', id: 'LIST' }] : [],
    }),
  }),
})

export const {
  useCreateScheduledDoseSchemaByPrescriptionMutation,
  useDestroyPrescriptionMutation,
  useDestroyScheduledDoseMutation,
  useDestroyScheduledDoseSchemaMutation,
  useDestroyWatchedPrescriptionMutation,
  useGetAdherenceByPrescriptionQuery,
  useGetDoseEventsByDoseQuery,
  useGetDoseEventsByPrescriptionQuery,
  useGetFlagsByPrescriptionQuery,
  useGetInvitationsQuery,
  useGetManagedUserQuery,
  useGetManagedUsersQuery,
  useGetPackagesByPrescriptionQuery,
  useGetPackagesQuery,
  useGetPermissionsByProjectQuery,
  useGetPrescriptionQuery,
  useGetPrescriptionConfigQuery,
  useGetPrescriptionSummariesQuery,
  useGetProjectOrganizationTreeQuery,
  useGetProjectsQuery,
  useGetReminderDispatchesByPrescriptionQuery,
  useGetScheduledDoseQuery,
  useGetScheduledDoseSchemaQuery,
  useGetScheduledDosesByPrescriptionQuery,
  useGetTakenPodQuery,
  useGetTakenPodsByScheduledDoseQuery,
  useGetUnmatchedTakenPodsByPrescriptionQuery,
  useGetUserRolesByProjectQuery,
  usePostAcceptInvitationMutation,
  usePostAddWatchedPrescriptionMutation,
  usePostCreatePrescriptionMutation,
  usePostDoseEventsBatchMutation,
  usePostInvitationMutation,
  usePostRenewManagedUserInvitationMutation,
  usePostRevokeManagedUserInvitationMutation,
  usePrefetch,
  useUpdateManagedUserRolesMutation,
  useUpdatePrescriptionMutation,
  useUpdateScheduledDoseMutation,
  useUpdateScheduledDoseSchemaMutation,
  useDisconnectMiaModuleFromPrescriptionMutation,
  useGetAvailableMiaModulesQuery,
  useCreateExposureReportMutation,
  useCreateAdherenceReportMutation,
  useGetReportsQuery,
  useLazyGetReportLinkQuery,
  useCreateCsvReportMutation,
  useGetJobStatusQuery,
} = mapiApi
