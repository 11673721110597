import React, { useEffect, useState } from 'react'
import {
  Job,
  JobStatusContext,
} from '@mevia/features/jobStatusMonitor/jobStatusContext'
import { JobStatus, useGetJobStatusQuery } from '@mevia/mapiAPI'
import { useToast } from '@mevia/features/toast/toastContext'

type PossibleJobStates = JobStatus['status'] | 'requestError' | 'pending'

function jobStateToToastType(jobState: PossibleJobStates) {
  switch (jobState) {
    case 'queued':
    case 'working':
    case 'pending':
      return 'pending'
    case 'failed':
    case 'requestError':
      return 'error'
    case 'completed':
      return 'success'
    default:
      return 'info'
  }
}

function getJobState(
  jobQuery: ReturnType<typeof useGetJobStatusQuery>
): PossibleJobStates {
  if (jobQuery.error) {
    return 'requestError'
  }
  return jobQuery.data?.status ?? 'pending'
}

function isFinalJobState(jobState: PossibleJobStates) {
  return jobState === 'completed' || jobState === 'failed'
}

type Props = {
  job: Job
  onFinalState: () => void
}

function SpecificJobIdMonitor(props: Props) {
  const toastState = useToast()
  const { job, onFinalState } = props
  const prevAddedToast = React.useRef<string | null>(null)
  const queryData = useGetJobStatusQuery(job.jobId, {
    pollingInterval: 1000,
  })
  const jobState = getJobState(queryData)

  useEffect(() => {
    if (prevAddedToast.current) {
      toastState.close(prevAddedToast.current)
    }

    if (jobState) {
      const jobStateDesc = job.states[jobState]
      prevAddedToast.current = toastState.add({
        type: jobStateToToastType(jobState),
        title: jobStateDesc.title,
        description: jobStateDesc.message,
      })
      if (typeof jobStateDesc.on === 'function') {
        jobStateDesc.on()
      }
      if (isFinalJobState(jobState)) {
        onFinalState()
      }
    }
  }, [jobState])

  return null
}

interface JobStatusToasterProps {
  children: React.ReactNode
}

export function JobStatusMonitor({ children }: JobStatusToasterProps) {
  const [monitoredJobs, setMonitoredJobs] = useState<Job[]>([])
  return (
    <JobStatusContext.Provider
      value={{
        pushJob: (job: Job) => {
          setMonitoredJobs([...monitoredJobs, job])
        },
      }}
    >
      {monitoredJobs.map((job) => (
        <SpecificJobIdMonitor
          key={job.jobId}
          job={job}
          onFinalState={() => {
            setMonitoredJobs(
              monitoredJobs.filter(
                (monitoredJob) => monitoredJob.jobId !== job.jobId
              )
            )
          }}
        />
      ))}
      {children}
    </JobStatusContext.Provider>
  )
}
