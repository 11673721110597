import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../../utils/request'
import { Question } from '../../reducers/participant/questions'

type Questions = Question[]

export const fetchAll = createAsyncThunk<Questions>(
  'participants/questions/fetchAll',
  async () => {
    const response = await request.get<{ data: Questions }>('v2/questions')
    return response.data
  }
)
