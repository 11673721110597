import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { CheckIcon } from './Icon'

const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
`

// Hidden but accessible
const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
`

const StyledRadioButton = styled.div<{ checked?: boolean }>`
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  width: var(--spacing-s);
  height: var(--spacing-s);
  background: ${(props) =>
    props.checked ? 'var(--color-blue-alpha)' : 'var(--color-white)'};
  border: 1px solid
    ${(props) =>
      props.checked ? 'var(--color-blue-alpha)' : 'var(--color-black-delta)'};
  border-radius: 100%;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  ${HiddenRadioButton}:focus + & {
    border-color: var(--color-blue-alpha);
  }
`

const RadioButtonIcon = styled(CheckIcon)<{ checked?: boolean }>`
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-white);
  transform: translate(-50%, -50%);
`

export const Label = styled.span`
  cursor: pointer;
  margin-left: var(--spacing-xs);
  color: var(--color-black-alpha);
  line-height: 1.1;
`

type RadioButtonProps = Omit<React.ComponentPropsWithoutRef<'input'>, 'type'>

type Ref = HTMLInputElement
export const RadioButton = React.forwardRef<Ref, RadioButtonProps>(
  ({ checked, ...props }, ref) => (
    <>
      <HiddenRadioButton {...props} ref={ref} />
      <StyledRadioButton checked={checked}>
        <RadioButtonIcon checked={checked} noSpacing size="tiny" />
      </StyledRadioButton>
    </>
  )
)
RadioButton.displayName = 'RadioButton'

interface CustomRadioButtonProps extends RadioButtonProps {
  label: ReactNode
}

export const CustomRadioButton = React.forwardRef<Ref, CustomRadioButtonProps>(
  ({ label, ...props }, ref) => (
    <RadioButtonContainer data-cy="radio-button">
      <RadioButton {...props} ref={ref} />
      <Label>{label}</Label>
    </RadioButtonContainer>
  )
)
CustomRadioButton.displayName = 'CustomRadioButton'
