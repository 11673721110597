import { parse } from 'query-string'
import React, { useState } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import withRouter from '@mevia/utils/withRouter'
import { compose } from 'redux'
import styled from 'styled-components'
import {
  postAcceptInvitation,
  putPasswordReset,
} from '../actions/authentication'
import SimpleErrorMessage from '@mevia/components/atoms/SimpleErrorMessage'
import Input from '@mevia/components/atoms/Input'
import InputWrapper from '@mevia/components/molecules/InputWrapper'
import AuthContainer from '@mevia/components/organisms/AuthContainer'
import { unwrapResult } from '@reduxjs/toolkit'

const placeholderMessages = defineMessages({
  password: {
    id: 'reset-password-password-input-placeholder',
    defaultMessage: 'Enter password',
  },
  retypePassword: {
    id: 'reset-password-retype-password-input-placeholder',
    defaultMessage: 'Confirm password',
  },
})

const errorMessages = defineMessages({
  mismatch: {
    id: 'reset-password-error-mismatch',
    defaultMessage: 'Passwords do not match',
  },
  tooShort: {
    id: 'reset-password-error-too-short',
    defaultMessage:
      'Password needs to be at least {characters} characters long',
  },
  previouslyClaimed: {
    id: 'reset-password-error-previously-claimed',
    defaultMessage: 'Your invitation has already been claimed',
  },
  invalidToken: {
    id: 'reset-password-error-invalid-token',
    defaultMessage: 'Your invitation token is invalid',
  },
  expired: {
    id: 'reset-password-error-expired',
    defaultMessage: 'Your invitation has expired',
  },
  generic: {
    id: 'reset-password-error-generic',
    defaultMessage:
      'Something went wrong when trying to change password. Please try again later or contact support.',
  },
})

const errorTypes = {
  'No invitation token provided': errorMessages.invalidToken,
  'Invalid token': errorMessages.invalidToken,
  'Token has expired': errorMessages.expired,
  'Token has already been claimed': errorMessages.previouslyClaimed,
}

const successMessages = defineMessages({
  match: {
    id: 'reset-password-match',
    defaultMessage: 'Passwords match',
  },
})

const Bold = styled.span`
  font-weight: var(--bold);
`

const minPasswordLength = 6

const ResetPassword = ({
  putPasswordReset,
  postAcceptInvitation,
  isLoading,
  fetchError,
  router,
  intl,
}) => {
  const [password, setPassword] = useState('')
  const [retypePassword, setRetypePassword] = useState('')
  const [success, setSuccess] = useState(false)

  const showMismatchError =
    password !== retypePassword && retypePassword.length > 0
  const showMatch =
    password.length >= minPasswordLength && password === retypePassword

  const onSubmit = () => {
    const query = parse(router.location.search)
    const token =
      query['reset_password_token'] || query['invitation_token'] || ''

    const actionCreator = {
      '/reset_password': putPasswordReset,
      '/create_account': postAcceptInvitation,
    }[router.location.pathname]

    actionCreator({
      token,
      password,
      passwordConfirmation: retypePassword,
    })
      .then(unwrapResult)
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
  }

  const { bannerStatus, bannerTitle } = (() => {
    if (fetchError) {
      const messageKey = errorTypes[fetchError.message] || errorMessages.generic
      return {
        bannerStatus: 'error',
        bannerTitle: <span>{intl.formatMessage(messageKey)}</span>,
      }
    }
    return {}
  })()

  if (success) {
    return (
      <AuthContainer
        onSubmit={() =>
          router.navigate({
            pathname: '/login',
          })
        }
        title={
          <FormattedMessage
            id="reset-password-updated"
            defaultMessage="Password successfully updated"
          />
        }
        subtitle={
          <FormattedMessage
            id="reset-password-updated-description"
            defaultMessage="Your password has been changed successfully. You can now login."
          />
        }
        buttonContent={
          <FormattedMessage
            id="reset-password-return-to-login"
            defaultMessage="Return to login"
          />
        }
      />
    )
  }

  return (
    <AuthContainer
      bannerStatus={bannerStatus}
      bannerTitle={bannerTitle}
      onSubmit={onSubmit}
      title={
        <FormattedMessage
          id="reset-password-header"
          defaultMessage="Enter your new password"
        />
      }
      errorContent={
        <>
          {showMismatchError && (
            <SimpleErrorMessage
              status="error"
              message={intl.formatMessage(errorMessages.mismatch)}
            />
          )}
          {showMatch && (
            <SimpleErrorMessage
              status="success"
              message={intl.formatMessage(successMessages.match)}
            />
          )}
        </>
      }
      disabled={
        isLoading ||
        password.length < minPasswordLength ||
        password !== retypePassword
      }
      buttonContent={
        isLoading ? (
          <FormattedMessage
            id="reset-password-pending-request"
            defaultMessage="Changing password..."
          />
        ) : (
          <FormattedMessage
            id="reset-password-submit-button"
            defaultMessage="Confirm"
          />
        )
      }
      linkContent={
        location.pathname !== '/create_account' && (
          <>
            <FormattedMessage
              id="forgot-password-remember"
              defaultMessage="Did you remember? "
            />
            <Bold>
              <FormattedMessage
                id="forgot-password-login"
                defaultMessage="Login"
              />
            </Bold>
          </>
        )
      }
      linkTo="/login"
    >
      <InputWrapper>
        <Input
          type="password"
          placeholder={intl.formatMessage(placeholderMessages.password)}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoFocus
          required
          invalidInput={false}
          data-cy="password"
        />
      </InputWrapper>
      {password.length < minPasswordLength && password && (
        <SimpleErrorMessage
          message={intl.formatMessage(errorMessages.tooShort, {
            characters: minPasswordLength,
          })}
        />
      )}
      <InputWrapper>
        <Input
          type="password"
          placeholder={intl.formatMessage(placeholderMessages.retypePassword)}
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
          required
          invalidInput={false}
          data-cy="retype-password"
        />
      </InputWrapper>
    </AuthContainer>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
  fetchError: state.user.resetPasswordError,
})

const mapDispatchToProps = {
  putPasswordReset,
  postAcceptInvitation,
}

const enhance = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ResetPassword)
