import { createSlice } from '@reduxjs/toolkit'
import * as actions from '../actions/recipients'
import * as flagPoliciesActions from '../actions/flagPolicies'
import * as notificationPoliciesActions from '../actions/notificationPolicies'
import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import { RootState } from './types'
import { Recipient, RecipientId } from '@mevia/types/mapiAPI'

export interface RecipientState {
  readonly byId: Record<RecipientId, Recipient>
  readonly idsByFlagPolicyId: Record<string, RecipientId[]>
  readonly idsByNotificationPolicyId: Record<string, RecipientId[]>
}

const initialState: RecipientState = {
  byId: {},
  idsByFlagPolicyId: {},
  idsByNotificationPolicyId: {},
}

const recipientsSlice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      actions.createRecipientForPrescription.fulfilled,
      (state, action) => {
        state.byId[action.payload.id] = action.payload
      }
    )
    builder.addCase(actions.fetchByPrescription.fulfilled, (state, action) => {
      state.byId = {
        ...state.byId,
        ...keyBy(action.payload, 'id'),
      }
    })
    builder.addCase(
      notificationPoliciesActions.fetchAll.fulfilled,
      (state, action) => {
        state.byId = {
          ...state.byId,
          ...keyBy(action.payload.recipients, 'id'),
        }

        state.idsByNotificationPolicyId = {
          ...state.idsByNotificationPolicyId,
          ...action.payload.relationships,
        }
      }
    )
    builder.addCase(
      notificationPoliciesActions.fetchRecipients.fulfilled,
      (state, action) => {
        state.byId = {
          ...state.byId,
          ...keyBy(action.payload, 'id'),
        }
        state.idsByNotificationPolicyId[action.meta.arg.notificationPolicyId] =
          action.payload.map(({ id }) => id)
      }
    )
    builder.addCase(
      flagPoliciesActions.fetchRecipients.fulfilled,
      (state, action) => {
        state.byId = {
          ...state.byId,
          ...keyBy(action.payload, 'id'),
        }
        state.idsByFlagPolicyId[action.meta.arg.flagPolicyId] =
          action.payload.map(({ id }) => id)
      }
    )

    builder.addCase(
      flagPoliciesActions.fetchByPrescription.fulfilled,
      (state, action) => {
        state.byId = {
          ...state.byId,
          ...keyBy(action.payload.recipients, 'id'),
        }

        state.idsByFlagPolicyId = {
          ...state.idsByFlagPolicyId,
          ...action.payload.relationships,
        }
      }
    )
    builder.addCase(actions.update.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(actions.destroy.fulfilled, (state, action) => {
      state.byId = omit(state.byId, action.payload)
    })
    builder.addCase(
      flagPoliciesActions.linkRecipient.fulfilled,
      (state, action) => {
        const flagPolicyId = action.payload.flagPolicyId
        const linkedRecipients = state.idsByFlagPolicyId[flagPolicyId] || []
        linkedRecipients.push(action.payload.recipientId)
        state.idsByFlagPolicyId[flagPolicyId] = linkedRecipients
      }
    )
    builder.addCase(
      notificationPoliciesActions.linkRecipient.fulfilled,
      (state, action) => {
        const notificationPolicyId = action.payload.notificationPolicyId
        const linkedRecipients =
          state.idsByNotificationPolicyId[notificationPolicyId] || []
        linkedRecipients.push(action.payload.recipientId)
        state.idsByNotificationPolicyId[notificationPolicyId] = linkedRecipients
      }
    )
    builder.addCase(
      notificationPoliciesActions.createRecipient.fulfilled,
      (state, action) => {
        state.byId[action.payload.id] = action.payload

        const notificationPolicyId = action.meta.arg.notificationPolicyId
        const linkedRecipients =
          state.idsByNotificationPolicyId[notificationPolicyId] || []
        linkedRecipients.push(action.payload.id)
        state.idsByNotificationPolicyId[notificationPolicyId] = linkedRecipients
      }
    )
    builder.addCase(
      flagPoliciesActions.createRecipient.fulfilled,
      (state, action) => {
        state.byId[action.payload.id] = action.payload

        const flagPolicyId = action.meta.arg.flagPolicyId
        const linkedRecipients = state.idsByFlagPolicyId[flagPolicyId] || []
        linkedRecipients.push(action.payload.id)
        state.idsByFlagPolicyId[flagPolicyId] = linkedRecipients
      }
    )
    builder.addCase(
      flagPoliciesActions.unlinkRecipient.fulfilled,
      (state, action) => {
        const flagPolicyId = action.payload.flagPolicyId
        const linkedRecipients = state.idsByFlagPolicyId[flagPolicyId] || []
        state.idsByFlagPolicyId[flagPolicyId] = linkedRecipients.filter(
          (id) => id !== action.payload.recipientId
        )
      }
    )
    builder.addCase(
      notificationPoliciesActions.unlinkRecipient.fulfilled,
      (state, action) => {
        const notificationPolicyId = action.payload.notificationPolicyId
        const linkedRecipients =
          state.idsByNotificationPolicyId[notificationPolicyId] || []
        state.idsByNotificationPolicyId[notificationPolicyId] =
          linkedRecipients.filter((id) => id !== action.payload.recipientId)
      }
    )
  },
})

export default recipientsSlice.reducer

export const selectByPrescriptionId = (
  prescriptionId: string,
  state: RootState
): Recipient[] =>
  Object.values(state.recipients.byId).filter(
    (recipient) => String(recipient.prescriptionId) === String(prescriptionId)
  )
