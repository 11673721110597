import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
// @ts-expect-error Not a TS-file
import IconAndTitleBase from './IconAndTitle'
// @ts-expect-error Not a TS-file
import { VisuallyHidden } from '@mevia/components/util'
// @ts-expect-error Not a TS-file
import Button from '@mevia/components/atoms/Button'
import {
  CheckCircleIcon,
  CloseIcon,
  InfoIcon,
  WarningIcon,
} from '@mevia/components/atoms/Icon'
import { BP_XL } from '../../constants/breakpoints'
import { FormattedMessage } from 'react-intl'

const CloseButton = styled(Button)`
  position: absolute;
  top: var(--spacing-s);
  right: var(--spacing-s);
  padding: 0;

  &:focus,
  &:hover {
    opacity: 0.7;
  }
`

const IconAndTitle = styled(IconAndTitleBase)`
  flex: unset;
`

const BannerWrapper = styled.div<{
  bannerTypeColor: string
  row?: boolean
}>`
  position: relative; //for close button
  margin-bottom: var(--spacing-m);
  padding: var(--spacing-m);
  background: ${(props) => props.bannerTypeColor};
  color: 'var(--color-black-alpha)';

  ${(props) =>
    props.row && `display: flex; flex-direction: row; align-items: center;`}

  @media (min-width: ${BP_XL}) {
    margin-bottom: var(--spacing-l);
  }
`

const Description = styled.div`
  margin: var(--spacing-xxs) 0 0 calc(var(--spacing-s) + var(--spacing-xs)); //width icon + spacing
  font-size: var(--font-size-s);
  color: var(--color-black-beta);
`

interface Props {
  type: string
  title: React.ReactNode
  children?: React.ReactNode
  row?: boolean
  dismiss?: boolean
}

const Banner = ({
  type,
  title,
  children,
  dismiss,
  ...props
}: Props): ReactElement | null => {
  const [visibleBanner, setVisibleBanner] = useState(true)

  const removeBanner = () => {
    setVisibleBanner(!visibleBanner)
  }

  const getBannerTypeStyling = (type: string) => {
    switch (type) {
      case 'error':
        return {
          icon: <WarningIcon color={'var(--color-red-alpha)'} />,
          color: 'var(--color-red-delta)',
        }
      case 'success':
        return {
          icon: <CheckCircleIcon color={'var(--color-green-alpha)'} />,
          color: 'var(--color-green-delta)',
        }
      default:
        return {
          icon: <InfoIcon color={'var(--color-black-alpha)'} />,
          color: 'var(--color-grey-alpha)',
        }
    }
  }

  const { icon, color } = getBannerTypeStyling(type)

  if (!visibleBanner) return null

  return (
    <BannerWrapper bannerTypeColor={color} {...props}>
      <IconAndTitle icon={icon} title={title} bold={true} />
      {children && <Description>{children}</Description>}
      {dismiss && (
        <CloseButton size="tiny" variant="lowEmphazis" onClick={removeBanner}>
          <VisuallyHidden>
            <FormattedMessage
              id="banner.close-button-label"
              defaultMessage="Close banner"
              description="Banner: Button text for close button"
            />
          </VisuallyHidden>
          <CloseIcon size="tiny" noSpacing />
        </CloseButton>
      )}
    </BannerWrapper>
  )
}

export default Banner
