import React, { ReactElement } from 'react'
import styled from 'styled-components'

// Import icon file
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as Barcode } from '../../../assets/icons/barcode.svg'
import { ReactComponent as BellMute } from '../../../assets/icons/bell-mute.svg'
import { ReactComponent as Bell } from '../../../assets/icons/bell.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import { ReactComponent as Cellphone } from '../../../assets/icons/cellphone.svg'
import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg'
import { ReactComponent as Check } from '../../../assets/icons/check.svg'
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg'
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg'
import { ReactComponent as ChevronUp } from '../../../assets/icons/chevron-up.svg'
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg'
import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import { ReactComponent as Columns } from '../../../assets/icons/columns.svg'
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg'
import { ReactComponent as Desktop } from '../../../assets/icons/desktop.svg'
import { ReactComponent as DoubleChevronLeft } from '../../../assets/icons/double-chevron-left.svg'
import { ReactComponent as DoubleChevronRight } from '../../../assets/icons/double-chevron-right.svg'
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg'
import { ReactComponent as ExclamationMark } from '../../../assets/icons/exclamation-mark.svg'
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg'
import { ReactComponent as Food } from '../../../assets/icons/food.svg'
import { ReactComponent as Globe } from '../../../assets/icons/globe.svg'
import { ReactComponent as Info } from '../../../assets/icons/info.svg'
import { ReactComponent as Leave } from '../../../assets/icons/leave.svg'
import { ReactComponent as List } from '../../../assets/icons/list.svg'
import { ReactComponent as Mail } from '../../../assets/icons/mail.svg'
import { ReactComponent as Menu } from '../../../assets/icons/menu.svg'
import { ReactComponent as Minus } from '../../../assets/icons/minus.svg'
import { ReactComponent as More } from '../../../assets/icons/more.svg'
import { ReactComponent as Note } from '../../../assets/icons/note.svg'
import { ReactComponent as Organisation } from '../../../assets/icons/organisation.svg'
import { ReactComponent as PauseCircle } from '../../../assets/icons/pause-circle.svg'
import { ReactComponent as Pill } from '../../../assets/icons/pill.svg'
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'
import { ReactComponent as Redo } from '../../../assets/icons/redo.svg'
import { ReactComponent as Refresh } from '../../../assets/icons/refresh-cw.svg'
import { ReactComponent as Search } from '../../../assets/icons/search.svg'
import { ReactComponent as Send } from '../../../assets/icons/send.svg'
import { ReactComponent as Settings } from '../../../assets/icons/settings.svg'
import { ReactComponent as Sort } from '../../../assets/icons/sort.svg'
import { ReactComponent as Sun } from '../../../assets/icons/sun.svg'
import { ReactComponent as User } from '../../../assets/icons/user.svg'
import { ReactComponent as UserCheck } from '../../../assets/icons/user-check.svg'
import { ReactComponent as UserPlus } from '../../../assets/icons/user-plus.svg'
import { ReactComponent as Users } from '../../../assets/icons/users.svg'
import { ReactComponent as WarningTriangle } from '../../../assets/icons/warning-triangle.svg'
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg'
import { ReactComponent as Wireless } from '../../../assets/icons/wireless.svg'

const IconWrapper = styled.div<{
  width: string
  marginLeft: string
  marginRight: string
  color?: string
}>`
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  color: ${(props) => props.color};
`

const sizeVariants = {
  large: 'var(--spacing-m)',
  small: '.875rem',
  tiny: 'var(--spacing-xs)',
}

export type Size = keyof typeof sizeVariants

const getIconSize = (size?: Size): string => {
  const defaultSize = 'var(--spacing-s)'

  if (size) return sizeVariants[size]
  else return defaultSize
}

interface IconProps extends React.ComponentPropsWithoutRef<'div'> {
  icon: ReactElement
  noSpacing?: boolean
  spacingLeft?: boolean
  className?: string
  size?: Size
  color?: string
}

const Icon = React.forwardRef<HTMLDivElement, IconProps>(function IconWithRef(
  { icon, noSpacing, spacingLeft, size, ...props },
  ref
) {
  const spacing = size === 'large' ? 'var(--spacing-xs)' : 'var(--spacing-xxs)'
  const marginLeft = spacingLeft ? spacing : ''
  const marginRight = spacingLeft || noSpacing ? '' : spacing
  const iconSize = getIconSize(size)

  return (
    <IconWrapper
      width={iconSize}
      marginLeft={marginLeft}
      marginRight={marginRight}
      ref={ref}
      {...props}
    >
      {icon}
    </IconWrapper>
  )
})

const createIcon = (icon: ReactElement) => {
  const Component = React.forwardRef<HTMLDivElement, Omit<IconProps, 'icon'>>(
    function IconComponent({ ...props }, ref) {
      return <Icon ref={ref} icon={icon} {...props} />
    }
  )
  return Component
}

// Create and export icon
export const ArrowDownIcon = createIcon(<ArrowDown />)
export const ArrowLeftIcon = createIcon(<ArrowLeft />)
export const ArrowRightIcon = createIcon(<ArrowRight />)
export const ArrowUpIcon = createIcon(<ArrowUp />)
export const BarcodeIcon = createIcon(<Barcode />)
export const BellMuteIcon = createIcon(<BellMute />)
export const BellIcon = createIcon(<Bell />)
export const CalendarIcon = createIcon(<Calendar />)
export const CellphoneIcon = createIcon(<Cellphone />)
export const CheckCircleIcon = createIcon(<CheckCircle />)
export const CheckIcon = createIcon(<Check />)
export const ChevronDownIcon = createIcon(<ChevronDown />)
export const ChevronLeftIcon = createIcon(<ChevronLeft />)
export const ChevronRightIcon = createIcon(<ChevronRight />)
export const ChevronUpIcon = createIcon(<ChevronUp />)
export const ClockIcon = createIcon(<Clock />)
export const CloseIcon = createIcon(<Close />)
export const ColumnsIcon = createIcon(<Columns />)
export const DeleteIcon = createIcon(<Delete />)
export const DesktopIcon = createIcon(<Desktop />)
export const DoubleChevronLeftIcon = createIcon(<DoubleChevronLeft />)
export const DoubleChevronRightIcon = createIcon(<DoubleChevronRight />)
export const EditIcon = createIcon(<Edit />)
export const ExlamationMarkIcon = createIcon(<ExclamationMark />)
export const FilterIcon = createIcon(<Filter />)
export const FoodIcon = createIcon(<Food />)
export const GlobeIcon = createIcon(<Globe />)
export const InfoIcon = createIcon(<Info />)
export const LeaveIcon = createIcon(<Leave />)
export const ListIcon = createIcon(<List />)
export const MailIcon = createIcon(<Mail />)
export const MenuIcon = createIcon(<Menu />)
export const MinusIcon = createIcon(<Minus />)
export const MoreIcon = createIcon(<More />)
export const NoteIcon = createIcon(<Note />)
export const OrganisationIcon = createIcon(<Organisation />)
export const PauseCircleIcon = createIcon(<PauseCircle />)
export const PillIcon = createIcon(<Pill />)
export const PlusIcon = createIcon(<Plus />)
export const RedoIcon = createIcon(<Redo />)
export const RefreshIcon = createIcon(<Refresh />)
export const SearchIcon = createIcon(<Search />)
export const SendIcon = createIcon(<Send />)
export const SettingsIcon = createIcon(<Settings />)
export const SortIcon = createIcon(<Sort />)
export const SunIcon = createIcon(<Sun />)
export const UserIcon = createIcon(<User />)
export const UserCheckIcon = createIcon(<UserCheck />)
export const UserPlusIcon = createIcon(<UserPlus />)
export const UsersIcon = createIcon(<Users />)
export const WarningTriangleIcon = createIcon(<WarningTriangle />)
export const WarningIcon = createIcon(<Warning />)
export const WirelessIcon = createIcon(<Wireless />)
