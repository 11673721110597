import React, { ReactElement, useEffect, useState } from 'react'
import { RootState } from '../../../reducers/types'
import { useAppSelector } from '@mevia/storeHooks'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import {
  DateFnsInTimeZone,
  useDateFnsInSubjectTimeZone,
  useDateFnsInBrowserTimeZone,
} from '../../../hooks/useDateFnsTimeZone'
import { InfoIcon } from '@mevia/components/atoms/Icon'
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-s);
  color: var(--color-black-alpha);
`
const TimeZoneLabel = styled.span`
  padding-right: var(--spacing-xxs);
  color: var(--color-black-beta);
`

const HtmlTooltip = withStyles({
  tooltip: {
    backgroundColor: 'var(--color-white)',
    boxShadow: 'var(--box-shadow)',
    whiteSpace: 'nowrap',
    borderRaduis: 'var(--border-radius)',
    fontSize: 'var(--font-size-xs)',
    padding: 'var(--spacing-xs)',
  },
})(Tooltip)

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TimeZoneForTitle = styled.span`
  font-size: var(--font-size-xs);
  font-weight: var(--bold);
  color: var(--color-black-alpha);
  line-height: 1.5;
`
const TimeZoneData = styled.span`
  color: var(--color-black-beta);
  line-height: 1.5;
`

interface TickingTimeProps {
  format: (date: Date) => ReactElement
}

const TickingTime = ({ format }: TickingTimeProps) => {
  const ONE_SECOND = 1000
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date())
    }, ONE_SECOND)

    return function cleanup() {
      clearInterval(interval)
    }
  })

  return format(time)
}

interface TimeZoneInfoProps extends DateFnsInTimeZone {
  label: string
  dataCy: string
}

const TimeZoneInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: var(--spacing-xs);
  }
`

const TimeZoneInfo = ({
  dataCy,
  label,
  format,
  timeZone,
}: TimeZoneInfoProps) => {
  return (
    <TimeZoneInfoWrapper data-cy={`${dataCy}-time-zone-info`}>
      <TimeZoneForTitle>{label}</TimeZoneForTitle>
      <TickingTime
        format={(currentTime) => (
          <>
            <TimeZoneData>
              {format(currentTime, 'yyyy-MM-dd HH:mm')}
            </TimeZoneData>
            <TimeZoneData>
              ({format(currentTime, 'O')}) {timeZone}
            </TimeZoneData>
          </>
        )}
      />
    </TimeZoneInfoWrapper>
  )
}

const TooltipWrapper = styled.div`
  padding-left: var(--spacing-xs);
`

const InfoTooltip = () => {
  const info = (
    <InfoWrapper data-cy="timezone-info-tooltip-content">
      <TimeZoneInfo
        dataCy="participant"
        label={'Participant'}
        {...(useDateFnsInSubjectTimeZone() as DateFnsInTimeZone)}
      />
      <TimeZoneInfo
        dataCy="local"
        label={'Local'}
        {...(useDateFnsInBrowserTimeZone() as DateFnsInTimeZone)}
      />
    </InfoWrapper>
  )

  return (
    <TooltipWrapper>
      <HtmlTooltip title={info} placement="bottom-end">
        <InfoIcon data-cy="timezone-info-tooltip" />
      </HtmlTooltip>
    </TooltipWrapper>
  )
}

const SelectedTimeZoneInfo = (): React.ReactElement | null => {
  const { preferredTimeZoneType } = useAppSelector(
    (state: RootState) => state.localization
  )
  const dateFnsInParticipantTimeZone = useDateFnsInSubjectTimeZone()
  const dateFnsInLocalTimeZone = useDateFnsInBrowserTimeZone()

  const dateFns =
    preferredTimeZoneType === 'browser'
      ? dateFnsInLocalTimeZone
      : dateFnsInParticipantTimeZone

  // Not available, probably due to not being on participant view
  if (!dateFns) {
    return null
  }

  const { format } = dateFns

  return (
    <Wrapper data-cy="selected-time-zone-info">
      <TimeZoneLabel>
        <FormattedMessage
          id="site-wide-words.time-zone"
          defaultMessage="Time zone"
        />
        :
      </TimeZoneLabel>
      {preferredTimeZoneType === 'browser' ? (
        <FormattedMessage
          id="localization.time-zone.local"
          defaultMessage="Local"
        />
      ) : (
        <FormattedMessage
          id="localization.timezone.participant"
          defaultMessage="Participant"
        />
      )}{' '}
      <TickingTime format={(time) => <>{format(time, 'HH:mm')}</>} />
      <InfoTooltip />
    </Wrapper>
  )
}

export default SelectedTimeZoneInfo
