import { createSlice } from '@reduxjs/toolkit'

const participantsTableSlice = createSlice({
  name: 'participantsTable',
  initialState: {
    hiddenColumns: undefined,
    columnOrder: undefined,
  },
  reducers: {
    setHiddenColumns: (state, action) => {
      state.hiddenColumns = action.payload
    },
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload
    },
  },
})

export const { setColumnOrder, setHiddenColumns } =
  participantsTableSlice.actions
export default participantsTableSlice.reducer
