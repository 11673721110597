import { normalize } from 'normalizr'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NormalizedSchema } from 'normalizr'
import * as schemas from '../utils/schemas'
import * as request from '../utils/request'

import { ValidationErrors } from '../reducers/types'
import {
  NotificationPolicy,
  NotificationPolicyId,
  Recipient,
  RecipientId,
} from '@mevia/types/mapiAPI'

type RelationshipLink = {
  type: string
  id: string
}

export type NotificationPolicyResponseShape = {
  id: string
  attributes: Omit<NotificationPolicy, 'id'>
  relationships: {
    recipientReceivers: {
      data: RelationshipLink[]
    }
  }
}
type NotificationPoliciesResponse = {
  data: NotificationPolicyResponseShape[]
  included?: RecipientResponseShape[]
}
type NotificationPolicyResponse = {
  data: NotificationPolicyResponseShape
}

type NortificationPolicySchema = {
  notificationPolicies: Record<string, NotificationPolicy>
}

type NormalizedNotificationPolicyPayload = NormalizedSchema<
  NortificationPolicySchema | undefined,
  unknown
>

export const fetchAllOwn = createAsyncThunk<{
  notificationPolicies: NotificationPolicy[]
}>('notificationPolicies/fetchAllOwn', async () => {
  const json = await request.get<NotificationPoliciesResponse>(
    'v2/notification_policies'
  )

  const buildEntity = <T>({
    id,
    attributes,
  }: {
    id: string
    attributes: T
  }) => ({
    id,
    ...attributes,
  })

  const notificationPolicies = json.data.map(buildEntity)

  return {
    notificationPolicies,
  }
})

export const fetchAll = createAsyncThunk<
  {
    notificationPolicies: NotificationPolicy[]
    recipients: Recipient[]
    relationships: Record<NotificationPolicyId, RecipientId[]>
  },
  { prescriptionId: string; query?: Record<string, string> }
>('notificationPolicies/fetchAll', async ({ prescriptionId, query = {} }) => {
  const json = await request.get<NotificationPoliciesResponse>(
    `v2/prescriptions/${prescriptionId}/notification_policies?include=recipient_receivers`,
    {
      query,
    }
  )

  const buildEntity = <T>({
    id,
    attributes,
  }: {
    id: string
    attributes: T
  }) => ({
    id,
    ...attributes,
  })

  const notificationPolicies = json.data.map(buildEntity)

  const recipients = json.included?.map(buildEntity) || []

  const relationships: Record<NotificationPolicyId, RecipientId[]> =
    json.data.reduce((acc, { id: notificationPolicyId, relationships }) => {
      acc[notificationPolicyId] = relationships.recipientReceivers.data.map(
        ({ id: recipientId }) => recipientId
      )
      return acc
    }, {} as Record<NotificationPolicyId, RecipientId[]>)

  return {
    notificationPolicies,
    recipients,
    relationships,
  }
})

export const fetch = createAsyncThunk<
  NotificationPolicy,
  { notificationPolicyId: NotificationPolicyId; query?: Record<string, string> }
>(
  'notificationPolicies/fetch',
  async ({ notificationPolicyId, query = {} }) => {
    const json = await request.get<NotificationPolicyResponse>(
      `v2/notification_policies/${notificationPolicyId}`,
      { query }
    )

    return { id: json.data.id, ...json.data.attributes }
  }
)

export const create = createAsyncThunk<
  NotificationPolicy,
  { prescriptionId: string; attributes: unknown; recipients?: string[] },
  {
    rejectValue: ValidationErrors
  }
>(
  'notificationPolicies/create',
  async (
    { prescriptionId, attributes, recipients = [] },
    { rejectWithValue }
  ) => {
    try {
      const json = await request.post<NotificationPolicyResponse>(
        `v2/notification_policies`,
        {
          body: {
            data: {
              type: 'notification_policies',
              attributes,
              relationships: {
                prescription: {
                  data: { type: 'prescriptions', id: prescriptionId },
                },
                recipient_receivers: {
                  data: recipients.map((id) => ({
                    type: 'recipients',
                    id,
                  })),
                },
              },
            },
          },
        }
      )
      const notificationPolicy = {
        id: json.data.id,
        ...json.data.attributes,
      }
      return notificationPolicy
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        validationErrors: error.json?.errors,
      })
    }
  }
)

export const update = createAsyncThunk<
  NormalizedNotificationPolicyPayload,
  {
    notificationPolicyId: NotificationPolicyId
    attributes: unknown
    recipients: string[]
  },
  {
    rejectValue: ValidationErrors
  }
>(
  'notificationPolicies/update',
  async (
    { notificationPolicyId, attributes, recipients },
    { rejectWithValue }
  ) => {
    try {
      const json = await request.put<NotificationPolicyResponse>(
        `v2/notification_policies/${notificationPolicyId}`,
        {
          body: {
            data: {
              type: 'notification_policies',
              id: notificationPolicyId,
              attributes,
              relationships: {
                recipient_receivers: {
                  data: recipients.map((id) => ({
                    type: 'recipients',
                    id,
                  })),
                },
              },
            },
          },
        }
      )
      const notificationPolicy = {
        id: json.data.id,
        ...json.data.attributes,
      }

      return normalize(notificationPolicy, schemas.notificationPolicy)
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        validationErrors: error.json?.errors,
      })
    }
  }
)

export const destroy = createAsyncThunk<
  NotificationPolicyId,
  NotificationPolicyId
>('notificationPolicies/destroy', async (notificationPolicyId) => {
  await request.del<NotificationPolicyResponse>(
    `v2/notification_policies/${notificationPolicyId}`
  )
  return notificationPolicyId
})

type RecipientResponseShape = {
  id: string
  attributes: Omit<Recipient, 'id'>
}
type RecipientResponse = {
  data: RecipientResponseShape
}
type RecipientsResponse = {
  data: RecipientResponseShape[]
}

export const fetchRecipients = createAsyncThunk<
  Recipient[],
  { notificationPolicyId: NotificationPolicyId }
>('notificationPolicies/fetchRecipients', async ({ notificationPolicyId }) => {
  const json = await request.get<RecipientsResponse>(
    `v2/notification_policies/${notificationPolicyId}/recipient_receivers`
  )
  const recipients = json.data.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }))
  return recipients
})

export const createRecipient = createAsyncThunk<
  Recipient,
  {
    notificationPolicyId: NotificationPolicyId
    prescriptionId: string
    attributes: unknown
  },
  {
    rejectValue: ValidationErrors
  }
>(
  'notificationPolicies/createRecipient',
  async (
    { notificationPolicyId, prescriptionId, attributes },
    { rejectWithValue }
  ) => {
    try {
      const json = await request.post<RecipientResponse>(`v2/recipients`, {
        body: {
          data: {
            type: 'recipients',
            attributes,
            relationships: {
              notification_policies: {
                data: [
                  { type: 'notification_policies', id: notificationPolicyId },
                ],
              },
              prescription: {
                data: { type: 'prescriptions', id: prescriptionId },
              },
            },
          },
        },
      })
      const recipient = {
        id: json.data.id,
        ...json.data.attributes,
      }

      return recipient
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        validationErrors: error.json?.errors,
      })
    }
  }
)

export const linkRecipient = createAsyncThunk<
  { notificationPolicyId: NotificationPolicyId; recipientId: RecipientId },
  { notificationPolicyId: NotificationPolicyId; recipientId: RecipientId }
>(
  'notificationPolicies/linkRecipient',
  async ({ notificationPolicyId, recipientId }) => {
    await request.post(
      `v2/notification_policies/${notificationPolicyId}/relationships/recipient_receivers`,
      {
        body: {
          data: [{ type: 'recipients', id: recipientId }],
        },
      }
    )
    return { notificationPolicyId, recipientId }
  }
)

export const unlinkRecipient = createAsyncThunk<
  { notificationPolicyId: NotificationPolicyId; recipientId: RecipientId },
  { notificationPolicyId: NotificationPolicyId; recipientId: RecipientId }
>(
  'notificationPolicies/unlinkRecipient',
  async ({ notificationPolicyId, recipientId }) => {
    await request.del(
      `v2/notification_policies/${notificationPolicyId}/relationships/recipient_receivers`,
      {
        body: {
          data: [{ type: 'recipients', id: recipientId }],
        },
      }
    )
    return { notificationPolicyId, recipientId }
  }
)
