import React from 'react'
import { View } from 'glamor/jsxstyle'

const Block = React.forwardRef(function BlockWithRef(
  {
    center,
    inline,
    row,
    column,
    wrap,
    component = 'div',
    children,
    props,
    ...rest
  },
  ref
) {
  let styles = {
    display: 'block',
  }

  if (row || column) styles.display = 'flex'

  if (inline) styles.display = 'inline-block'

  if (column) styles.flexDirection = 'column'

  if (wrap) styles.flexWrap = 'wrap'

  if (center) styles.textAlign = 'center'

  return (
    <View component={component} ref={ref} props={props} {...styles} {...rest}>
      {children}
    </View>
  )
})

export default Block
