/**
 * Apply `enhance` to a reducer (most likely the root reducer) then dispatch
 * `action(state)` to hydrate store state.
 */

import { Action, AnyAction, Reducer } from 'redux'
const ACTION_TYPE = '__HYDRATE_STATE__'

interface HydrateAction<S> extends Action {
  type: '__HYDRATE_STATE__'
  initialState?: S
}

export function createAction<S>(initialState?: S): HydrateAction<S> {
  return {
    type: ACTION_TYPE,
    initialState,
  }
}

// Redux is using any in for their types (see https://github.com/reduxjs/redux/blob/master/src/types/reducers.ts). Seems reasonable to do the same here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function enhance<S = any, A extends Action = AnyAction>(
  reducer: Reducer<S, A>
) {
  return (state: S, action: A): S =>
    action.type === ACTION_TYPE
      ? reducer((action as unknown as HydrateAction<S>).initialState, action)
      : reducer(state, action)
}
