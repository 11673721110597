import React from 'react'
import styled from 'styled-components'
import { BP_S } from '../../constants/breakpoints'
import SimpleErrorMessage from '@mevia/components/atoms/SimpleErrorMessage'
import InputLabel from '@mevia/components/atoms/InputLabel'
import InputDescription from '@mevia/components/atoms/InputDescription'

const StyledInputWrapper = styled.div<{ column: boolean }>`
  @media (min-width: ${BP_S}) {
    flex-basis: ${({ column }) => (column ? '49%' : '100%')};
  }

  &:not(:first-child) {
    margin-top: var(--spacing-m);

    @media (min-width: ${BP_S}) {
      margin-top: ${({ column }) => (column ? 0 : 'var(--spacing-m)')};
    }
  }
`

interface Props {
  id?: string
  label?: React.ReactNode | null
  description?: React.ReactNode
  errorMessage?: React.ReactNode | undefined
  column?: boolean
  children?: React.ReactNode | null
}

const InputWrapper = ({
  id = undefined,
  label = null,
  description = undefined,
  errorMessage = undefined,
  column = false,
  children = null,
  ...props
}: Props) => (
  <StyledInputWrapper column={column} {...props}>
    {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
    {children}
    {errorMessage && <SimpleErrorMessage message={errorMessage} />}
    {description && <InputDescription>{description}</InputDescription>}
  </StyledInputWrapper>
)

export default InputWrapper
