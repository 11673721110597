import { createSlice, isPending, isRejected } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'
import * as actions from '../actions/scheduledDoseSchemas'
import { ScheduledDoseSchemaId } from '../actions/scheduledDoseSchemas'
import { RootState } from './types'
import { NotificationPolicy } from '@mevia/types/mapiAPI'

export interface ScheduledDoseSchema {
  createdAt: string
  daysBetweenDoses: number
  endDate: string
  endTime: string
  fullDay: boolean
  id: ScheduledDoseSchemaId
  podAmount: number
  prescriptionId: string | number
  startDate: string
  startTime: string
  updatedAt: string
  weekdays: unknown
}

export interface ScheduledDoseSchemasState {
  readonly byId: Partial<Record<number | string, ScheduledDoseSchema>>
  readonly isLoading: boolean
}

const initialState: ScheduledDoseSchemasState = {
  byId: {},
  isLoading: false,
}

const scheduledDoseSchemasSlice = createSlice({
  name: 'scheduledDoseSchemas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.fetchAll.fulfilled, (state, action) => {
      state.byId = keyBy(action.payload.scheduledDoseSchemas, 'id')
      state.isLoading = false
    })

    builder.addMatcher(isPending(actions.fetchAll), (state) => {
      state.isLoading = true
    })
    builder.addMatcher(isRejected(actions.fetchAll), (state) => {
      state.isLoading = false
    })
  },
})

export default scheduledDoseSchemasSlice.reducer

export const getDoseSchemas = (state: RootState): ScheduledDoseSchema[] =>
  Object.values(state.scheduledDoseSchemas.byId).filter(
    (maybeDoseSchema): maybeDoseSchema is ScheduledDoseSchema =>
      !!maybeDoseSchema
  )

export const selectNotificationPolicies = (
  scheduledDoseSchemaId: ScheduledDoseSchemaId,
  state: RootState
): NotificationPolicy[] => {
  if (!state.notificationPolicies.idsByScheduledDoseSchemaId) return []

  return state.notificationPolicies.idsByScheduledDoseSchemaId[
    scheduledDoseSchemaId
  ]
    .map((id) => state.notificationPolicies.byId[id])
    .filter(Boolean)
}
