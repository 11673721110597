import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logOut } from '@mevia/actions/authentication'

export const useHandleLogout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(logOut())
    navigate('/login', { replace: true })
  }

  return logout
}
