import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { setLocale } from '@mevia/reducers/localization'
import { getSelectedProjectLocale } from './reducers/localization'
import { getLanguage, getLocaleProject } from './reducers/user'
import { isProjectLocale } from '@mevia/locales'
import type { RootState } from './reducers/types'
import * as Sentry from '@sentry/react'
import type { Locale as DateFnsLocale } from 'date-fns'
import type { LocaleInput as FullCalendarLocale } from '@fullcalendar/react'
import DateFnsLocalesContext from '@mevia/utils/dateFnsLocales'
import FullCalendarLocalesContext from '@mevia/utils/fullCalendarLocales'
import loadIntlAPIPolyfills, { PolyfillState } from '@mevia/polyfillIntlAPI'
import { useAppSelector } from '@mevia/storeHooks'

type Messages = Record<string, string>

interface Props {
  children: React.ReactNode
}

const useImportSelectedMessages = () => {
  const { messages: messageImport } = useAppSelector(getSelectedProjectLocale)
  const [messages, setMessages] = useState<Messages | null>(null)
  useEffect(() => {
    messageImport().then(({ default: newMessages }) => {
      setMessages(newMessages)
    })
  }, [messageImport])

  return messages
}

const useImportSelectedDateFnsLocale = () => {
  const { dateFnsLocale: dateFnsImport } = useAppSelector(
    getSelectedProjectLocale
  )
  const [selectedDateFns, setDateFnsLocale] = useState<DateFnsLocale | null>(
    null
  )
  useEffect(() => {
    if (dateFnsImport) {
      dateFnsImport().then(({ default: newDateFnsLocale }) => {
        setDateFnsLocale(newDateFnsLocale)
      })
    } else {
      setDateFnsLocale(null)
    }
  }, [dateFnsImport])

  return selectedDateFns
}

const useImportSelectedFullCalendarLocale = () => {
  const { fullCalendarLocale: fullCalendarLocaleImport } = useAppSelector(
    getSelectedProjectLocale
  )
  const [selectedFullCalendarLocale, setSelectedFullCalendarLocale] =
    useState<FullCalendarLocale | null>(null)
  useEffect(() => {
    if (fullCalendarLocaleImport) {
      fullCalendarLocaleImport().then(({ default: newFullCalendarLocale }) => {
        setSelectedFullCalendarLocale(newFullCalendarLocale)
      })
    } else {
      setSelectedFullCalendarLocale(null)
    }
  }, [fullCalendarLocaleImport])

  return selectedFullCalendarLocale
}

const useSignedInUserLocale = () => {
  const dispatch = useDispatch()

  const { localeProject, language } = useAppSelector((state: RootState) => {
    return {
      localeProject: getLocaleProject(state),
      language: getLanguage(state),
    }
  })

  useEffect(() => {
    if (!localeProject || !language) return

    const projectLocale = {
      project: localeProject,
      locale: language,
    }
    if (isProjectLocale(projectLocale)) {
      dispatch(setLocale(projectLocale))
    } else {
      Sentry.captureMessage(
        `Unknown project locale ${localeProject}:${language}`
      )
    }
  }, [localeProject, language])
}

const useUserProjectLocale = (): {
  locale: string
  intlLocale: string
  messages: Messages | null
  dateFnsLocale: DateFnsLocale | null
  fullCalendarLocale: FullCalendarLocale | null
} => {
  useSignedInUserLocale()
  const messages = useImportSelectedMessages()
  const dateFnsLocale = useImportSelectedDateFnsLocale()
  const fullCalendarLocale = useImportSelectedFullCalendarLocale()
  const localeData = useAppSelector(getSelectedProjectLocale)
  const { locale } = localeData
  const intlLocale =
    ('intlLocale' in localeData && localeData.intlLocale) || locale

  return { locale, intlLocale, messages, dateFnsLocale, fullCalendarLocale }
}

const polyfillErrorMessage =
  'Failed to load the application. Please try again later or contact support if the error persists.'

const LocalizationProvider = ({ children }: Props) => {
  const { intlLocale, messages, dateFnsLocale, fullCalendarLocale } =
    useUserProjectLocale()
  const [intlPolyfillState, setIntlPolyfillState] =
    useState<PolyfillState>('idle')

  useEffect(() => {
    setIntlPolyfillState('loading')
    loadIntlAPIPolyfills(intlLocale)
      .then(() => setIntlPolyfillState('loaded'))
      .catch(() => {
        // Not really clear what to do in this case. If the browser lacks support for the Intl API, and the polyfill fails to load, we're in a bad spot.
        // Potentially we should throw an error here and let an ErrorBoundary handle it. It would at least fail in a "graceful" way.
        setIntlPolyfillState('error')
        Sentry.captureMessage(`Error loading intl polyfill for ${intlLocale}`)
      })
  }, [intlLocale])

  if (
    !messages ||
    intlPolyfillState === 'idle' ||
    intlPolyfillState === 'loading'
  ) {
    return null
  }
  if (intlPolyfillState === 'error') {
    // Since we failed to load the Intl polyfill, we can't use format.js to display the error message.
    // If we reach this case I reckon that the user is using a fairly old browser in conjunction with a flaky internet connection.
    // Admittedly we could provide better UX in this case but at this point in time I don't think that it's worth it.
    return <div>{polyfillErrorMessage}</div>
  }
  // Our 'defaultMessage' English is British. Set defaultLocale to reflect this.
  return (
    <IntlProvider
      locale={intlLocale}
      defaultLocale="en-GB"
      messages={messages}
      onError={(error) => Sentry.captureException(error)}
    >
      <DateFnsLocalesContext.Provider value={dateFnsLocale}>
        <FullCalendarLocalesContext.Provider value={fullCalendarLocale}>
          {children}
        </FullCalendarLocalesContext.Provider>
      </DateFnsLocalesContext.Provider>
    </IntlProvider>
  )
}

export default LocalizationProvider
