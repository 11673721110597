import { createSlice } from '@reduxjs/toolkit'
import * as actions from '@mevia/actions/reports'
import { RootState } from './types'

export interface ReportOptions {
  title: string
  startsAt: string
  endsAt: string
  projectId: number
}

export interface ReportOptionsState {
  readonly options: ReportOptions[]
  readonly loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState: ReportOptionsState = {
  options: [],
  loading: 'idle',
}

const reportOptionsSlice = createSlice({
  name: 'reportOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.fetchAdherenceOptions.pending, (state) => {
      state.loading = 'pending'
    })
    builder.addCase(
      actions.fetchAdherenceOptions.fulfilled,
      (state, action) => {
        state.options = action.payload
        state.loading = 'succeeded'
      }
    )
    builder.addCase(actions.fetchAdherenceOptions.rejected, (state) => {
      state.loading = 'failed'
    })
    builder.addCase(actions.fetchCsvOptions.pending, (state) => {
      state.loading = 'pending'
    })
    builder.addCase(actions.fetchCsvOptions.fulfilled, (state, action) => {
      state.options = action.payload
      state.loading = 'succeeded'
    })
    builder.addCase(actions.fetchCsvOptions.rejected, (state) => {
      state.loading = 'failed'
    })
  },
})

export default reportOptionsSlice.reducer

export const isFetching = (state: RootState): boolean => {
  return state.reportOptions.loading === 'pending'
}

export const fetchHasSucceeded = (state: RootState): boolean => {
  return state.reportOptions.loading === 'succeeded'
}
