import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import LogoLink from '@mevia/components/molecules/LogoLink'
import PrimaryNavigation, { MenuItem } from '../PrimaryNavigation'
import { FormattedMessage } from 'react-intl'
import Container from '@mevia/components/atoms/Container'
import { BP_XXL } from '../../../constants/breakpoints'
import AccountDropdown from './AccountDropdown'
import LocalizationDropdown from './LocalizationDropdown'
import SelectedTimeZoneInfo from './SelectedTimeZoneInfo'
import {
  useHasPermission,
  canManageProject,
  canCreateReports,
} from '@mevia/utils/permissions'
import { getHelpGuideUrl } from '@mevia/reducers/user'
import { useAppSelector } from '@mevia/storeHooks'

const Header = styled.header`
  background: var(--color-white);
  border-bottom: 1px solid var(--color-black-epsilon);
`

const HeaderContent = styled(Container)`
  display: flex;
  align-items: center;
`

const HeaderColumn = styled.div`
  padding: var(--spacing-m);

  &:first-child {
    align-self: stretch;
    border-right: 1px solid var(--color-black-epsilon);
    border-left: 1px solid var(--color-black-epsilon);

    @media (max-width: ${BP_XXL}) {
      padding-left: 0;
      border-left: 0;
    }
  }
`

const NavigationColumn = styled(HeaderColumn)`
  flex: 1;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: var(--spacing-s);
  }

  &.right-aligned {
    margin-left: auto;
    padding-right: 0;
  }
`

const StyledLogoLink = styled(LogoLink)`
  display: flex;
  align-items: center;
  height: 100%;
`

const buildMenuItems = (
  canManageProject: boolean,
  canCreateReports: boolean,
  helpGuideExists: boolean
): MenuItem[] => {
  const baseNav: MenuItem[] = [
    {
      path: '/participants',
      label: (
        <FormattedMessage
          id="admin-menu-patients"
          defaultMessage="Participants"
        />
      ),
    },
  ]

  if (canCreateReports) {
    baseNav.push({
      path: '/reports',
      testId: 'reports-link',
      label: (
        <FormattedMessage
          defaultMessage="Reports"
          description="Link to reports page"
        />
      ),
    })
  }

  if (canManageProject) {
    baseNav.push({
      path: '/management',
      testId: 'user-management-link',
      label: (
        <FormattedMessage
          id="admin-menu-user-management"
          defaultMessage="User management"
        />
      ),
    })
  }

  if (helpGuideExists) {
    baseNav.push({
      path: '/help',
      testId: 'help-link',
      label: <FormattedMessage id="admin-menu-help" defaultMessage="Help" />,
      className: 'right-aligned',
    })
  }

  return baseNav
}

interface Props {
  children?: ReactNode
}

const AdminHeader = ({ children }: Props): ReactElement => {
  const isUserAllowedToManageProjects = useHasPermission(canManageProject)
  const userCanCreateReports = useHasPermission(canCreateReports)
  const helpGuideExists = useAppSelector(getHelpGuideUrl)
  const menuItems = buildMenuItems(
    isUserAllowedToManageProjects,
    userCanCreateReports,
    !!helpGuideExists
  )

  return (
    <Header role="banner">
      <HeaderContent>
        <HeaderColumn>
          <StyledLogoLink />
        </HeaderColumn>
        <NavigationColumn>
          <PrimaryNavigation menuItems={menuItems} />
        </NavigationColumn>
        <HeaderRow className="right-aligned">
          <SelectedTimeZoneInfo />
          <LocalizationDropdown />
          {children}
          <AccountDropdown />
        </HeaderRow>
      </HeaderContent>
    </Header>
  )
}

export default AdminHeader
