import { completeGuide } from '@mevia/features/guides/actions'
import { createSlice, SerializedError } from '@reduxjs/toolkit'
import type { RootState } from './types'
import {
  logIn,
  postPasswordReset,
  putPasswordReset,
  postAcceptInvitation,
  changePassword,
  LoginError,
} from '../actions/authentication'
import { fetch } from '../actions/user'
import { Guides } from '@mevia/features/guides/types'

export interface Permissions {
  adherenceReport: boolean
  exposureReport: boolean
  manageProject: boolean
  csvReport: boolean
}

type FeatureFlag = string

export interface UserConfig {
  guides: Guides[]
  canEditUsers: boolean
  canCreatePatients: boolean
  canPlanDoses: boolean
  hasDevice: boolean
  canChangePortalLanguage: boolean
  canChangePortalTimeZone: boolean
  canInteractWithDoses: boolean
  tracksFood: boolean
  permissions: Permissions
  isStudyParticipant: boolean
  localeProject: string
  featureFlags: FeatureFlag[]
  helpGuideUrl?: string
}

export type UserId = string

export interface User {
  id: UserId
  country: string | null
  createdAt: string
  email: string
  language: string
  lastInteraction: string
  name: string
  phone: string | null
  pushPlatform: string | null
  pushToken: string | null
  timeZone: string
  updatedAt: string
  userName: string
  displayOrganizations: boolean
  completedGuides: string[]
  config: UserConfig
}

export interface UserState {
  readonly user: User | null
  readonly loginError: LoginError | undefined | null
  readonly isLoading: boolean
  readonly resetPasswordError: SerializedError | null
  readonly resetPasswordSent: boolean
  readonly changePasswordError: SerializedError | null
}

const initialState: UserState = {
  user: null,
  loginError: null,
  isLoading: false,
  resetPasswordError: null,
  resetPasswordSent: false,
  changePasswordError: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetch.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.user = action.payload
      state.isLoading = false
    })
    builder.addCase(fetch.rejected, (state) => {
      state.isLoading = false
    })

    // Login
    builder.addCase(logIn.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.user = action.payload
      state.isLoading = false
      state.loginError = null
    })
    builder.addCase(logIn.rejected, (state, action) => {
      state.isLoading = false
      // Since we're using reject with value
      state.loginError = action.payload
    })

    // Put password
    builder.addCase(putPasswordReset.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(putPasswordReset.fulfilled, (state) => {
      state.isLoading = false
      state.resetPasswordError = null
    })
    builder.addCase(putPasswordReset.rejected, (state, action) => {
      state.isLoading = false
      state.resetPasswordError = action.error
    })

    // Post password
    builder.addCase(postPasswordReset.pending, (state) => {
      state.isLoading = true
      state.resetPasswordSent = false
    })
    builder.addCase(postPasswordReset.fulfilled, (state) => {
      state.isLoading = false
      state.resetPasswordError = null
      state.resetPasswordSent = true
    })
    builder.addCase(postPasswordReset.rejected, (state, action) => {
      state.isLoading = false
      state.resetPasswordError = action.error
      state.resetPasswordSent = false
    })

    // Change password
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(changePassword.fulfilled, (state) => {
      state.isLoading = false
      state.changePasswordError = null
    })
    builder.addCase(changePassword.rejected, (state, action) => {
      state.isLoading = false
      state.changePasswordError = action.error
    })

    // Accept invitation
    builder.addCase(postAcceptInvitation.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(postAcceptInvitation.fulfilled, (state) => {
      state.isLoading = false
      state.resetPasswordError = null
    })
    builder.addCase(postAcceptInvitation.rejected, (state, action) => {
      state.isLoading = false
      state.resetPasswordError = action.error
    })

    builder.addCase(completeGuide.fulfilled, (state, action) => {
      if (state.user) state.user.completedGuides = action.payload
    })
  },
})

export default userSlice.reducer

export const displayOrganizations = (userRoot: UserState): boolean =>
  !!userRoot?.user?.displayOrganizations

export const getLanguage = (state: RootState): string | void =>
  state.user.user?.language

export const getLocaleProject = (state: RootState): string | undefined =>
  state.user.user?.config?.localeProject

export const getHelpGuideUrl = (state: RootState): string | undefined =>
  state.user.user?.config?.helpGuideUrl
