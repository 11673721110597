import 'isomorphic-fetch'
import qs from 'query-string'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { getAuthToken } from './persistance'

export const PAGE_LIMIT = 1725

export interface SerializedMapiError {
  response: Pick<Response, 'status' | 'statusText'>
  json: unknown
  name: string
}

export class MapiError extends Error {
  json: unknown
  response: Response

  constructor(message: string, json: unknown, response: Response) {
    super(message)
    this.response = response
    this.json = json
    this.name = 'MapiError'
  }

  serialize(): SerializedMapiError {
    return {
      name: this.name,
      json: this.json,
      response: {
        status: this.response.status,
        statusText: this.response.statusText,
      },
    }
  }
}

export const API_ROOT = process.env.API_ROOT || 'http://0.0.0.0:3000'

interface urlParams {
  [key: string]: string | number
}
interface requestInterface {
  query?: urlParams
  headers?: { [key: string]: string }
  body?: { [key: string]: unknown }
}

type QueryParams = Partial<urlParams>

const hasQuery = (query: QueryParams) => Object.keys(query).length > 0
export const buildQueryString = (query: QueryParams): string =>
  hasQuery(query) ? `?${qs.stringify(decamelizeKeys(query))}` : ''

function fullUrl(url: string, query: urlParams) {
  const queryString = buildQueryString(query)
  return `${API_ROOT}/${url}${queryString}`
}

export function buildHeader(additionalHeaders: Headers): Headers {
  const headers = new Headers({
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'X-Client-Identifier': 'portal',
  })

  if (getAuthToken()) headers.set('Authorization', `Bearer ${getAuthToken()}`)

  for (const [header, headerValue] of additionalHeaders.entries()) {
    headers.append(header, headerValue)
  }

  return headers
}

type RequestResponse<T> = Promise<T>

function request<T>(
  method: string,
  url: string,
  { query = {}, ...options }: requestInterface = {}
): RequestResponse<T> {
  const body = options.body && JSON.stringify(decamelizeKeys(options.body))

  const headers = buildHeader(new Headers(options.headers))

  return (
    fetch(fullUrl(url, query), {
      ...options,
      method,
      body,
      headers,
    })
      .then((response) => {
        if (response.status == 204 || response.status === 202)
          return Promise.all([response, {}])
        return Promise.all([response, failSafeJSONParse(response)])
      })
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          // Probably not the most beautiful way of defining an error
          const errorMessage =
            json.error || json.errors?.join(', ') || response.statusText
          const error = new MapiError(errorMessage, json, response)
          return Promise.reject(error)
        }
        return json
      })
      // Lint complained about object[] being to similar to { [key: string]:
      // unknown } and suggested using unkown as a midstep
      .then((json) => camelizeKeys(json) as unknown as T)
  )
}

const failSafeJSONParse = async (response: Response) => {
  const text = await response.text()
  try {
    const json = JSON.parse(text)
    return json
  } catch (error) {
    return {}
  }
}

export const get: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>('GET', url, obj)

export const post: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>('POST', url, obj)

export const patch: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>('PATCH', url, obj)

export const put: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>('PUT', url, obj)

export const del: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>('DELETE', url, obj)
