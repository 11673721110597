import { createSlice } from '@reduxjs/toolkit'

import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import { RootState } from './types'

import * as actions from '../actions/notificationPolicies'
import {
  fetchAll as fetchAllScheduledDoseSchemas,
  ScheduledDoseSchemaId,
} from '@mevia/actions/scheduledDoseSchemas'
import {
  NotificationPolicy,
  NotificationPolicyId,
  Recipient,
} from '@mevia/types/mapiAPI'

export interface NotificationPolicyState {
  readonly byId: Record<NotificationPolicyId, NotificationPolicy>
  readonly idsByScheduledDoseSchemaId: Record<
    ScheduledDoseSchemaId,
    NotificationPolicyId[]
  >
  readonly isLoading: boolean
}

const initialState: NotificationPolicyState = {
  byId: {},
  idsByScheduledDoseSchemaId: {},
  isLoading: false,
}

const notificationPolicySlice = createSlice({
  name: 'notificationPolicies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.fetchAll.fulfilled, (state, action) => {
      state.byId = keyBy(action.payload.notificationPolicies, 'id')
    })
    builder.addCase(actions.fetchAllOwn.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(actions.fetchAllOwn.fulfilled, (state, action) => {
      state.byId = keyBy(action.payload.notificationPolicies, 'id')
      state.isLoading = false
    })
    builder.addCase(actions.fetchAllOwn.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(actions.fetch.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(actions.create.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(actions.destroy.fulfilled, (state, action) => {
      const idToOmit = action.payload
      state.byId = omit(state.byId, idToOmit)
    })
    builder.addCase(actions.update.fulfilled, (state, action) => {
      if (action.payload?.entities?.notificationPolicies) {
        state.byId = {
          ...state.byId,
          ...action.payload.entities.notificationPolicies,
        }
      }
    })
    builder.addCase(fetchAllScheduledDoseSchemas.fulfilled, (state, action) => {
      state.byId = {
        ...state.byId,
        ...keyBy(action.payload.notificationPolicies, 'id'),
      }

      state.idsByScheduledDoseSchemaId = {
        ...state.idsByScheduledDoseSchemaId,
        ...action.payload.relationships,
      }
    })
  },
})

export default notificationPolicySlice.reducer

export const selectByPrescriptionId = (
  prescriptionId: number | string,
  state: RootState
): NotificationPolicy[] =>
  (
    Object.values(state.notificationPolicies.byId) as NotificationPolicy[]
  ).filter(
    (policy: NotificationPolicy): boolean =>
      String(policy.prescriptionId) === String(prescriptionId)
  )

export const selectRecipients = (
  notificationPolicyId: NotificationPolicyId,
  state: RootState
): Recipient[] => {
  if (!state.recipients.idsByNotificationPolicyId[notificationPolicyId])
    return []
  return state.recipients.idsByNotificationPolicyId[notificationPolicyId]
    .map((id) => state.recipients.byId[id])
    .filter(Boolean)
}
