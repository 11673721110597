import React from 'react'
import { ThemeProvider } from 'styled-components'

export const theme = {
  default: {
    bg: 'var(--color-orange-alpha)',
    hoverBg: 'var(--color-orange-beta)',
    color: 'var(--color-black-alpha)',
  },
  defaultVariant: {
    bg: 'var(--color-light-blue-alpha)',
    hoverBg: 'var(--color-light-blue-beta)',
    color: 'var(--color-white)',
  },
  save: {
    bg: 'var(--color-green-alpha)',
    hoverBg: 'var(--color-green-beta)',
    color: 'var(--color-white)',
  },
  warning: {
    bg: 'var(--color-red-alpha)',
    hoverBg: 'var(--color-red-beta)',
    color: 'var(--color-white)',
  },
  mediumEmphasis: {
    border: '1px solid var(--color-black-delta)',
    bg: 'transparent',
    color: 'var(--color-black-alpha)',
    hoverBg: 'var(--color-grey-gamma)',
  },
  mediumEmphasisWarning: {
    border: '1px solid var(--color-black-delta)',
    bg: 'transparent',
    hoverBg: 'var(--color-grey-gamma)',
    color: 'var(--color-red-alpha)',
  },
  lowEmphasis: {
    bg: 'transparent',
    hoverBg: 'transparent',
    color: 'var(--color-black-alpha)',
    hoverColor: 'var(--color-black-beta)',
  },
  lowEmphasisWarning: {
    bg: 'transparent',
    hoverBg: 'transparent',
    color: 'var(--color-red-alpha)',
    hoverColor: 'var(--color-red-beta)',
  },
  fontSize: {
    default: 'var(--font-size-default)',
    small: 'var(--font-size-s)',
    tiny: 'var(--font-size-xs)',
  },
  size: {
    default: 'var(--spacing-xs) var(--spacing-m)',
    small: 'var(--spacing-xs)',
    tiny: 'var(--spacing-xxs) var(--spacing-xs)',
  },
}

const ButtonTheme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default ButtonTheme
