import { createSlice } from '@reduxjs/toolkit'
import { fetch } from '../actions/templates'

export interface Template {
  id: string | number
  title: string
}

export interface TemplateState {
  readonly templates: Array<Template>
  readonly isLoading: boolean
}

const initialState: TemplateState = {
  templates: [],
  isLoading: true,
}

const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetch.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.templates = action.payload
      state.isLoading = false
    })
    builder.addCase(fetch.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export default templateSlice.reducer
